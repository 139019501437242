import { takeEvery, put, call, select } from 'redux-saga/effects';

import { locationsApi, userApi } from '../../api';

import * as locationsActions from './locations.actions';
import { GET_LOCATIONS_REQUEST, GET_USER_LOCATIONS_REQUEST, GET_WORKAREAS_REQUEST } from './locations.types';

function* getLocationsSaga() {
  try {
    const locations = yield call(locationsApi.getLocations);
    yield put(locationsActions.getLocationsSuccess(locations));
  } catch (error) {
    yield put(locationsActions.getLocationsFail());
  }
}

function* getUserLocationsSaga() {
  try {
    const { userId } = yield select(({ user }) => user);
    const locations = yield call(userApi.getUserLocations, userId);
    yield put(locationsActions.getUserLocationsSuccess(locations));
  } catch (error) {
    yield put(locationsActions.getUserLocationsFail());
  }
}

function* getWorkAreas({ payload: locationId }) {
  try {
    const workAreas = yield call(locationsApi.getWorkAreas, locationId);
    yield put(locationsActions.getWorkAreasSuccess(workAreas));
  } catch (error) {
    yield put(locationsActions.getWorkAreasFail());
  }
}

export default function* locationsSaga() {
  yield takeEvery(GET_LOCATIONS_REQUEST, getLocationsSaga);
  yield takeEvery(GET_USER_LOCATIONS_REQUEST, getUserLocationsSaga);
  yield takeEvery(GET_WORKAREAS_REQUEST, getWorkAreas);
}
