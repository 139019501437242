import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  EnrolledAttendedWithIncome,
  AttendeesByPeriod,
  TeachersInClassroom,
  TeachersAndEmployees,
  TeachersNeeded,
  ToursThisWeek,
  ParentsComplaints,
  ViolationsWithAmounts,
  BilledVsReceived,
  Substitutes,
  IncomeAndCosts,
  AttendeesVsEnrollees,
  CountOfTeachersPerClassroom,
} from './reports';

import { checkPermission } from '../../helpers';
import { permission, modalTypes } from '../../constants';

import FilterBar from './filter-bar';
import ReportWrapper from '../../components/report-wrapper';

import * as dashboardActions from './dashboard-page.actions';
import { modalActions } from '../../components/modal';

@connect(
  ({ locations: { userLocations, spinners: locationSpinners }, user: { permissions }, report }) => ({
    permissions,
    userLocations,
    locationSpinners,
    report,
  }),
  {
    applyInitialFiltersRequest: dashboardActions.applyInitialFiltersRequest,
    applyFiltersRequest: dashboardActions.applyFiltersRequest,
    addSubstitutionRequest: dashboardActions.addSubstitutionRequest,
    hideModal: modalActions.hideModal,
    changeReportTab: dashboardActions.changeReportTab,
    changeFilters: dashboardActions.changeFilters,
    openModal: modalActions.openModal,
  }
)
class Dashboard extends Component {
  componentDidMount() {
    this.props.applyInitialFiltersRequest();
  }

  applyFiltersHandler = filters => {
    this.props.applyFiltersRequest(filters);
    this.props.changeFilters({ dateFrom: filters.dateFrom, dateTo: filters.dateTo });
  };

  addComplaintHandler = () => {
    this.props.openModal({
      modalType: modalTypes.CREATE_COMPLAINT,
    });
  };

  addViolationHandler = () => {
    this.props.openModal({
      modalType: modalTypes.CREATE_VIOLATION,
    });
  };

  tabChangeHandler = (type, value) => this.props.changeReportTab({ type, value });

  render() {
    const { userLocations, locationSpinners, report, permissions } = this.props;
    return locationSpinners.getUserLocations ? (
      <LinearProgress />
    ) : (
      <Fragment>
        <h1 className="dashboard-title">BeanStalk Academy Dashboard</h1>

        <FilterBar locations={userLocations} onFilters={this.applyFiltersHandler} />

        <div className="container reports-holder">
          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="List of All Teachers and Office Employees"
              className="colored-table wide-table earls-green-color"
              tabChangeHandler={this.tabChangeHandler}
              tabLabels={['WORKAREAS']}
              report={report.teachersAndEmployees}
              loading={report.spinners.getTeachersAndEmployees}
              permissions={permissions}
              filters={report.filters}
            >
              <TeachersAndEmployees />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.FINANCIAL) && (
            <ReportWrapper
              title="Average Enrolled, Attended with Income"
              className="group-column colored-table geraldine-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.enrolledAttendedWithIncome}
              loading={report.spinners.getEnrolledAttendedWithIncome}
              permissions={permissions}
              filters={report.filters}
            >
              <EnrolledAttendedWithIncome />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Count of Teachers per Classroom"
              className="group-column colored-table cerulean-color"
              tabChangeHandler={this.tabChangeHandler}
              withoutTabs
              report={report.countOfTeachersPerClassroom}
              loading={report.spinners.getCountOfTeachersPerClassroom}
              permissions={permissions}
              filters={report.filters}
            >
              <CountOfTeachersPerClassroom />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.FINANCIAL) && (
            <ReportWrapper
              title="Teachers in the Classroom with the Total Expense"
              className="group-column colored-table dodger-blue-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.teachersInClassroom}
              loading={report.spinners.getTeachersInClassroom}
              permissions={permissions}
              filters={report.filters}
            >
              <TeachersInClassroom />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Number of teachers needed by calculating if classes can be combined without reaching the max per class"
              className="group-column colored-table froly-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.teachersNeededByCalculating}
              loading={report.spinners.getTeachersNeededByCalculating}
              permissions={permissions}
              filters={report.filters}
            >
              <TeachersNeeded />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Compare Attendees by Time Period"
              className="colored-table wide-table forest-green-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.attendeesByPeriod}
              loading={report.spinners.getAttendeesByPeriod}
              permissions={permissions}
              filters={report.filters}
            >
              <AttendeesByPeriod />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Compare Attendees versus Enrollees"
              className="colored-table wide-table light-wisteria-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.attendeesVsEnrollees}
              loading={report.spinners.getAttendeesVsEnrollees}
              permissions={permissions}
              filters={report.filters}
            >
              <AttendeesVsEnrollees />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Violations, Received with Amounts"
              className="group-column colored-table purple-heart-color"
              tabChangeHandler={this.tabChangeHandler}
              withoutTabs
              report={report.violationsCount}
              loading={report.spinners.getViolationsCount}
              permissions={permissions}
              filters={report.filters}
            >
              <ViolationsWithAmounts addViolation={this.addViolationHandler} linkTo="dashboard/violations" />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Number of Parents' Complaints"
              className="group-column colored-table cerulean-light-color"
              tabChangeHandler={this.tabChangeHandler}
              withoutTabs
              report={report.complaintsCount}
              loading={report.spinners.getComplaintsCount}
              permissions={permissions}
              filters={report.filters}
            >
              <ParentsComplaints addComplaint={this.addComplaintHandler} linkTo="dashboard/complaints" />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.FINANCIAL) && (
            <ReportWrapper
              title="Income and Cost"
              className="group-column colored-table japanese-laurel-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.incomeAndCosts}
              loading={report.spinners.getIncomeAndCosts}
              permissions={permissions}
              filters={report.filters}
            >
              <IncomeAndCosts />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.FINANCIAL) && (
            <ReportWrapper
              title="Compare Monies Billed vs Monies Received"
              className="group-column colored-table jungle-green-color"
              tabChangeHandler={this.tabChangeHandler}
              report={report.moniesBilledVsReceived}
              loading={report.spinners.getMoniesBilledVsReceived}
              permissions={permissions}
              filters={report.filters}
            >
              <BilledVsReceived />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="List of Substitutes by Week"
              className="group-column colored-table jazzberry-jam-color"
              withoutTabs
              tabChangeHandler={this.tabChangeHandler}
              report={report.listOfSubstitutes}
              loading={report.spinners.getListOfSubstitutes}
              permissions={permissions}
              filters={report.filters}
            >
              <Substitutes />
            </ReportWrapper>
          )}

          {checkPermission(permissions, permission.NON_FINANCIAL) && (
            <ReportWrapper
              title="Tours"
              className="group-column colored-table jazzberry-jam-color"
              tabChangeHandler={this.tabChangeHandler}
              permissions={permissions}
              filters={report.filters}
            >
              <ToursThisWeek />
            </ReportWrapper>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
