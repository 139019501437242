import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';

import { userApi } from '../../api';

import { snackActions } from '../../components/snack-bar';
import { modalActions } from '../../components/modal';

import * as userActions from './users-page.actions';
import * as userTypes from './users-page.types';

function* getUsersSaga({ payload: { currentPage, pageSize, orderBy, orderDirection } }) {
  try {
    const { users, pagination } = yield call(userApi.getUsers, { currentPage, pageSize, orderBy, orderDirection });
    yield put(userActions.getUsersSuccess({ users, pagination, order: { orderBy, orderDirection } }));
  } catch (error) {
    yield put(userActions.getUsersFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createUserSaga({ payload: user }) {
  try {
    const { currentPage, pageSize } = yield select(({ users: { pagination } }) => pagination);
    yield call(userApi.createUser, user);
    yield put(userActions.createUserSuccess());
    yield put(modalActions.hideModal());
    yield put(userActions.getUsersRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(userActions.createUserFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* updateUserSaga({ payload: user }) {
  try {
    const { currentPage, pageSize } = yield select(({ users: { pagination } }) => pagination);
    yield call(userApi.updateUser, user);
    yield put(userActions.updateUserSuccess());
    yield put(modalActions.hideModal());
    yield put(userActions.getUsersRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(userActions.updateUserFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* deleteUserSaga({ payload: user }) {
  try {
    const { currentPage, pageSize } = yield select(({ users: { pagination } }) => pagination);
    yield call(userApi.deleteUser, user.id);
    yield put(userActions.deleteUserSuccess());
    yield put(modalActions.hideModal());
    yield put(userActions.getUsersRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(userActions.deleteUserFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* checkEmailSaga({ payload }) {
  try {
    const isUnique = yield call(userApi.checkUserEmail, payload);
    yield put(userActions.checkEmailSuccess(isUnique));
  } catch (error) {
    yield put(userActions.checkEmailFail());
  }
}

export default function* userCRUDSaga() {
  yield takeLatest(userTypes.CHECK_EMAIL_REQUEST, checkEmailSaga);
  yield takeEvery(userTypes.CREATE_USER_REQUEST, createUserSaga);
  yield takeEvery(userTypes.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeEvery(userTypes.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeEvery(userTypes.GET_USERS_REQUEST, getUsersSaga);
}
