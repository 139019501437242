import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginForm from './login-form';
import Header from '../../components/app-bar';

import { loginRequest } from './login-page.actions';

import AUTH_TOKEN from '../../constants';

@connect(
  ({ user: { spinners } }) => ({
    loading: spinners.login,
  }),
  { loginRequest }
)
class Login extends Component {
  static propTypes = {
    loginRequest: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    isLoggedIn: localStorage.getItem(AUTH_TOKEN),
  };

  loginHandler = credentials => {
    this.props.loginRequest(credentials);
  };

  render() {
    const { loading } = this.props;
    const { isLoggedIn } = this.state;

    return !isLoggedIn ? (
      <div className="login-page">
        <Header />
        <LoginForm loading={loading} onSubmit={this.loginHandler} />
      </div>
    ) : (
      <Redirect to="/" />
    );
  }
}

export default Login;
