import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { MenuItem as Item, ListItemIcon, ListItemText, Icon } from '@material-ui/core';

class MenuItem extends PureComponent {
  static propTypes = {
    linkTo: PropTypes.string,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  };

  render() {
    const { linkTo, onClick, label, icon } = this.props;
    return (
      <Item color="inherit" className="menu-item" onClick={onClick}>
        {linkTo ? (
          <NavLink className="nav-link" exact to={linkTo}>
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText className="menu-item-label">{label}</ListItemText>
          </NavLink>
        ) : (
          <Fragment>
            <div className="nav-link-ref">
              <ListItemIcon>
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText className="menu-item-label">{label}</ListItemText>
            </div>
          </Fragment>
        )}
      </Item>
    );
  }
}

export default MenuItem;
