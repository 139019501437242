import { OPEN_MODAL, HIDE_MODAL } from './modal.types';

const initState = { modalType: null, modalProps: {} };
const modalReducer = (state = initState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { modalType: action.payload.modalType, modalProps: action.payload.modalProps };
    case HIDE_MODAL:
      return initState;
    default:
      return state;
  }
};

export default modalReducer;
