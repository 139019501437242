import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import { Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core/';
import moment from 'moment';
import get from 'lodash/get';

import { createValidator } from '../../helpers';

import ButtonWithSpinner from '../../components/button-with-spinner';
import FieldWithIcon from '../../components/field-with-icon';
import DatePicker from '../../components/date-picker';
import Select from '../../components/select';

@withFormik({
  validate: createValidator(validator =>
    validator.object().shape({
      date: validator
        .date()
        .typeError('Date of Complain must be a valid "date" type')
        .required('Date of Complain is required field'),
      resolvedDate: validator
        .date()
        .nullable()
        .default(null)
        .typeError('Resolved Date must be a valid "date" type'),
      locationId: validator.string().required('Day Care Center is required field'),
      description: validator
        .string()
        .max(256, 'Description must be less than or equal to 256')
        .required('Description is required field'),
      phoneOrEmail: validator.string().required('Phone or Email is required field'),
      resolved: validator.boolean(),
    })
  ),
  mapPropsToValues: ({ complaint }) => ({
    date: get(complaint, 'date') || moment(),
    locationId: get(complaint, 'location.id', ''),
    description: get(complaint, 'description', ''),
    phoneOrEmail: get(complaint, 'phoneOrEmail', ''),
    resolved: get(complaint, 'resolved', false),
    resolvedDate: get(complaint, 'resolvedDate', null),
    type: complaint ? 'update' : 'create',
  }),
})
class ComplaintModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
  };

  onComplaintDateHandler = date => {
    const { setFieldValue } = this.props;
    setFieldValue('date', date);
  };

  onResolvedDateHandler = date => {
    const { setFieldValue } = this.props;
    setFieldValue('resolvedDate', date);
  };

  onSelectHandler = locationId => {
    const { setFieldValue } = this.props;
    setFieldValue('locationId', locationId);
  };

  onCheckboxHandler = e => {
    const { values, setFieldValue } = this.props;
    const { name } = e.target;
    if (name === 'resolved' && values['resolved']) {
      setFieldValue('resolvedDate', null);
    }
    setFieldValue(name, !values[name]);
  };

  onChangeHandler = e => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  onBlurHandler = e => {
    const { setFieldTouched } = this.props;
    setFieldTouched(e.target.name, true, false);
  };

  onSubmitHandler = () => {
    const { onSubmit, values, isValid, complaint } = this.props;
    if (!isValid) return;

    const { date, resolvedDate, type, ...rest } = values;

    if (type === 'update') {
      onSubmit({
        ...complaint,
        date: moment(date).format('YYYY-MM-DD'),
        resolvedDate: resolvedDate ? moment(resolvedDate).format('YYYY-MM-DD') : null,
        ...rest,
      });
      return;
    }
    onSubmit({
      date: moment(date).format('YYYY-MM-DD'),
      resolvedDate: resolvedDate ? moment(resolvedDate).format('YYYY-MM-DD') : null,
      ...rest,
    });
  };

  onCancelHandler = () => {
    this.props.onClose();
  };

  render() {
    const { errors, touched, values, loading, isValid, locations, title } = this.props;

    return (
      <div className="modal add-complaint">
        <div className="modal-header">{title}</div>
        <Form>
          <div className="datepicker-holder">
            <FieldWithIcon
              icon="event"
              onBlur={this.onBlurHandler}
              error={touched.date && !!errors.date}
              withInputProps
            >
              <DatePicker
                name="date"
                label="Date of Complaint*"
                maxDate={values.resolvedDate || undefined}
                value={values.date}
                onChange={this.onComplaintDateHandler}
                error={touched.date && !!errors.date}
                fullWidth
                disableFuture
              />
            </FieldWithIcon>
          </div>

          <FieldWithIcon icon="business" onBlur={this.onBlurHandler} error={touched.locationId && !!errors.locationId}>
            <Select
              className="select-checkbox-input custom"
              label="Day Care Center*"
              values={values.locationId}
              options={locations}
              onChange={this.onSelectHandler}
              error={touched.locationId && !!errors.locationId}
              valueKey="id"
              labelKey="name"
              name="locationId"
              helperText={touched.locationId ? errors.locationId : ''}
            />
          </FieldWithIcon>

          <FieldWithIcon icon="notes" error={touched.description && !!errors.description} onBlur={this.onBlurHandler}>
            <TextField
              type="text"
              name="description"
              value={values.description}
              label="Description*"
              error={touched.description && !!errors.description}
              helperText={touched.description ? errors.description : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
              multiline={true}
              rows={3}
              rowsMax={5}
            />
          </FieldWithIcon>

          <FieldWithIcon
            icon="textsms"
            error={touched.phoneOrEmail && !!errors.phoneOrEmail}
            onBlur={this.onBlurHandler}
          >
            <TextField
              type="text"
              name="phoneOrEmail"
              value={values.phoneOrEmail}
              label="Phone or Email*"
              error={touched.phoneOrEmail && !!errors.phoneOrEmail}
              helperText={touched.phoneOrEmail ? errors.phoneOrEmail : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
            />
          </FieldWithIcon>

          <FormControlLabel
            className="input-checkbox"
            control={
              <Checkbox
                className="simple-checkbox"
                name="resolved"
                checked={values.resolved}
                onChange={this.onCheckboxHandler}
                color="primary"
              />
            }
            label="Resolved"
          />

          <FieldWithIcon
            icon="event"
            onBlur={this.onBlurHandler}
            error={touched.resolvedDate && !!errors.resolvedDate}
            withInputProps
          >
            <DatePicker
              name="resolvedDate"
              label="Resolved Date"
              value={values.resolvedDate}
              onChange={this.onResolvedDateHandler}
              error={touched.resolvedDate && !!errors.resolvedDate}
              helperText={touched.resolvedDate ? errors.resolvedDate : ''}
              minDate={values.date}
              fullWidth
              disableFuture
              disabled={!values.resolved}
            />
          </FieldWithIcon>

          <div className="footer-buttons">
            <Button onClick={this.onCancelHandler} className="light-btn dark">
              CANCEL
            </Button>
            <div className="button-holder">
              <ButtonWithSpinner
                className="light-btn"
                type="button"
                onClick={this.onSubmitHandler}
                loading={loading}
                disabled={!isValid}
              >
                SAVE
              </ButtonWithSpinner>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default ComplaintModal;
