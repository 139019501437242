import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menu-item';
import { modalTypes, menuIds } from '../../../constants';

import { generateSinglePDF } from '../../../helpers/pdf';
import { generateZIPCSV } from '../../../helpers/csv';

const menu = [
  {
    id: menuIds.DASHBOARD,
    label: 'Dashboard',
    icon: 'dashboard',
    to: '/dashboard',
  },
  {
    id: menuIds.COMPLAINTS_LIST,
    label: 'Complaints List',
    icon: 'format_list_bulleted',
    to: '/dashboard/complaints',
  },
  {
    id: menuIds.VIOLATIONS_LIST,
    label: 'Violations List',
    icon: 'format_list_bulleted',
    to: '/dashboard/violations',
  },
  // {
  //   id: menuIds.PDF,
  //   label: 'Export to PDF',
  //   icon: 'insert_drive_file',
  // },
  // {
  //   id: menuIds.CSV,
  //   label: 'Export to CSV',
  //   icon: 'archive',
  // },
  {
    id: menuIds.SUBSTITUTES,
    label: 'Add Substitute',
    icon: 'add',
  },
  {
    id: menuIds.COMPLAINT,
    label: 'Add Complaint',
    icon: 'add',
  },
  {
    id: menuIds.VIOLATION,
    label: 'Add Violation',
    icon: 'add',
  },
];

class UserMenu extends PureComponent {
  static propTypes = {
    isAdmin: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  onClickHandler = id => {
    const { openModal, onClose, report, permissions } = this.props;

    switch (id) {
      case menuIds.PDF:
        generateSinglePDF(report, permissions);
        break;

      case menuIds.CSV:
        generateZIPCSV(report, permissions);
        break;

      case menuIds.SUBSTITUTES:
        openModal({
          modalType: modalTypes.CREATE_SUBSTITUTE,
        });
        break;

      case menuIds.COMPLAINT:
        openModal({
          modalType: modalTypes.CREATE_COMPLAINT,
        });
        break;

      case menuIds.VIOLATION:
        openModal({
          modalType: modalTypes.CREATE_VIOLATION,
        });
        break;

      default:
        break;
    }

    onClose();
  };

  render() {
    const { isAdmin, onClose } = this.props;

    return (
      <Fragment>
        {menu.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => this.onClickHandler(item.id)}
            icon={item.icon}
            label={item.label}
            linkTo={item.to}
          />
        ))}
        {isAdmin && <MenuItem onClick={onClose} icon="settings" label="Admin Panel" linkTo="/admin" />}
      </Fragment>
    );
  }
}

export default UserMenu;
