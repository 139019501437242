import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from '../../components/select';
import Chips from '../../components/chips';
import DatePicker from '../../components/date-picker';
import Button from '../../components/button-with-spinner';
import FieldWithIcon from '../../components/field-with-icon';
import moment from 'moment';
import filter from 'lodash/filter';

class DashboardFilters extends PureComponent {
  static propTypes = {
    locations: PropTypes.array,
  };

  state = {
    selectedLocations:
      this.props.locations && this.props.locations.length ? this.props.locations.map(location => location.id) : [],
    dateFrom: moment()
      .subtract(1, 'weeks')
      .startOf('week'),
    dateTo: moment()
      .subtract(1, 'weeks')
      .endOf('week'),
  };

  onApplyHandler = () => {
    const { dateFrom, dateTo, selectedLocations: locationIds } = this.state;
    const { locations } = this.props;
    this.props.onFilters({ dateFrom, dateTo, locationIds, locations });
  };

  selectLocationHandler = locations => {
    this.setState({ selectedLocations: locations });
  };

  deleteLocationHandler = removedLocation => {
    this.setState(({ selectedLocations }) => ({
      selectedLocations: filter(selectedLocations, location => location !== removedLocation),
    }));
  };

  dateFromHandler = dateFrom => {
    this.setState({ dateFrom });
  };

  dateToHandler = dateTo => {
    this.setState({ dateTo });
  };

  get disabled() {
    const { selectedLocations } = this.state;
    return !selectedLocations.length;
  }

  render() {
    const { locations } = this.props;
    const { selectedLocations, dateFrom, dateTo } = this.state;

    return (
      <div className="main-dashboard container">
        <div className="filter-block">
          <div className="filter-actions">
            <div className="w-42">
              <Select
                label="Select Day Care Center"
                values={selectedLocations}
                options={locations}
                allSelected={locations.length === selectedLocations.length}
                onChange={this.selectLocationHandler}
                multiple
                valueKey="id"
                labelKey="name"
              />
            </div>
            <div className="datepicker-holder w-18">
              <FieldWithIcon icon="event" withInputProps>
                <DatePicker
                  label="From"
                  maxDate={dateTo || undefined}
                  value={dateFrom}
                  onChange={this.dateFromHandler}
                  disableFuture
                />
              </FieldWithIcon>
            </div>
            <div className="datepicker-holder w-18">
              <FieldWithIcon icon="event" withInputProps>
                <DatePicker
                  label="To"
                  minDate={dateFrom || undefined}
                  value={dateTo}
                  onChange={this.dateToHandler}
                  disableFuture
                />
              </FieldWithIcon>
            </div>
            <div className="button-holder w-15">
              <Button
                className="default-btn"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={this.disabled}
                onClick={this.onApplyHandler}
              >
                APPLY
                <br />
                FILTERS
              </Button>
            </div>
          </div>
          <div className="tags-holder">
            <Chips values={selectedLocations} options={locations} onDelete={this.deleteLocationHandler} valueKey="id" />
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardFilters;
