import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import { Button, TextField } from '@material-ui/core/';
import moment from 'moment';

import { createValidator } from '../../helpers';

import ButtonWithSpinner from '../../components/button-with-spinner';
import FieldWithIcon from '../../components/field-with-icon';
import DatePicker from '../../components/date-picker';
import Select from '../../components/select';

@withFormik({
  validate: createValidator(validator =>
    validator.object().shape({
      date: validator
        .date()
        .required()
        .required('First Name is required field'),
      location: validator.string().required('Day Care Center is required field'),
      amount: validator
        .number()
        .integer('Amount must be an integer')
        .min(1, 'Amount must be greater than or equal to 1')
        .max(256, 'Amount must be less than or equal to 256')
        .typeError('Amount must be a number'),
    })
  ),
  mapPropsToValues: () => ({
    date: moment(),
    location: '',
    amount: '',
  }),
})
class SubstituteModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
  };

  onDateChangeHandler = date => {
    const { setFieldValue } = this.props;
    setFieldValue('date', date);
  };

  onSelectHandler = location => {
    const { setFieldValue } = this.props;
    setFieldValue('location', location);
  };

  onChangeHandler = e => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  onBlurHandler = e => {
    const { setFieldTouched } = this.props;
    setFieldTouched(e.target.name, true, false);
  };

  onSubmitHandler = () => {
    const { onSubmit, values, isValid } = this.props;
    if (!isValid) return;
    onSubmit({
      date: values.date.format('YYYY-MM-DD'),
      locationId: values.location,
      amount: values.amount,
    });
  };

  onCancelHandler = () => {
    this.props.onClose();
  };

  render() {
    const { errors, touched, values, loading, isValid, locations, title } = this.props;

    return (
      <div className="modal add-substitute">
        <div className="modal-header">{title}</div>
        <Form>
          <div className="datepicker-holder">
            <FieldWithIcon
              icon="event"
              onBlur={this.onBlurHandler}
              error={touched.date && !!errors.date}
              withInputProps
            >
              <DatePicker
                label="Date"
                value={values.date}
                onChange={this.onDateChangeHandler}
                error={touched.date && !!errors.date}
                name="date"
              />
            </FieldWithIcon>
          </div>

          <FieldWithIcon icon="business" onBlur={this.onBlurHandler} error={touched.location && !!errors.location}>
            <Select
              className="select-checkbox-input custom"
              label="Select Day Care Center"
              values={values.location}
              options={locations}
              onChange={this.onSelectHandler}
              error={touched.location && !!errors.location}
              valueKey="id"
              labelKey="name"
              name="location"
              helperText={touched.location ? errors.location : ''}
            />
          </FieldWithIcon>

          <FieldWithIcon icon="school" error={touched.amount && !!errors.amount} onBlur={this.onBlurHandler}>
            <TextField
              type="text"
              name="amount"
              value={values.amount}
              label="Amount"
              error={touched.amount && !!errors.amount}
              helperText={touched.amount ? errors.amount : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
            />
          </FieldWithIcon>

          <div className="footer-buttons">
            <Button onClick={this.onCancelHandler} className="light-btn dark">
              CANCEL
            </Button>
            <div className="button-holder">
              <ButtonWithSpinner
                className="light-btn"
                type="button"
                onClick={this.onSubmitHandler}
                loading={loading}
                disabled={!isValid}
              >
                SAVE
              </ButtonWithSpinner>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default SubstituteModal;
