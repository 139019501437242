import { combineReducers } from 'redux';

import * as types from './history-log.types';

const list = (state = [], action) => {
  switch (action.type) {
    case types.GET_HISTORY_LOG_SUCCESS:
      return [...action.payload.history];
    default:
      return state;
  }
};

const paginationState = {
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  rowCount: 0,
};
const pagination = (state = paginationState, action) => {
  switch (action.type) {
    case types.GET_HISTORY_LOG_SUCCESS:
      return { ...state, ...action.payload.pagination };
    default:
      return state;
  }
};

const orderState = {
  orderBy: 'actionDateTime',
  orderDirection: 'desc',
};
const order = (state = orderState, action) => {
  switch (action.type) {
    case types.GET_HISTORY_LOG_SUCCESS:
      return { ...action.payload.order };
    default:
      return state;
  }
};

const spinnersState = {
  getHistoryLog: false,
};
const spinners = (state = spinnersState, action) => {
  switch (action.type) {
    case types.GET_HISTORY_LOG_REQUEST:
      return { ...state, getHistoryLog: true };
    case types.GET_HISTORY_LOG_SUCCESS:
    case types.GET_HISTORY_LOG_FAIL:
      return { ...state, getHistoryLog: false };
    default:
      return state;
  }
};

const historyLogReducer = combineReducers({
  list,
  order,
  pagination,
  spinners,
});

export default historyLogReducer;
