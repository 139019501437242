import { combineReducers } from 'redux';

import * as types from './violations.types';

const list = (state = [], action) => {
  switch (action.type) {
    case types.GET_VIOLATIONS_SUCCESS:
      return [...action.payload.violations];
    default:
      return state;
  }
};

const paginationState = {
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  rowCount: 0,
};
const pagination = (state = paginationState, action) => {
  switch (action.type) {
    case types.GET_VIOLATIONS_SUCCESS:
      return { ...state, ...action.payload.pagination };
    default:
      return state;
  }
};

const spinnersState = {
  getViolations: false,
  createViolation: false,
  updateViolation: false,
  deleteViolation: false,
};
const spinners = (state = spinnersState, action) => {
  switch (action.type) {
    case types.GET_VIOLATIONS_REQUEST:
      return { ...state, getViolations: true };
    case types.GET_VIOLATIONS_FAIL:
    case types.GET_VIOLATIONS_SUCCESS:
      return { ...state, getViolations: false };
    case types.CREATE_VIOLATION_REQUEST:
      return { ...state, createViolation: true };
    case types.UPDATE_VIOLATION_REQUEST:
      return { ...state, updateViolation: true };
    case types.DELETE_VIOLATION_REQUEST:
      return { ...state, deleteViolation: true };
    case types.CREATE_VIOLATION_SUCCESS:
    case types.CREATE_VIOLATION_FAIL:
      return { ...state, createViolation: false };
    case types.UPDATE_VIOLATION_SUCCESS:
    case types.UPDATE_VIOLATION_FAIL:
      return { ...state, updateViolation: false };
    case types.DELETE_VIOLATION_SUCCESS:
    case types.DELETE_VIOLATION_FAIL:
      return { ...state, deleteViolation: false };
    default:
      return state;
  }
};

const violationsReducer = combineReducers({
  list,
  pagination,
  spinners,
});

export default violationsReducer;
