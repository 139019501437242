import { takeEvery, put, call, select } from 'redux-saga/effects';

import { userApi, complaintsApi } from '../../api';

import { snackActions } from '../../components/snack-bar';
import { modalActions } from '../../components/modal';

import * as complaintsActions from './complaints.actions';
import * as complaintsTypes from './complaints.types';

function* getComplaintsSaga({ payload: { currentPage, pageSize } }) {
  try {
    const { userId } = yield select(({ user }) => user);
    const { complaints, pagination } = yield call(userApi.getUserComplaints, { currentPage, pageSize, userId });
    yield put(complaintsActions.getComplaintsSuccess({ complaints, pagination }));
  } catch (error) {
    yield put(complaintsActions.getComplaintsFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createComplaintSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ complaints: { pagination } }) => pagination);
    yield call(complaintsApi.createComplaint, violation);
    yield put(complaintsActions.createComplaintSuccess());
    yield put(modalActions.hideModal());
    yield put(complaintsActions.getComplaintsRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(complaintsActions.createComplaintFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* updateComplaintSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ complaints: { pagination } }) => pagination);
    yield call(complaintsApi.updateComplaint, violation);
    yield put(complaintsActions.updateComplaintSuccess());
    yield put(modalActions.hideModal());
    yield put(complaintsActions.getComplaintsRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(complaintsActions.updateComplaintFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* deleteComplaintSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ complaints: { pagination } }) => pagination);
    yield call(complaintsApi.deleteComplaint, violation.id);
    yield put(complaintsActions.deleteComplaintSuccess());
    yield put(modalActions.hideModal());
    yield put(complaintsActions.getComplaintsRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(complaintsActions.deleteComplaintFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

export default function* complaintsCRUDSaga() {
  yield takeEvery(complaintsTypes.CREATE_COMPLAINT_REQUEST, createComplaintSaga);
  yield takeEvery(complaintsTypes.UPDATE_COMPLAINT_REQUEST, updateComplaintSaga);
  yield takeEvery(complaintsTypes.DELETE_COMPLAINT_REQUEST, deleteComplaintSaga);
  yield takeEvery(complaintsTypes.GET_COMPLAINTS_REQUEST, getComplaintsSaga);
}
