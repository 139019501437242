import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  CircularProgress,
  Tooltip,
  Icon,
  IconButton,
} from '@material-ui/core/';
import { dateHelpers } from '../../helpers';

class ComplaintsTable extends PureComponent {
  static propTypes = {
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
  };

  render() {
    const { data, loading, pagination, onChangeRowsPerPage, onChangePage, onEdit, onDelete } = this.props;

    return (
      <div>
        <Paper className="table-wrapper">
          <div className="table-name">Complaints List</div>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}

          <Table>
            <colgroup>
              <col width="14%" />
              <col width="16%" />
              <col width="25%" />
              <col width="14%" />
              <col width="9%" />
              <col width="12%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Date of Complaint</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Phone or Email</TableCell>
                <TableCell className="center">Resolved</TableCell>
                <TableCell>Resolved Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(complaint => (
                <TableRow className="row" key={complaint.id}>
                  <TableCell>{dateHelpers.periodsFormat(complaint.date)}</TableCell>
                  <TableCell>{complaint.location.name}</TableCell>
                  <TableCell>
                    <Tooltip title={complaint.description} placement="bottom-start">
                      <span className="span">{complaint.description}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={complaint.phoneOrEmail} placement="bottom-start">
                      <span className="span">{complaint.phoneOrEmail}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="center">
                    {complaint.resolved ? (
                      <Tooltip disableFocusListener title="Resolved">
                        <Icon className="check-icon">check_box</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip disableFocusListener title="Unresolved">
                        <Icon className="uncheck-icon">check_box_outline_blank</Icon>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{dateHelpers.periodsFormat(complaint.resolvedDate) || ''}</TableCell>
                  <TableCell>
                    <IconButton className="action-btn edit-btn" onClick={() => onEdit(complaint)}>
                      <Icon className="action-edit">create</Icon>
                    </IconButton>
                    <IconButton className="action-btn" onClick={() => onDelete(complaint)}>
                      <Icon className="action-delete">delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50]}
                  count={pagination.rowCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  SelectProps={{
                    style: {
                      width: 85,
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default ComplaintsTable;
