import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import JSZip from 'jszip';

import { reports, permission } from '../constants';

import checkPermission from './checkPermission';
import { periodsFormat } from './date';

const defaultOptions = {
  fieldSeparator: ',',
  filename: 'Report',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  title: 'Report',
  useBom: true,
  useTextFile: false,
  useKeysAsHeaders: false,
  headers: [],
};

const shouldReturnCsv = true;

const generateEAWI = reportData => {
  const headers = [
    'Location',
    'Classroom',
    'Average Children Enrolled',
    'Average Children Attended',
    'Total Income ($)',
  ];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'averageChildrenEnrolled',
    },
    {
      type: 'classroom',
      path: 'averageChildrenAttended',
    },
    {
      type: 'classroom',
      path: 'totalIncome',
      prefix: '$',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.EAWI,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.EAWI };
};

const generateTCTE = reportData => {
  const headers = ['Location', 'Classroom', 'Teachers', 'Total Expense ($)'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'teachersCount',
    },
    {
      type: 'classroom',
      path: 'totalExpense',
      prefix: '$',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.TCTE,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.TCTE };
};

const generateCATP = reportData => {
  const headers = ['Location', 'Classroom', 'Max Children Allowed', 'From', 'To', 'Count', 'From max ( % )'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'maxChildrenAllowed',
    },
    {
      type: 'datePeriod',
      path: 'dateFrom',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'dateTo',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'averageAttendeesCount',
    },
    {
      type: 'datePeriod',
      path: 'percentOfAttendees',
      suffix: '%',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.CATP,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.CATP };
};

const generateCMBMR = reportData => {
  const headers = ['Location', 'Classroom', 'Monies Billed', 'Monies Received'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'moniesBilled',
      prefix: '$',
    },
    {
      type: 'classroom',
      path: 'moniesReceived',
      prefix: '$',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.CMBMR,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.CMBMR };
};

const generateLATOE = reportData => {
  const headers = ['Location', 'Workarea', 'Avg enrolled', 'Avg attended', 'Position', 'Name', 'Credentials', 'Rate'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'workarea',
      path: 'name',
    },
    {
      type: 'workarea',
      path: 'averageEnrolleesCount',
    },
    {
      type: 'workarea',
      path: 'averageAttendeesCount',
    },
    {
      type: 'employee',
      path: 'position',
    },
    {
      type: 'employee',
      path: 'personFullName',
    },
    {
      type: 'employee',
      path: 'credentials',
    },
    {
      type: 'employee',
      path: 'rate',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.LATOE,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.LATOE };
};

const generateLSW = reportData => {
  const headers = ['Location', 'From', 'To', 'Count of Substitutes'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'datePeriod',
      path: 'dateFrom',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'dateTo',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'sumAmount',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.LSW,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.LSW };
};

const generateIC = reportData => {
  const headers = ['Location', 'Classroom', 'Income', 'Cost Teacher ($)', 'Cost Office ($)'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'income',
      prefix: '$',
    },
    {
      type: 'classroom',
      path: 'costsForTeachers',
      prefix: '$',
    },
    {
      type: 'classroom',
      path: 'costsForOffices',
      prefix: '$',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.IC,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.IC };
};

const generateVRA = reportData => {
  const headers = ['Location', 'Violations'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'location',
      path: 'violationsCount',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.VRA,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.VRA };
};

const generateNPC = reportData => {
  const headers = ['Location', 'Complains'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'location',
      path: 'complaintsCount',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.NPC,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.NPC };
};

const generateNTCC = reportData => {
  const headers = ['Location', 'Classroom', 'Group Type', 'Max Children Allowed', 'Attendees'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'departmentName',
    },
    {
      type: 'classroom',
      path: 'maxChildrenAllowed',
    },
    {
      type: 'classroom',
      path: 'averageAttendeesCount',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.NTCC,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.NTCC };
};

const generateCAE = reportData => {
  const headers = ['Location', 'Classroom', 'From', 'To', 'Enrollees', 'Attendees', 'Attendees / Enrollees ( % )'];

  const fields = [
    {
      type: 'location',
      path: 'name',
    },
    {
      type: 'classroom',
      path: 'name',
    },
    {
      type: 'datePeriod',
      path: 'dateFrom',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'dateTo',
      format: periodsFormat,
    },
    {
      type: 'datePeriod',
      path: 'averageEnrolleesCount',
    },
    {
      type: 'datePeriod',
      path: 'averageAttendeesCount',
    },
    {
      type: 'datePeriod',
      path: 'percentOfAttendees',
      suffix: '%',
    },
  ];

  const data = transformLocations(fields, reportData.locations);

  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    filename: reports.CAE,
    headers: headers,
  });

  const csv = csvExporter.generateCsv(data, shouldReturnCsv);

  return { csv, title: reports.CAE };
};

const transformLocations = (fields, locations) =>
  locations.reduce((allLocations, location) => {
    const defaultTypes = {
      location,
    };

    if (location.classrooms) {
      return [...allLocations, ...transformClassrooms(defaultTypes, fields, location.classrooms)];
    }

    if (location.datePeriods) {
      return [...allLocations, ...transformDatePeriods(defaultTypes, fields, location.datePeriods)];
    }

    if (location.workAreas) {
      return [...allLocations, ...transformWorkAreas(defaultTypes, fields, location.workAreas)];
    }

    return [...allLocations, transformFields(defaultTypes, fields)];
  }, []);

const transformClassrooms = (defaultTypes, fields, classrooms) =>
  classrooms.reduce((allClassrooms, classroom) => {
    const types = {
      ...defaultTypes,
      classroom,
    };

    if (classroom.datePeriods) {
      return [...allClassrooms, ...transformDatePeriods(types, fields, classroom.datePeriods)];
    }

    return [...allClassrooms, transformFields(types, fields)];
  }, []);

const transformDatePeriods = (defaultTypes, fields, datePeriods) =>
  datePeriods.reduce((allDatePeriods, datePeriod) => {
    const types = {
      ...defaultTypes,
      datePeriod,
    };

    return [...allDatePeriods, transformFields(types, fields)];
  }, []);

const transformWorkAreas = (defaultTypes, fields, workAreas) =>
  workAreas.reduce((allWorkAreas, workarea) => {
    const types = {
      ...defaultTypes,
      workarea,
    };

    return [...allWorkAreas, ...transformEmployees(types, fields, workarea.employees)];
  }, []);

const transformEmployees = (defaultTypes, fields, employees) =>
  employees.reduce((allEmployees, employee) => {
    const types = {
      ...defaultTypes,
      employee,
    };

    return [...allEmployees, transformFields(types, fields)];
  }, []);

const transformFields = (defaultTypes, fields) =>
  fields.reduce((allFields, field) => {
    let value = get(defaultTypes[field.type], field.path);

    value = checkValue(value);

    value = `${value}`.replace(/,/g, '');

    if (field.format) {
      value = field.format(value);
    }

    return {
      ...allFields,
      [`${field.type}_${field.path}`]: `${addPrefix(field.prefix)}${value}${addSuffix(field.suffix)}`,
    };
  }, {});

const addPrefix = prefix => prefix || '';

const addSuffix = suffix => suffix || '';

const checkValue = value => (value === null || value === undefined ? '' : value);

export const generateCSV = (reportType, reportData, permissions) => {
  if (!reportData) {
    return;
  }

  let data = '';

  switch (reportType) {
    case reports.EAWI:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      data = generateEAWI(reportData);
      break;

    case reports.TCTE:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      data = generateTCTE(reportData);
      break;

    case reports.TGW:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      // data = generateTGW(reportData);
      break;

    case reports.CATP:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateCATP(reportData);
      break;

    case reports.VRA:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateVRA(reportData);
      break;

    case reports.NPC:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateNPC(reportData);
      break;

    case reports.CMBMR:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      data = generateCMBMR(reportData);
      break;

    case reports.LATOE:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateLATOE(reportData);
      break;

    case reports.LSW:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateLSW(reportData);
      break;

    case reports.NTCC:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateNTCC(reportData);
      break;

    case reports.CAE:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      data = generateCAE(reportData);
      break;

    case reports.IC:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      data = generateIC(reportData);
      break;

    default:
      return;
  }

  const file = new File([data.csv], `${data.title}.csv`, { type: 'text/plain;charset=utf-8' });

  saveAs(file);
};

export const generateZIPCSV = async (reportData, permissions) => {
  if (!reportData) {
    return;
  }

  if (Object.values(reportData.spinners).some(spinner => spinner)) {
    return;
  }

  const {
    enrolledAttendedWithIncome,
    teachersInClassroom,
    moniesBilledVsReceived,
    teachersAndEmployees,
    incomeAndCosts,
    listOfSubstitutes,
    attendeesByPeriod,
    violationsCount,
    complaintsCount,
    teachersNeededByCalculating,
    attendeesVsEnrollees,
  } = reportData;

  let files = [];

  if (checkPermission(permissions, permission.NON_FINANCIAL)) {
    files = [
      // generateTGW(null),
      generateCATP(attendeesByPeriod),
      generateVRA(violationsCount),
      generateNPC(complaintsCount),
      generateLATOE(teachersAndEmployees),
      generateNTCC(teachersNeededByCalculating),
      generateCAE(attendeesVsEnrollees),
      generateLSW(listOfSubstitutes),
    ];
  }

  if (checkPermission(permissions, permission.FINANCIAL)) {
    files = [
      ...files,
      generateEAWI(enrolledAttendedWithIncome),
      generateTCTE(teachersInClassroom),
      generateCMBMR(moniesBilledVsReceived),
      generateIC(incomeAndCosts),
    ];
  }

  if (!files.length) return;

  const zip = new JSZip();

  files.forEach(file => {
    zip.file(`${file.title}.csv`, file.csv);
  });

  const zipped = await zip.generateAsync({ type: 'blob' });

  saveAs(zipped, 'Reports.zip', { autoBom: true });
};
