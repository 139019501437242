import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, LinearProgress } from '@material-ui/core/';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import ButtonWithSpinner from '../../components/button-with-spinner';

import 'react-day-picker/lib/style.css';
import { dateHelpers } from '../../helpers';
import { getSchedulerRequest, setSchedulerRequest } from './scheduler-page.actions';

function DayPickerNavbar({ onPreviousClick, onNextClick, className }) {
  return (
    <div className={className}>
      <Button onClick={() => onPreviousClick()} variant="outlined" className="nav-button nav-button--back">
        <Icon>arrow_back</Icon>
      </Button>
      <Button onClick={() => onNextClick()} variant="outlined" className="nav-button nav-button--forward">
        <Icon>arrow_forward</Icon>
      </Button>
    </div>
  );
}

@connect(
  ({ scheduler: { schedule, spinners } }) => ({ schedule, spinners }),
  { getSchedulerRequest, setSchedulerRequest }
)
class Scheduler extends PureComponent {
  static propTypes = {};

  state = {
    schedule: [],
    addedDaysOff: [],
    deletedDaysOff: [],
  };

  componentDidMount() {
    this.props.getSchedulerRequest();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schedule !== this.props.schedule) {
      this.setState({ schedule: this.props.schedule.map(day => new Date(day)), addedDaysOff: [], deletedDaysOff: [] });
    }
  }

  dayClickHandler = (selectedDay, { disabled }) => {
    if (disabled) return;

    const { schedule, addedDaysOff, deletedDaysOff } = this.state;
    const isRemoved = this.props.schedule.some(day => dateHelpers.isSame(day, selectedDay));

    if (!isRemoved) {
      const daysOff = addedDaysOff.some(day => dateHelpers.isSame(day, selectedDay))
        ? addedDaysOff.filter(day => !dateHelpers.isSame(day, selectedDay))
        : [...addedDaysOff, selectedDay];
      this.setState({ addedDaysOff: daysOff });
    } else {
      const isPreviouslyRemoved = deletedDaysOff.some(day => dateHelpers.isSame(day, selectedDay));
      if (isPreviouslyRemoved) {
        this.setState({
          deletedDaysOff: deletedDaysOff.filter(day => !dateHelpers.isSame(day, selectedDay)),
          schedule: [...schedule, selectedDay],
        });
      } else {
        this.setState({
          deletedDaysOff: [...deletedDaysOff, selectedDay],
          schedule: schedule.filter(day => !dateHelpers.isSame(day, selectedDay)),
        });
      }
    }
  };

  onSubmitHandler = () => {
    const { addedDaysOff, deletedDaysOff } = this.state;
    const { setSchedulerRequest } = this.props;
    setSchedulerRequest({ addedDaysOff, deletedDaysOff });
  };

  onCancelHandler = () => {
    this.setState({ addedDaysOff: [], deletedDaysOff: [], schedule: this.props.schedule.map(day => new Date(day)) });
  };

  get isSaveBtnDisabled() {
    const { addedDaysOff, deletedDaysOff } = this.state;
    return !addedDaysOff.length && !deletedDaysOff.length;
  }

  render() {
    const { schedule, addedDaysOff } = this.state;
    const { spinners } = this.props;

    return spinners.getSchedule ? (
      <LinearProgress />
    ) : (
      <div className="container scheduler-page">
        <h1 className="scheduler-page__title">API Scheduler</h1>
        <p>
          Click on days, when API import data will not work, and click Save. <br />
          To turn on API import again, unmark date in calendar and click Save.
        </p>
        <div>
          <DayPicker
            disabledDays={[{ daysOfWeek: [0, 6] }, { before: new Date() }]}
            selectedDays={[...schedule, ...addedDaysOff]}
            onDayClick={this.dayClickHandler}
            navbarElement={<DayPickerNavbar />}
          />
          <div className="scheduler-page__buttons">
            <Button onClick={this.onCancelHandler} className="light-btn dark scheduler-page__cancel-button">
              CANCEL
            </Button>
            <ButtonWithSpinner
              type="button"
              onClick={this.onSubmitHandler}
              loading={spinners.setSchedule}
              variant="contained"
              disabled={this.isSaveBtnDisabled}
              color="primary"
              className="scheduler-page__save-button"
            >
              SAVE
            </ButtonWithSpinner>
          </div>
        </div>
      </div>
    );
  }
}

export default Scheduler;
