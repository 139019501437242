import { takeEvery, put, call, select } from 'redux-saga/effects';

import { userApi, violationsApi } from '../../api';

import { snackActions } from '../../components/snack-bar';
import { modalActions } from '../../components/modal';

import * as violationsActions from './violations.actions';
import * as violationsTypes from './violations.types';

function* getViolationsSaga({ payload: { currentPage, pageSize } }) {
  try {
    const { userId } = yield select(({ user }) => user);
    const { violations, pagination } = yield call(userApi.getUserViolations, { currentPage, pageSize, userId });
    yield put(violationsActions.getViolationsSuccess({ violations, pagination }));
  } catch (error) {
    yield put(violationsActions.getViolationsFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createViolationSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ violations: { pagination } }) => pagination);
    yield call(violationsApi.createViolation, violation);
    yield put(violationsActions.createViolationSuccess());
    yield put(modalActions.hideModal());
    yield put(violationsActions.getViolationsRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(violationsActions.createViolationFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* updateViolationSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ violations: { pagination } }) => pagination);
    yield call(violationsApi.updateViolation, violation);
    yield put(violationsActions.updateViolationSuccess());
    yield put(modalActions.hideModal());
    yield put(violationsActions.getViolationsRequest({ currentPage, pageSize }));
  } catch (error) {
    console.log(error);
    yield put(violationsActions.updateViolationFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* deleteViolationSaga({ payload: violation }) {
  try {
    const { currentPage, pageSize } = yield select(({ violations: { pagination } }) => pagination);
    yield call(violationsApi.deleteViolation, violation.id);
    yield put(violationsActions.deleteViolationSuccess());
    yield put(modalActions.hideModal());
    yield put(violationsActions.getViolationsRequest({ currentPage, pageSize }));
  } catch (error) {
    yield put(violationsActions.deleteViolationFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

export default function* violationsCRUDSaga() {
  yield takeEvery(violationsTypes.CREATE_VIOLATION_REQUEST, createViolationSaga);
  yield takeEvery(violationsTypes.UPDATE_VIOLATION_REQUEST, updateViolationSaga);
  yield takeEvery(violationsTypes.DELETE_VIOLATION_REQUEST, deleteViolationSaga);
  yield takeEvery(violationsTypes.GET_VIOLATIONS_REQUEST, getViolationsSaga);
}
