import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';

import VirtualizedList from '../../../components/virtualized-list';

const dummy = {
  totalTours: 19.2,
  locations: [
    {
      name: '2901 White Plains',
      totalTours: 23,
      classrooms: [
        {
          name: 'Room 1A (0-12 Mo Max 8)',
          totalTours: 23,
        },
        {
          name: 'Room 1A (0-12 Mo Max 8)',
          totalTours: 23,
        },
      ],
    },
  ],
};
class Report extends PureComponent {
  static propTypes = {
    // report: PropTypes.object,
    byLocation: PropTypes.number,
  };

  state = {
    title: '',
    titleIndex: -1,
  };

  cacheByClassrooms = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByClassroom = ({ index, style, parent, key }) => {
    // const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByClassrooms} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="group-row">{dummy.locations[index].name}</div>
          {dummy.locations[index].classrooms.map((classroom, i) => (
            <div className="row" key={`${dummy.locations[index]}-${classroom.name}-${i}`}>
              <div className="cell">{classroom.name}</div>
              <div className="cell">{classroom.totalTours}</div>
            </div>
          ))}
        </div>
      </CellMeasurer>
    );
  };

  rowByLocation = ({ index, style, parent, key }) => {
    // const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell">{dummy.locations[index].name}</div>
            <div className="cell">{dummy.locations[index].totalTours}</div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  rowsRenderHandler = ({ startIndex }) => {
    // const { report } = this.props;
    const { titleIndex } = this.state;

    if (startIndex !== titleIndex) {
      this.setState({ title: dummy.locations[startIndex].name, titleIndex: startIndex });
    }
  };

  render() {
    // const { byLocation, report } = this.props;
    const { byLocation } = this.props;
    const { title } = this.state;

    return get(dummy, 'locations', []).length ? (
      !byLocation ? (
        <>
          <div className="group-title">{title}</div>
          <div className="table-header">
            <div className="header-cell">Classroom</div>
            <div className="header-cell">Tours</div>
          </div>
          <VirtualizedList
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByClassroom}
            rowCount={dummy.locations.length}
            deferredMeasurementCache={this.cacheByClassrooms}
            rowHeight={this.cacheByClassrooms.rowHeight}
          />
        </>
      ) : (
        <>
          <div className="table-header">
            <div className="header-cell">Location</div>
            <div className="header-cell">Tours</div>
          </div>
          <VirtualizedList
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByLocation}
            rowCount={dummy.locations.length}
            deferredMeasurementCache={this.cacheByLocations}
            rowHeight={this.cacheByLocations.rowHeight}
          />
          <div className="table-footer">
            <div className="footer-cell">Total</div>
            <div className="footer-cell">{dummy.totalTours}</div>
          </div>
        </>
      )
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
