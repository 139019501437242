import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';

import ButtonWithSpinner from '../../components/button-with-spinner';

class ComplaintDeleteModal extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool,
  };

  onSubmitHandler = () => {
    const { complaint, onSubmit } = this.props;
    onSubmit(complaint);
  };

  render() {
    const { open, onClose, loading } = this.props;

    return (
      <Dialog className="confirm-modal" open={open} onClose={onClose}>
        <DialogTitle className="modal-header">Delete Complaint</DialogTitle>
        <DialogContent>
          <DialogContentText className="text">Are you sure you want to delete complaint from list?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="footer-buttons">
            <ButtonWithSpinner onClick={this.onSubmitHandler} loading={loading} autoFocus className="light-btn dark">
              DELETE
            </ButtonWithSpinner>
            <Button onClick={onClose} color="primary" className="light-btn">
              CANCEL
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ComplaintDeleteModal;
