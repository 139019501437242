import * as types from './users-page.types';

// CREATE USER
export const createUserRequest = payload => ({
  type: types.CREATE_USER_REQUEST,
  payload,
});
export const createUserSuccess = () => ({
  type: types.CREATE_USER_SUCCESS,
});
export const createUserFail = () => ({
  type: types.CREATE_USER_FAIL,
});

// UPDATE USER
export const updateUserRequest = payload => ({
  type: types.UPDATE_USER_REQUEST,
  payload,
});
export const updateUserSuccess = () => ({
  type: types.UPDATE_USER_SUCCESS,
});
export const updateUserFail = () => ({
  type: types.UPDATE_USER_FAIL,
});

// DELETE USER
export const deleteUserRequest = payload => ({
  type: types.DELETE_USER_REQUEST,
  payload,
});
export const deleteUserSuccess = () => ({
  type: types.DELETE_USER_SUCCESS,
});
export const deleteUserFail = () => ({
  type: types.DELETE_USER_FAIL,
});

// GET USERS
export const getUsersRequest = payload => ({
  type: types.GET_USERS_REQUEST,
  payload,
});
export const getUsersSuccess = payload => ({
  type: types.GET_USERS_SUCCESS,
  payload,
});
export const getUsersFail = () => ({
  type: types.GET_USERS_FAIL,
});

// EMAIL
export const checkEmailRequest = payload => ({
  type: types.CHECK_EMAIL_REQUEST,
  payload,
});
export const checkEmailSuccess = payload => ({
  type: types.CHECK_EMAIL_SUCCESS,
  payload,
});
export const checkEmailFail = () => ({
  type: types.CHECK_EMAIL_FAIL,
});
