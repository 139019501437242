export const CREATE_USER_MODAL = 'CREATE_USER_MODAL';
export const UPDATE_USER_MODAL = 'UPDATE_USER_MODAL';
export const DELETE_USER_MODAL = 'DELETE_USER_MODAL';
export const CREATE_SUBSTITUTE = 'CREATE_SUBSTITUTE';
export const CREATE_COMPLAINT = 'CREATE_COMPLAINT';
export const UPDATE_COMPLAINT = 'UPDATE_COMPLAINT';
export const DELETE_COMPLAINT = 'DELETE_COMPLAINT';
export const CREATE_VIOLATION = 'CREATE_VIOLATION';
export const UPDATE_VIOLATION = 'UPDATE_VIOLATION';
export const DELETE_VIOLATION = 'DELETE_VIOLATION';
