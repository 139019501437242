import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Icon } from '@material-ui/core';

import AppBarMenu from './menu';

@withRouter
class DashboardHeader extends Component {
  state = { anchorEl: null };

  openMenuHandler = e => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  closeMenuHandler = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { location, hasMenu } = this.props;

    return (
      <AppBar className="header">
        <div className="container">
          <Toolbar disableGutters={true}>
            <div className="logo">
              <img src="/assets/images/logo.svg" width="249" height="77" alt="Beanstalk Academy" />
            </div>
            {hasMenu && (
              <IconButton
                className="header__menu"
                color="inherit"
                aria-label="menu"
                aria-owns={anchorEl && 'menu'}
                onClick={this.openMenuHandler}
              >
                <Icon>more_vert</Icon>
              </IconButton>
            )}
          </Toolbar>
          <AppBarMenu anchorEl={anchorEl} onClose={this.closeMenuHandler} path={location.pathname} />
        </div>
      </AppBar>
    );
  }
}

export default DashboardHeader;
