import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';

import ButtonWithSpinner from '../../../components/button-with-spinner';

class UserDeleteModal extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      viewNonFinancialReports: PropTypes.bool.isRequired,
      viewFinancialReports: PropTypes.bool.isRequired,
      locations: PropTypes.array.isRequired,
    }),
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool,
  };

  get fullName() {
    const { firstName, lastName } = this.props.user;
    return `${firstName} ${lastName}`;
  }

  onSubmitHandler = () => {
    const { user, onSubmit } = this.props;
    onSubmit(user);
  };

  render() {
    const { open, onClose, loading } = this.props;

    return (
      <Dialog className="confirm-modal" open={open} onClose={onClose}>
        <DialogTitle className="modal-header">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText className="text">
            Are you sure to delete user <span className="mark-text">{this.fullName}</span> from list?
            <br />
            He will not have access to Dashboards after that.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="footer-buttons">
            <ButtonWithSpinner onClick={this.onSubmitHandler} loading={loading} autoFocus className="light-btn dark">
              DELETE
            </ButtonWithSpinner>
            <Button onClick={onClose} color="primary" className="light-btn">
              CANCEL
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UserDeleteModal;
