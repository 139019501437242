import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';

import VirtualizedList from '../../../components/virtualized-list';
import { dateHelpers } from '../../../helpers';

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
  };

  state = {
    scrollBarSize: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.ByLocationsList.current && this.ByLocationsList.current.forceUpdateGrid();
    }
  }

  ByLocationsList = React.createRef();
  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByLocation = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell label-cell w-24">{report.locations[index].name}</div>
            <div className="cell date-cell w-76">
              {report.locations[index].datePeriods.map((period, i) => (
                <div className="cell" key={i}>
                  {period.sumAmount}
                </div>
              ))}
            </div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  detectScrollBar = ({ vertical, size }) => {
    this.setState({ scrollBarSize: vertical ? size : 0 });
  };

  render() {
    const { report, loading } = this.props;
    const { scrollBarSize } = this.state;

    return get(report, 'locations', []).length ? (
      <>
        <div className="complex-table-header">
          <div className="header-cell label-cell w-24">Location</div>
          <div className="date-cell w-76">
            <div className="header-cell" style={{ paddingRight: scrollBarSize }}>
              Count of Substitutes
            </div>
            <div className="header-cell complex-cell" style={{ paddingRight: scrollBarSize }}>
              {report.datePeriods.map((period, i) => (
                <div className="sub-cell" key={i}>
                  {`${dateHelpers.periodsFormat(period.dateFrom)} -`}
                  <br />
                  {dateHelpers.periodsFormat(period.dateTo)}
                </div>
              ))}
            </div>
          </div>
        </div>
        {loading && (
          <div className="table-spinner">
            <CircularProgress />
          </div>
        )}
        <VirtualizedList
          ref={this.ByLocationsList}
          rowRenderer={this.rowByLocation}
          rowCount={report.locations.length}
          deferredMeasurementCache={this.cacheByLocations}
          rowHeight={this.cacheByLocations.rowHeight}
          onScrollbarPresenceChange={this.detectScrollBar}
        />
        <div className="complex-table-footer" style={{ paddingRight: scrollBarSize }}>
          <div className="footer-cell label-cell w-24">Total</div>
          <div className="date-cell w-76">
            {report.datePeriods.map((period, i) => (
              <div className="footer-cell" key={i}>
                {period.sumAmount}
              </div>
            ))}
          </div>
        </div>
      </>
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
