export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';

export const GET_USER_LOCATIONS_REQUEST = 'GET_USER_LOCATIONS_REQUEST';
export const GET_USER_LOCATIONS_SUCCESS = 'GET_USER_LOCATIONS_SUCCESS';
export const GET_USER_LOCATIONS_FAIL = 'GET_USER_LOCATIONS_FAIL';

export const GET_WORKAREAS_REQUEST = 'GET_WORKAREAS_REQUEST';
export const GET_WORKAREAS_SUCCESS = 'GET_WORKAREAS_SUCCESS';
export const GET_WORKAREAS_FAIL = 'GET_WORKAREAS_FAIL';
