export const reports = {
  EAWI: 'Average Enrolled, Attended with Income',
  TCTE: 'Teachers in the Classroom with the Total Expense',
  TGW: 'Tours, Given this Week',
  CATP: 'Compare Attendees by Time Period',
  VRA: 'Violations, Received with Amounts',
  NPC: "Number of Parents' Complaints",
  CMBMR: 'Compare Monies Billed vs Monies Received',
  LATOE: 'List of All Teachers and Office Employees',
  LSW: 'List of Substitutes by Week',
  NTCC: 'Number of teachers needed by calculating if classes can be combined without reaching the max per class',
  CAE: 'Compare Attendees versus Enrollees',
  IC: 'Income and Cost',
  CTC: 'Count of Teachers per Classroom',
};

export const highlightColors = ['#C0CA33', '#7BBC6F', '#4FC3F7', '#F46F5A', '#A6BAFF'];
