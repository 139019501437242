// CREATE VIOLATION
export const CREATE_VIOLATION_REQUEST = 'CREATE_VIOLATION_REQUEST';
export const CREATE_VIOLATION_SUCCESS = 'CREATE_VIOLATION_SUCCESS';
export const CREATE_VIOLATION_FAIL = 'CREATE_VIOLATION_FAIL';

// UPDATE VIOLATION
export const UPDATE_VIOLATION_REQUEST = 'UPDATE_VIOLATION_REQUEST';
export const UPDATE_VIOLATION_SUCCESS = 'UPDATE_VIOLATION_SUCCESS';
export const UPDATE_VIOLATION_FAIL = 'UPDATE_VIOLATION_FAIL';

// DELETE VIOLATION
export const DELETE_VIOLATION_REQUEST = 'DELETE_VIOLATION_REQUEST';
export const DELETE_VIOLATION_SUCCESS = 'DELETE_VIOLATION_SUCCESS';
export const DELETE_VIOLATION_FAIL = 'DELETE_VIOLATION_FAIL';

// GET VIOLATIONS
export const GET_VIOLATIONS_REQUEST = 'GET_VIOLATIONS_REQUEST';
export const GET_VIOLATIONS_SUCCESS = 'GET_VIOLATIONS_SUCCESS';
export const GET_VIOLATIONS_FAIL = 'GET_VIOLATIONS_FAIL';
