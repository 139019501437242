import { takeEvery, put, call, all, select, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import { substitutionApi, reportsApi } from '../../api';

import * as dashboardActions from './dashboard-page.actions';
import * as dashboardTypes from './dashboard-page.types';
import { snackActions } from '../../components/snack-bar';
import { modalActions } from '../../components/modal';
import { createHistoryRequest } from '../history-log-page';
import { locationActions, locationTypes } from '../../shared/locations';
import { prettifiers } from '../../helpers';
import { logTypes, highlightColors } from '../../constants';

function* initialFiltersSaga() {
  try {
    yield put(locationActions.getUserLocationsRequest());
    yield take(locationTypes.GET_USER_LOCATIONS_SUCCESS);
    const { userLocations } = yield select(({ locations }) => locations);
    const locationIds = userLocations && userLocations.length ? userLocations.map(location => location.id) : [];
    const dateFrom = moment()
      .subtract(1, 'weeks')
      .startOf('week');
    const dateTo = moment()
      .subtract(1, 'weeks')
      .endOf('week');
    yield put(dashboardActions.applyFiltersRequest({ locationIds, dateFrom, dateTo, initial: true }));
    yield put(dashboardActions.changeFilters({ dateFrom, dateTo }));
  } catch (error) {
    console.log(error);
  }
}

function* addSubstitutionSaga({ payload }) {
  try {
    yield call(substitutionApi.addSubstitution, payload);
    yield put(dashboardActions.addSubstitutionSuccess());
    yield put(modalActions.hideModal());
  } catch (error) {
    yield put(dashboardActions.addSubstitutionFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createHistorySaga({ payload: { dateFrom, dateTo, locationIds, locations, initial } }) {
  if (initial) return;
  try {
    yield all([
      put(createHistoryRequest(prettifiers.historyLogMessage(logTypes.LOCATION_FILTER, { locationIds, locations }))),
      put(createHistoryRequest(prettifiers.historyLogMessage(logTypes.DATE_FILTER, { dateFrom, dateTo }))),
    ]);
  } catch (error) {
    console.log(error);
  }
}

function* enrolledAttendedWithIncome({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getEnrolledAttendedWithIncome({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getEnrolledAttendedWithIncomeSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getEnrolledAttendedWithIncomeFail());
  }
}

function* teachersInClassroom({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getTeachersInClassroom({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getTeachersInClassroomSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getTeachersInClassroomFail());
  }
}

function* teachersAndEmployees({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getTeachersAndEmployees({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getTeachersAndEmployeesSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getTeachersAndEmployeesFail());
  }
}

function* moniesBilledVsReceived({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getMoniesBilledVsReceived({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getMoniesBilledVsReceivedSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getMoniesBilledVsReceivedFail());
  }
}

function* listOfSubstitutes({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getListOfSubstitutes({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getListOfSubstitutesSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getListOfSubstitutesFail());
  }
}

function* incomeAndCosts({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getIncomeAndCosts({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getIncomeAndCostsSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getIncomeAndCostsFail());
    console.log(error);
  }
}

function* attendeesByPeriod({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getAttendeesByPeriod({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getAttendeesByPeriodSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getAttendeesByPeriodFail());
  }
}

function* violationsCount({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getViolationsCount({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getViolationsCountSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getViolationsCountFail());
  }
}

function* complaintsCount({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getComplaintsCount({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getComplaintsCountSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getComplaintsCountFail());
  }
}

function* attendeesVsEnrollees({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getAttendeesVsEnrollees({
      dateFrom,
      dateTo,
      locationIds,
    });
    yield put(dashboardActions.getAttendeesVsEnrolleesSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getAttendeesVsEnrolleesFail());
  }
}

function* teachersByCalculating({ payload: { dateFrom, dateTo, locationIds } }) {
  try {
    const report = yield reportsApi.getTeachersNeededByCalculating({
      dateFrom,
      dateTo,
      locationIds,
    });

    const canBeGrouped = (currRoom, nextRoom) => {
      return (
        currRoom.maxChildrenAllowed >
        parseFloat(get(currRoom, 'averageAttendeesCount', '')) + parseFloat(get(nextRoom, 'averageAttendeesCount', ''))
      );
    };

    const groupRoomsBy = (rooms, { name, ageFrom, ageTo }) => {
      return Object.values(
        groupBy(rooms, room => {
          return `${room[name]}#${get(room, ageTo)}#${get(room, ageFrom)}`;
        })
      );
    };

    for (let i = 0; i < report.locations.length; i++) {
      const groupedRooms = groupRoomsBy(report.locations[i].classrooms, {
        name: 'departmentName',
        ageFrom: 'ageBreakdown.to',
        ageTo: 'ageBreakdown.to',
      });

      let colorIndex = 0;

      for (let j = 0; j < groupedRooms.length; j++) {
        let colorUsed = false;
        for (let k = 0; k < groupedRooms[j].length; k++) {
          let currRoom = groupedRooms[j][k];

          for (let l = 0; l < groupedRooms[j].length; l++) {
            let nextRoom = groupedRooms[j][l];
            if (k === l) continue;
            if (canBeGrouped(currRoom, nextRoom)) {
              colorUsed = true;
              currRoom.groupColor = highlightColors[colorIndex % highlightColors.length];
              nextRoom.groupColor = highlightColors[colorIndex % highlightColors.length];
            }
          }
        }

        if (colorUsed) {
          colorIndex++;
        }
      }
    }

    yield put(dashboardActions.getTeachersNeededByCalculatingSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getTeachersNeededByCalculatingFail());
    console.log(error);
  }
}

function* countOfTeachersPerClassroom({ payload: { locationIds } }) {
  try {
    const report = yield reportsApi.getCountOfTeachersPerClassroom({
      locationIds,
    });
    yield put(dashboardActions.getCountOfTeachersPerClassroomSuccess(report));
  } catch (error) {
    yield put(dashboardActions.getCountOfTeachersPerClassroomFail());
  }
}

export default function* dashboardSaga() {
  yield takeEvery(dashboardTypes.ADD_SUBSTITUTION_REQUEST, addSubstitutionSaga);
  yield takeEvery(dashboardTypes.APPLY_FILTERS_REQUEST, createHistorySaga);
  yield takeEvery(dashboardTypes.APPLY_INITIAL_FILTERS_REQUEST, initialFiltersSaga);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, enrolledAttendedWithIncome);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, teachersInClassroom);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, teachersAndEmployees);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, teachersByCalculating);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, moniesBilledVsReceived);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, listOfSubstitutes);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, incomeAndCosts);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, attendeesByPeriod);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, violationsCount);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, complaintsCount);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, attendeesVsEnrollees);
  yield takeLatest(dashboardTypes.APPLY_FILTERS_REQUEST, countOfTeachersPerClassroom);
}
