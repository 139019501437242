import * as types from './modal.types';

export const openModal = payload => ({
  type: types.OPEN_MODAL,
  payload,
});

export const hideModal = payload => ({
  type: types.HIDE_MODAL,
  payload,
});
