import axios from 'axios';
import config from '../../config.js';
import AUTH_TOKEN from '../constants';
import { history } from '../helpers';

const http = axios.create({
  baseURL: config.BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

http.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      history.replace('/login');
      error.response.snackMessage = 'Invalid session key or session expired. Try to log in again';
    }
    if (error.response.data) {
      const errorMessages = Object.values(error.response.data);
      error.response.snackMessage = errorMessages[0][0];
    } else {
      error.response.snackMessage = 'Something went wrong. Please, try again later';
    }
    return Promise.reject(error);
  }
);

export default http;
