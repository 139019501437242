import moment from 'moment';
import { get, isArray, isString } from 'lodash';
import Bowser from 'bowser';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { reports, permission } from '../constants';

import checkPermission from './checkPermission';
import { periodsFormat } from './date';
import { currency } from './prettifiers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

//   'font',
//   'fontSize',
//   'fontFeatures',
//   'bold',
//   'italics',
//   'alignment',
//   'color',
//   'columnGap',
//   'fillColor',
//   'decoration',
//   'decorationStyle',
//   'decorationColor',
//   'background',
//   'lineHeight',
//   'characterSpacing',
//   'noWrap',
//   'markerColor',
//   'leadingIndent'

const styles = {
  default: {
    fontSize: 12,
    alignment: 'center',
  },
  header: {
    alignment: 'left',
  },
  table: {
    header: {
      fontSize: 14,
      bold: true,
    },
    subHeader: {
      // fillColor: 'green',
    },
    total: {
      bold: true,
    },
  },
};

const landscapeReports = [reports.CATP, reports.CAE, reports.LATOE];

const margin = {
  document: [30, 30, 30, 30],
  header: [5, 5, 5, 10],
  table: {
    header: [5, 20, 5, 20],
    subHeader: [5, 15, 5, 15],
    subTitle: [5, 10, 5, 10],
    row: [5, 5, 5, 5],
  },
};

const prefixTypes = {
  currency: 'currency',
};

const generateEAWI = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = [
    'name',
    'averageChildrenEnrolled',
    'averageChildrenAttended',
    { text: 'totalIncome', prefixType: prefixTypes.currency },
  ];
  const totalFields = [
    'totalAverageChildrenEnrolled',
    'totalAverageChildrenAttended',
    { text: 'totalIncome', prefixType: prefixTypes.currency },
  ];
  const headers = [{ text: reports.EAWI, colSpan: 4 }, null, null, null];
  const subHeaders = [
    openTab ? 'Location' : 'Classroom',
    'Average Children Enrolled',
    'Average Children Attended',
    'Total Income ($)',
  ];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateCTC = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = [
    { text: 'classroomName', rowSpan: null },
    { text: 'classroomTeachersCount', rowSpan: null },
    { text: 'leadTeacherPersonFullName' },
    { text: 'assistantTeacherPersonFullName' },
  ];

  const headers = [{ text: reports.LATOE, colSpan: fields.length }, null, null, null];

  const subHeaders = ['Name', 'Teachers Count', 'Lead Teacher', 'Assistant Teacher'];

  const mappedReportData = {
    locations: reportData.map(location => ({
      ...location,
      classrooms: location.classrooms.reduce((allClassrooms, classroom) => {
        const defaultFields = {
          classroomName: classroom.name,
          classroomTeachersCount: classroom.teachersCount,
          rowSpanCount: classroom.teachersCount,
          // rowSpanCount:
          //   classroom.leadTeachers.length > classroom.assistantTeachers.length
          //     ? classroom.leadTeachers.length
          //     : classroom.assistantTeachers.length,
        };

        const classRoomData = !classroom.teachersCount
          ? [
              {
                classroomName: defaultFields.classroomName,
                classroomTeachersCount: defaultFields.classroomTeachersCount,
              },
            ]
          : [
              ...classroom.leadTeachers.map(lead => ({
                ...defaultFields,
                leadTeacherPersonFullName: lead.personFullName,
              })),
              ...classroom.assistantTeachers.map(assistant => ({
                ...defaultFields,
                assistantTeacherPersonFullName: assistant.personFullName,
              })),
            ];

        return [...allClassrooms, ...classRoomData];
      }, []),
    })),
  };

  return createContent(mappedReportData, openTab, headers, fields, [], subHeaders, pageBreak, pageOrientation);
};

const generateTCTE = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = ['name', 'teachersCount', { text: 'totalExpense', prefixType: prefixTypes.currency }];
  const totalFields = ['totalTeachersCount', { text: 'totalExpense', prefixType: prefixTypes.currency }];
  const headers = [{ text: reports.TCTE, colSpan: 3 }, null, null];
  const subHeaders = [openTab ? 'Location' : 'Classroom', 'Teachers', 'Total Expense ($)'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateCATP = (reportData, openTab, pageBreak, pageOrientation) => {
  const staticFields = ['name', 'maxChildrenAllowed'];

  const dynamicFields = reportData.locations[0].datePeriods.map((datePeriod, index) => ({
    fields: [
      `datePeriods[${index}].averageAttendeesCount`,
      { text: `datePeriods[${index}].percentOfAttendees`, suffix: '%' },
    ],
    delimeter: ' / ',
  }));

  const staticTotalFields = ['totalMaxChildrenAllowed'];

  const dynamicTotalFields = reportData.datePeriods.map((datePeriod, index) => ({
    fields: [
      `datePeriods[${index}].averageAttendeesCount`,
      { text: `datePeriods[${index}].percentOfAttendees`, suffix: '%' },
    ],
    delimeter: ' / ',
  }));

  const nullableStaticHeaders = [...staticFields];
  nullableStaticHeaders.pop();

  const nullableDynamicHeaders = [...dynamicFields];

  const dynamicHeaders = [
    { text: reports.CATP, colSpan: staticFields.length + dynamicFields.length },
    ...nullableStaticHeaders.fill(null),
    ...nullableDynamicHeaders.fill(null),
  ];

  const dynamicSubHeaders = [
    [
      { text: openTab ? 'Location' : 'Classroom', rowSpan: 2 },
      { text: 'Max Children Allowed', rowSpan: 2 },
      { text: 'Count / From max ( % )', colSpan: dynamicFields.length },
    ],
    [
      ...[...staticFields].fill(null),
      ...reportData.datePeriods.map(datePeriod => ({
        text: `${periodsFormat(datePeriod.dateFrom)} - ${periodsFormat(datePeriod.dateTo)}`,
      })),
    ],
  ];

  return createContent(
    reportData,
    openTab,
    dynamicHeaders,
    [...staticFields, ...dynamicFields],
    [...staticTotalFields, ...dynamicTotalFields],
    dynamicSubHeaders,
    pageBreak,
    pageOrientation
  );
};

const generateVRA = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = ['name', 'violationsCount'];
  const totalFields = ['totalViolationsCount'];
  const headers = [{ text: reports.VRA, colSpan: 2 }, null];
  const subHeaders = ['Location', 'Violations'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateNPC = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = ['name', 'complaintsCount'];
  const totalFields = ['totalComplaintsCount'];
  const headers = [{ text: reports.NPC, colSpan: 2 }, null];
  const subHeaders = ['Location', 'Complains'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateCMBMR = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = [
    'name',
    { text: 'moniesBilled', prefixType: prefixTypes.currency },
    { text: 'moniesReceived', prefixType: prefixTypes.currency },
  ];
  const totalFields = [
    { text: 'totalMoniesBilled', prefixType: prefixTypes.currency },
    { text: 'totalMoniesReceived', prefixType: prefixTypes.currency },
  ];
  const headers = [{ text: reports.CMBMR, colSpan: 3 }, null, null];
  const subHeaders = [openTab ? 'Location' : 'Classroom', 'Monies Billed', 'Monies Received'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateLATOE = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = openTab
    ? [
        'name',
        'classroomsCount',
        'averageEnrolleesCount',
        'averageAttendeesCount',
        'teachersCount',
        'officeWorkersCount',
      ]
    : [
        { text: 'workAreaName', rowSpan: null },
        { text: 'workAreaAverageEnrolleesCount', rowSpan: null },
        { text: 'workAreaAverageAttendeesCount', rowSpan: null },
        { text: 'employeePosition' },
        { text: 'employeePersonFullName' },
        { text: 'employeeCredentials' },
        { text: 'employeeRate', prefixType: prefixTypes.currency, hideNullableValues: true },
        { text: 'employeeSchedule' },
      ];

  const totalFields = [
    'totalClassroomsCount',
    'totalAverageChildrenEnrolled',
    'totalAverageChildrenAttended',
    'totalTeachersCount',
    'totalOfficeWorkersCount',
  ];

  const headers = openTab
    ? [{ text: reports.LATOE, colSpan: fields.length }, null, null, null, null, null]
    : [{ text: reports.LATOE, colSpan: fields.length }, null, null, null, null, null, null, null];

  const subHeaders = openTab
    ? [
        'Location',
        'Classrooms count',
        'Average Children Enrolled',
        'Average Children Attended',
        'Teachers Count',
        'Office Workers',
      ]
    : ['Workarea', 'Avg enrolled', 'Avg attended', 'Position', 'Name', 'Credentials', 'Rate', 'Schedule'];

  if (!openTab) {
    const mappedReportData = {
      ...reportData,
      locations: reportData.locations.map(location => ({
        ...location,
        workAreas: location.workAreas.reduce((allWorkAreas, workArea) => {
          const defaultFields = {
            workAreaName: workArea.name,
            workAreaAverageEnrolleesCount: workArea.averageEnrolleesCount,
            workAreaAverageAttendeesCount: workArea.averageAttendeesCount,
            rowSpanCount: workArea.employees.length,
          };

          const isEmptyWorkArea = !workArea.employees.length;

          const workAreaData = isEmptyWorkArea
            ? [
                {
                  workAreaName: defaultFields.workAreaName,
                  workAreaAverageEnrolleesCount: defaultFields.workAreaAverageEnrolleesCount,
                  workAreaAverageAttendeesCount: defaultFields.workAreaAverageAttendeesCount,
                },
              ]
            : [
                ...workArea.employees.map(employee => ({
                  ...defaultFields,
                  employeePosition: employee.position,
                  employeePersonFullName: employee.personFullName,
                  employeeCredentials: employee.credentials,
                  employeeRate: employee.rate,
                  employeeSchedule: employee.schedule ? `${employee.schedule.in} - ${employee.schedule.out}` : '',
                  suffixes: [{ field: 'employeeRate', value: employee.isHourlyRate ? '/hour' : '' }],
                })),
              ];

          return [...allWorkAreas, ...workAreaData];
        }, []),
      })),
    };

    return createContent(
      mappedReportData,
      openTab,
      headers,
      fields,
      totalFields,
      subHeaders,
      pageBreak,
      pageOrientation
    );
  }

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateLSW = (reportData, openTab, pageBreak, pageOrientation) => {
  const staticFields = ['name'];

  const dynamicFields = reportData.locations[0].datePeriods.map(
    (datePeriod, index) => `datePeriods[${index}].sumAmount`
  );

  const dynamicTotalFields = reportData.datePeriods.map((datePeriod, index) => `datePeriods[${index}].sumAmount`);

  const nullableDynamicHeaders = [...dynamicFields];

  const dynamicHeaders = [
    { text: reports.LSW, colSpan: staticFields.length + dynamicFields.length },
    ...nullableDynamicHeaders.fill(null),
  ];

  const dynamicSubHeaders = [
    [{ text: 'Location', rowSpan: 2 }, { text: 'Count of Substitutes', colSpan: dynamicFields.length }],
    [
      ...[...staticFields].fill(null),
      ...reportData.datePeriods.map(datePeriod => ({
        text: `${periodsFormat(datePeriod.dateFrom)} - ${periodsFormat(datePeriod.dateTo)}`,
      })),
    ],
  ];

  return createContent(
    reportData,
    openTab,
    dynamicHeaders,
    [...staticFields, ...dynamicFields],
    dynamicTotalFields,
    dynamicSubHeaders,
    pageBreak,
    pageOrientation
  );
};

const generateNTCC = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = openTab
    ? ['name', 'maxChildrenAllowed', 'averageAttendeesCount', 'averageEnrolledCount']
    : ['name', 'departmentName', 'maxChildrenAllowed', 'averageAttendeesCount', 'averageEnrolledCount'];
  const totalFields = ['totalMaxChildrenAllowed', 'totalAverageAttendeesCount', 'totalAverageEnrolledCount'];
  const headers = openTab
    ? [{ text: reports.NTCC, colSpan: 4 }, null, null, null]
    : [{ text: reports.NTCC, colSpan: 5 }, null, null, null, null];
  const subHeaders = openTab
    ? ['Location', 'Max Children Allowed', 'Attendees', 'Enrolled']
    : ['Classroom', 'Group Type', 'Max Children Allowed', 'Attendees', 'Enrolled'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const generateCAE = (reportData, openTab, pageBreak, pageOrientation) => {
  const staticFields = ['name'];

  const dynamicFields = reportData.locations[0].datePeriods.reduce(
    (all, datePeriod, index) => [
      ...all,
      `datePeriods[${index}].averageEnrolleesCount`,
      `datePeriods[${index}].averageAttendeesCount`,
      { text: `datePeriods[${index}].percentOfAttendees`, suffix: '%' },
    ],
    []
  );

  const dynamicTotalFields = reportData.datePeriods.reduce(
    (all, datePeriod, index) => [
      ...all,
      `datePeriods[${index}].averageEnrolleesCount`,
      `datePeriods[${index}].averageAttendeesCount`,
      { text: `datePeriods[${index}].percentOfAttendees`, suffix: '%' },
    ],
    []
  );

  const nullableDynamicHeaders = [...dynamicFields];

  const dynamicHeaders = [
    { text: reports.CAE, colSpan: staticFields.length + dynamicFields.length },
    ...nullableDynamicHeaders.fill(null),
  ];

  const subHeaderTitles = ['Enrollees', 'Attendees', 'Attendees / Enrollees ( % )'];

  const nullableHeaderTitles = [...subHeaderTitles];
  nullableHeaderTitles.pop();

  const dynamicSubHeaders = [
    [
      { text: openTab ? 'Location' : 'Classroom', rowSpan: 2 },
      ...reportData.datePeriods.reduce(
        (all, datePeriod, index) => [
          ...all,
          {
            text: `${periodsFormat(datePeriod.dateFrom)} - ${periodsFormat(datePeriod.dateTo)}`,
            colSpan: subHeaderTitles.length,
          },
          ...[...nullableHeaderTitles].fill(null),
        ],
        []
      ),
    ],
    [
      ...[...staticFields].fill(null),
      ...reportData.datePeriods.reduce((all, datePeriod) => [...all, ...subHeaderTitles], []),
    ],
  ];

  return createContent(
    reportData,
    openTab,
    dynamicHeaders,
    [...staticFields, ...dynamicFields],
    dynamicTotalFields,
    dynamicSubHeaders,
    pageBreak,
    pageOrientation
  );
};

const generateIC = (reportData, openTab, pageBreak, pageOrientation) => {
  const fields = [
    'name',
    { text: 'income', prefixType: prefixTypes.currency },
    { text: 'costsForTeachers', prefixType: prefixTypes.currency },
    { text: 'costsForOffices', prefixType: prefixTypes.currency },
  ];
  const totalFields = [
    { text: 'totalIncome', prefixType: prefixTypes.currency },
    { text: 'totalCostsForTeachers', prefixType: prefixTypes.currency },
    { text: 'totalCostsForOffices', prefixType: prefixTypes.currency },
  ];
  const headers = [{ text: reports.IC, colSpan: 4 }, null, null, null];
  const subHeaders = [openTab ? 'Location' : 'Classroom', 'Income', 'Cost Teacher ($)', 'Cost Office ($)'];

  return createContent(reportData, openTab, headers, fields, totalFields, subHeaders, pageBreak, pageOrientation);
};

const createContent = (
  reportData,
  openTab,
  headers,
  fields,
  totalFields,
  subHeaders,
  pageBreak = null,
  pageOrientation = 'portrait'
) => {
  let isHasNestedHeaders = false;
  let isHasNestedSubHeaders = false;

  const headersContent = headers.map(header => {
    const defaultFields = {
      style: styles.table.header,
      margin: margin.table.header,
    };

    if (isArray(header)) {
      isHasNestedHeaders = true;

      return header.map(h => transform(defaultFields, h));
    }

    return transform(defaultFields, header);
  });

  const subHeadersContent = subHeaders.map(subHeader => {
    const defaultFields = {
      style: styles.table.subHeader,
      margin: margin.table.subHeader,
    };

    if (isArray(subHeader)) {
      isHasNestedSubHeaders = true;

      return subHeader.map(sh => transform(defaultFields, sh));
    }

    return transform(defaultFields, subHeader);
  });

  const tableLocations = reportData.locations.reduce((allLocations, location, index) => {
    let defaultFields = {
      margin: margin.table.row,
    };

    if (openTab) {
      return [...allLocations, fields.map(field => transform(defaultFields, field, location))];
    }

    const arrayFields = location.classrooms || location.workAreas;

    const tableClassrooms = arrayFields.reduce((allClassrooms, classroom, index) => {
      defaultFields = {
        ...defaultFields,
        fillColor: classroom.groupColor,
      };

      return [...allClassrooms, fields.map(field => transform(defaultFields, field, classroom))];
    }, []);

    return [
      ...allLocations,
      [{ colSpan: headers.length, text: location.name, margin: margin.table.subTitle }],
      ...tableClassrooms,
    ];
  }, []);

  if (openTab) {
    const defaultFields = {
      margin: margin.table.row,
      style: styles.table.total,
    };

    tableLocations.push([
      { ...defaultFields, text: 'Total' },
      ...totalFields.map(totalField => transform(defaultFields, totalField, reportData)),
    ]);
  }

  let body = [];

  if (isHasNestedHeaders) {
    body = [...headersContent];
  } else {
    body = [headersContent];
  }

  if (isHasNestedSubHeaders) {
    body = [...body, ...subHeadersContent];
  } else {
    body = [...body, subHeadersContent];
  }

  body = [...body, ...tableLocations];

  const content = [
    {
      style: styles.table,
      pageBreak,
      pageOrientation,
      table: {
        // widths: [...headers].fill('*'),
        widths: [...headers].fill(`${100 / headers.length}%`),
        dontBreakRows: true,
        // headerRows: headers.length,
        // keepWithHeaderRows: true,
        body,
      },
    },
  ];

  return content;
};

const createPdfHeader = filters => {
  let text = '';

  if (filters) {
    const { dateFrom, dateTo } = filters;

    text = `From: ${dateFrom.format('MM/DD/YYYY')} ${' '.repeat(5)} To: ${dateTo.format('MM/DD/YYYY')}`;
  } else {
    text = `Date: ${moment().format('MM/DD/YYYY HH:mm:ss')}`;
  }

  return {
    style: styles.header,
    margin: margin.header,
    text,
  };
};

const createPDF = (content, reportType, filters) => {
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: landscapeReports.includes(reportType) ? 'landscape' : 'portrait',
    pageMargins: margin.document,
    footer: (currentPage, pageCount) => ({ text: `${currentPage.toString()}` }),
    styles,
    defaultStyle: styles.default,
    content: [createPdfHeader(filters), ...content],
    info: {
      title: 'Reports',
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName();

  if (['Internet Explorer', 'Microsoft Edge'].includes(browserName)) {
    return pdfDocGenerator.download('Reports.pdf');
  }

  return pdfDocGenerator.open();
};

const createText = (field, data, delimeter) => {
  if (isString(field)) {
    let value = data ? get(data, field) : field;

    value = checkValue(value);

    return value;
  } else if (isArray(field)) {
    return field.reduce((all, fieldPart, index) => {
      let textPart = `${all}`;

      if (isString(fieldPart)) {
        let value = get(data, fieldPart);

        value = checkValue(value);

        textPart += value;
      } else {
        const { prefix, suffix, text, prefixType, hideNullableValues } = fieldPart;
        let value = get(data, text);

        value = `${checkValue(value)}`;

        let createdText = value;

        createdText = addPrefix(createdText, prefix, prefixType);
        createdText = addSuffix(text, createdText, suffix, data);

        if (hideNullableValues) {
          createdText = value ? createdText : '';
        }

        textPart += createdText;
      }

      if (index !== field.length - 1) {
        textPart += addDelimeter(delimeter);
      }

      return textPart;
    }, '');
  }

  return '';
};

const transform = (defaultFields = {}, field, data) => {
  if (!field) return null;

  const { fields, delimeter, prefix, suffix, text, rowSpan, prefixType, hideNullableValues } = field;

  if (text === 'leadTeacherPersonFullName' && data.rowSpanCount === 3) {
    debugger;
  }

  let transformedField = {
    ...defaultFields,
  };

  if (rowSpan === null) {
    transformedField = {
      ...transformedField,
      rowSpan: data.rowSpanCount,
    };
  }

  if (isString(field)) {
    let value = createText(field, data);

    let createdText = value;

    createdText = addPrefix(createdText, prefix, prefixType);
    createdText = addSuffix(text, createdText, suffix, data);

    if (hideNullableValues) {
      createdText = value ? createdText : '';
    }

    transformedField = {
      ...transformedField,
      text: createdText,
    };
  } else if (fields) {
    transformedField = {
      ...field,
      ...transformedField,
      text: createText(fields, data, delimeter),
    };
  } else {
    let value = createText(text, data);

    let createdText = value;

    createdText = addPrefix(createdText, prefix, prefixType);
    createdText = addSuffix(text, createdText, suffix, data);

    if (hideNullableValues) {
      createdText = value ? createdText : '';
    }

    transformedField = {
      ...field,
      ...transformedField,
      text: createdText,
    };
  }

  return transformedField;
};

const addPrefix = (createdText, prefix, prefixType) => {
  if (!prefixType && !prefix) return createdText;

  return prefixType === prefixTypes.currency ? currency(createdText) : `${prefix}${createdText}`;
};

const addSuffix = (text, createdText, suffix, data) => {
  if (!data || (!suffix && (!data.suffixes || !data.suffixes.length))) return createdText;

  if (data.suffixes) {
    const dataSuffix = data.suffixes.find(suffix => suffix.field === text) || '';

    return `${createdText}${dataSuffix ? dataSuffix.value : dataSuffix}`;
  }

  return `${createdText}${suffix}`;
};

const addDelimeter = delimeter => (delimeter ? delimeter : '');

const checkValue = value => (value === null || value === undefined ? '' : value);

export const generatePDF = (reportType, reportData, openTab, permissions, loading, filters) => {
  if (!reportData || loading) {
    return;
  }

  let content = [];

  switch (reportType) {
    case reports.EAWI:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      content = generateEAWI(reportData, openTab);
      break;

    case reports.CTC:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateCTC(reportData, 0); // without tabs
      break;

    case reports.TCTE:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      content = generateTCTE(reportData, openTab);
      break;

    case reports.TGW:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      // content = generateTGW(reportData, openTab);
      break;

    case reports.CATP:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateCATP(reportData, openTab, undefined, 'landscape');
      break;

    case reports.VRA:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateVRA(reportData, 1); // without tabs
      break;

    case reports.NPC:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateNPC(reportData, 1); // without tabs
      break;

    case reports.CMBMR:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      content = generateCMBMR(reportData, openTab);
      break;

    case reports.LATOE:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateLATOE(reportData, openTab, undefined, 'landscape');
      break;

    case reports.LSW:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateLSW(reportData, 1); // without tabs
      break;

    case reports.NTCC:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateNTCC(reportData, openTab);
      break;

    case reports.CAE:
      if (!checkPermission(permissions, permission.NON_FINANCIAL)) return;
      content = generateCAE(reportData, openTab, undefined, 'landscape');
      break;

    case reports.IC:
      if (!checkPermission(permissions, permission.FINANCIAL)) return;
      content = generateIC(reportData, openTab);
      break;

    default:
      return;
  }

  return createPDF(content, reportType, filters);
};

export const generateSinglePDF = (reportData, permissions) => {
  if (!reportData) {
    return;
  }

  if (Object.values(reportData.spinners).some(spinner => spinner)) {
    return;
  }

  const {
    enrolledAttendedWithIncome,
    teachersInClassroom,
    moniesBilledVsReceived,
    teachersAndEmployees,
    incomeAndCosts,
    listOfSubstitutes,
    attendeesByPeriod,
    violationsCount,
    complaintsCount,
    teachersNeededByCalculating,
    attendeesVsEnrollees,
    countOfTeachersPerClassroom,
    tabs,
    filters,
  } = reportData;

  let content = [];

  if (checkPermission(permissions, permission.NON_FINANCIAL) && checkPermission(permissions, permission.FINANCIAL)) {
    content = [
      ...generateEAWI(enrolledAttendedWithIncome, tabs.EAWI),
      ...generateCTC(countOfTeachersPerClassroom, tabs.CTC, 'before'),
      ...generateTCTE(teachersInClassroom, tabs.TCTE, 'before'),
      ...generateCMBMR(moniesBilledVsReceived, tabs.CMBMR, 'before'),
      ...generateCATP(attendeesByPeriod, tabs.CATP, 'before', 'landscape'),
      ...generateVRA(violationsCount, tabs.VRA, 'before'),
      ...generateNPC(complaintsCount, tabs.NPC, 'before'),
      ...generateIC(incomeAndCosts, tabs.IC, 'before'),
      ...generateNTCC(teachersNeededByCalculating, tabs.NTCC, 'before'),
      ...generateCAE(attendeesVsEnrollees, tabs.CAE, 'before', 'landscape'),
      ...generateLSW(listOfSubstitutes, tabs.LSW, 'before'),
      // ...generateTGW(null, tabs.TGW, 'before'),
      ...generateLATOE(teachersAndEmployees, tabs.LATOE, 'before', 'landscape'),
    ];
  } else if (checkPermission(permissions, permission.FINANCIAL)) {
    content = [
      ...generateEAWI(enrolledAttendedWithIncome, tabs.EAWI),
      ...generateTCTE(teachersInClassroom, tabs.TCTE, 'before'),
      ...generateCMBMR(moniesBilledVsReceived, tabs.CMBMR, 'before'),
      ...generateIC(incomeAndCosts, tabs.IC, 'before'),
    ];
  } else if (checkPermission(permissions, permission.NON_FINANCIAL)) {
    content = [
      ...generateCTC(countOfTeachersPerClassroom, tabs.CTC),
      ...generateCATP(attendeesByPeriod, tabs.CATP, 'before', 'landscape'),
      ...generateVRA(violationsCount, tabs.VRA, 'before'),
      ...generateNPC(complaintsCount, tabs.NPC, 'before'),
      ...generateNTCC(teachersNeededByCalculating, tabs.NTCC, 'before'),
      ...generateCAE(attendeesVsEnrollees, tabs.CAE, 'before', 'landscape'),
      ...generateLSW(listOfSubstitutes, tabs.LSW, 'before'),
      // ...generateTGW(null, tabs.TGW, 'before'),
      ...generateLATOE(teachersAndEmployees, tabs.LATOE, 'before', 'landscape'),
    ];
  }

  if (!content.length) return;

  return createPDF(content, null, filters);
};
