import React, { PureComponent } from 'react';
import { AutoSizer, List } from 'react-virtualized';

class VirtualizedList extends PureComponent {
  render() {
    const {
      height = 390,
      rowCount,
      rowRenderer,
      onRowsRendered,
      innerRef,
      scrolledWidth,
      className,
      isScrolling,
      scrollTop,
      onScroll,
      ...rest
    } = this.props;
    return (
      <AutoSizer disableHeight>
        {({ width: autoWidth }) => (
          <List
            ref={innerRef}
            className={className}
            height={height}
            rowCount={rowCount}
            rowRenderer={rowRenderer}
            width={scrolledWidth > autoWidth ? scrolledWidth : autoWidth}
            onRowsRendered={onRowsRendered}
            isScrolling={isScrolling}
            scrollTop={scrollTop}
            onScroll={onScroll}
            {...rest}
          />
        )}
      </AutoSizer>
    );
  }
}

export default React.forwardRef((props, ref) => <VirtualizedList {...props} innerRef={ref} />);
