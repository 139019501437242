import HTTP from './http';

export const getLocations = async () => {
  const {
    data: { data: locations },
  } = await HTTP.get(`/locations`);
  return locations;
};

export const getWorkAreas = async locationId => {
  const { data: workareas } = await HTTP.get(`locations/${locationId}/workareas`);
  return workareas;
};
