import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  CircularProgress,
  Tooltip,
  Icon,
  IconButton,
} from '@material-ui/core/';
import { dateHelpers, prettifiers } from '../../helpers';
import get from 'lodash/get';

class ViolationsTable extends PureComponent {
  static propTypes = {
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
  };

  render() {
    const { data, loading, pagination, onChangeRowsPerPage, onChangePage, onEdit, onDelete } = this.props;

    return (
      <div>
        <Paper className="table-wrapper">
          <div className="table-name">Violations List</div>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}

          <Table>
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="13%" />
              <col width="7%" />
              <col width="15%" />
              <col width="8%" />
              <col width="10%" />
              <col width="9%" />
              <col width="8%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Date of Violation</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Classroom</TableCell>
                <TableCell>Violation Code</TableCell>
                <TableCell>Violation Description</TableCell>
                <TableCell className="center">Resolved</TableCell>
                <TableCell>Resolved Date</TableCell>
                <TableCell>Penalty</TableCell>
                <TableCell className="center">Critical</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(violation => (
                <TableRow className="row" key={violation.id}>
                  <TableCell>{dateHelpers.periodsFormat(violation.violationDate)}</TableCell>
                  <TableCell>{get(violation, 'location.name', '')}</TableCell>
                  <TableCell>{get(violation, 'workArea.name', '')}</TableCell>
                  <TableCell>
                    <Tooltip title={violation.code} placement="bottom-start">
                      <span className="span">{violation.code}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={violation.description} placement="bottom-start">
                      <span className="span">{violation.description}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="center">
                    {violation.resolved ? (
                      <Tooltip disableFocusListener title="Resolved">
                        <Icon className="check-icon">check_box</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip disableFocusListener title="Unresolved">
                        <Icon className="uncheck-icon">check_box_outline_blank</Icon>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{dateHelpers.periodsFormat(violation.resolvedDate)}</TableCell>
                  <TableCell>{prettifiers.currency(violation.penalty)}</TableCell>
                  <TableCell className="center">
                    {violation.critical ? (
                      <Tooltip disableFocusListener title="Critical">
                        <Icon className="check-icon">check_box</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip disableFocusListener title="Not critical">
                        <Icon className="uncheck-icon">check_box_outline_blank</Icon>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton className="action-btn edit-btn" onClick={() => onEdit(violation)}>
                      <Icon className="action-edit">create</Icon>
                    </IconButton>
                    <IconButton className="action-btn" onClick={() => onDelete(violation)}>
                      <Icon className="action-delete">delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50]}
                  count={pagination.rowCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  SelectProps={{
                    style: {
                      width: 85,
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default ViolationsTable;
