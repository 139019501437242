import HTTP from './http';

export const getUsers = async ({ currentPage, pageSize, orderBy, orderDirection }) => {
  const { data } = await HTTP.get(`/users`, { params: { pageSize, pageNumber: currentPage, orderBy, orderDirection } });
  const { results: users, ...pagination } = data;
  return { users, pagination };
};

export const createUser = async user => {
  await HTTP.put('/users', user);
};

export const updateUser = async user => {
  await HTTP.post('/users', user);
};

export const deleteUser = async id => {
  await HTTP.delete('/users', { data: { id } });
};

export const checkUserEmail = async ({ email, id: userId }) => {
  const { data: isUnique } = await HTTP.get(`/users/emails/check`, { params: { email, userId } });
  return isUnique;
};

export const getUserLocations = async userId => {
  const { data: locations } = await HTTP.get(`/users/${userId}/locations`);
  return locations;
};

export const getUserViolations = async ({ currentPage, pageSize, userId }) => {
  const { data } = await HTTP.get(`/users/${userId}/violations`, {
    params: { pageSize, pageNumber: currentPage },
  });
  const { results: violations, ...pagination } = data;
  return { violations, pagination };
};

export const getUserComplaints = async ({ currentPage, pageSize, userId }) => {
  const { data } = await HTTP.get(`/users/${userId}/complaints`, {
    params: { pageSize, pageNumber: currentPage },
  });
  const { results: complaints, ...pagination } = data;
  return { complaints, pagination };
};
