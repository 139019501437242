import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../pages/login-page';
import { logoutSaga } from '../components/app-bar';
import { usersSaga } from '../pages/users-page';
import { historyLogSaga } from '../pages/history-log-page';
import { dashboardSaga } from '../pages/dashboard-page';
import { violationsSaga } from '../pages/violations-page';
import { complaintsSaga } from '../pages/complaints-page';
import { schedulerSaga } from '../pages/scheduler-page';
import { locationsSaga } from '../shared/locations';

export default function* root() {
  yield all([
    fork(authSaga),
    fork(logoutSaga),
    fork(usersSaga),
    fork(historyLogSaga),
    fork(locationsSaga),
    fork(dashboardSaga),
    fork(violationsSaga),
    fork(complaintsSaga),
    fork(schedulerSaga),
  ]);
}
