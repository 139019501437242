import * as types from './snack-bar.types';

export const showSnackbar = payload => ({
  type: types.SHOW_SNACKBAR,
  payload,
});

export const hideSnackbar = () => ({
  type: types.HIDE_SNACKBAR,
});
