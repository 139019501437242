import { takeEvery, put, call } from 'redux-saga/effects';

import { authApi } from '../../api/';
import { snackActions } from '../../components/snack-bar';
import { loginFail, loginSuccess } from './login-page.actions';
import { createHistoryRequest } from '../history-log-page';
import { LOGIN_REQUEST } from './login-page.types';

import { parseToken, history, prettifiers } from '../../helpers';
import AUTH_TOKEN, { logTypes } from '../../constants';

function* loginSaga({ payload: credentials }) {
  try {
    const token = yield call(authApi.login, credentials);
    localStorage.setItem(AUTH_TOKEN, token);
    const data = yield call(parseToken, token);
    yield put(loginSuccess(data));
    history.replace('/');

    yield put(createHistoryRequest(prettifiers.historyLogMessage(logTypes.LOGIN)));
  } catch (error) {
    yield put(
      snackActions.showSnackbar({
        message: 'Email-password combination does not exists. Please, recheck entered data.',
        variant: 'error',
      })
    );
    yield put(loginFail());
  }
}

export default function* authSaga() {
  yield takeEvery(LOGIN_REQUEST, loginSaga);
}
