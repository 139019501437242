import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import { Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core/';
import moment from 'moment';
import get from 'lodash/get';

import { createValidator } from '../../helpers';

import ButtonWithSpinner from '../../components/button-with-spinner';
import FieldWithIcon from '../../components/field-with-icon';
import DatePicker from '../../components/date-picker';
import Select from '../../components/select';

@withFormik({
  validate: createValidator(validator =>
    validator.object().shape({
      violationDate: validator
        .date()
        .typeError('Date of Violation must be a valid "date" type')
        .required('Date of Violation is required field'),
      resolvedDate: validator
        .date()
        .nullable()
        .default(null)
        .typeError('Resolved Date must be a valid "date" type'),
      locationId: validator.string().required('Day Care Center is required field'),
      workAreaId: validator.string(),
      code: validator.string().max(256, 'Violation Code must be less than or equal to 256'),
      description: validator
        .string()
        .max(256, 'Description must be less than or equal to 256')
        .required('Description is required field'),
      resolved: validator.boolean(),
      penalty: validator
        .number()
        .min(0, 'Penalty must be greater than or equal to 0')
        .typeError('Penalty must be a number'),
      critical: validator.boolean(),
    })
  ),
  mapPropsToValues: ({ violation }) => ({
    violationDate: get(violation, 'violationDate') || moment(),
    locationId: get(violation, 'location.id', ''),
    workAreaId: get(violation, 'workArea.id', ''),
    code: get(violation, 'code', ''),
    description: get(violation, 'description', ''),
    resolved: get(violation, 'resolved', false),
    resolvedDate: get(violation, 'resolvedDate', null),
    penalty: get(violation, 'penalty', ''),
    critical: get(violation, 'critical', false),
    type: violation ? 'update' : 'create',
  }),
})
class ComplaintModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
    onLoading: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { violation, onLoading } = this.props;
    const locationId = get(violation, 'location.id', '');
    if (locationId) {
      onLoading(locationId);
    }
  }

  onViolationDateHandler = violationDate => {
    const { setFieldValue } = this.props;
    setFieldValue('violationDate', violationDate);
  };

  onResolvedDateHandler = resolvedDate => {
    const { setFieldValue } = this.props;
    setFieldValue('resolvedDate', resolvedDate);
  };

  onSelectLocationHandler = locationId => {
    this.props.onLoading(locationId);
    const { setFieldValue } = this.props;
    setFieldValue('locationId', locationId);
    setFieldValue('workAreaId', '', false);
  };

  onSelectWorkAreaHandler = workAreaId => {
    const { setFieldValue } = this.props;
    setFieldValue('workAreaId', workAreaId);
  };

  onCheckboxHandler = e => {
    const { values, setFieldValue } = this.props;
    const { name } = e.target;
    if (name === 'resolved' && values['resolved']) {
      setFieldValue('resolvedDate', null);
    }
    setFieldValue(name, !values[name]);
  };

  onChangeHandler = e => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  onBlurHandler = e => {
    const { setFieldTouched } = this.props;
    setFieldTouched(e.target.name, true, false);
  };

  onSubmitHandler = () => {
    const { onSubmit, values, isValid, violation } = this.props;
    if (!isValid) return;

    const { violationDate, resolvedDate, type, ...rest } = values;

    if (type === 'update') {
      onSubmit({
        id: violation.id,
        violationDate: moment(violationDate).format('YYYY-MM-DD'),
        resolvedDate: resolvedDate ? moment(resolvedDate).format('YYYY-MM-DD') : null,
        ...rest,
      });
      return;
    }
    onSubmit({
      violationDate: moment(violationDate).format('YYYY-MM-DD'),
      resolvedDate: resolvedDate ? moment(resolvedDate).format('YYYY-MM-DD') : null,
      ...rest,
    });
  };

  onCancelHandler = () => {
    this.props.onClose();
  };

  render() {
    const { errors, touched, values, loading, isValid, locations, title, getWorkAreaProgress, workAreas } = this.props;

    return (
      <div className="modal add-violation">
        <div className="modal-header">{title}</div>
        <Form>
          <div className="datepicker-holder">
            <FieldWithIcon
              icon="event"
              onBlur={this.onBlurHandler}
              error={touched.violationDate && !!errors.violationDate}
              withInputProps
            >
              <DatePicker
                name="violationDate"
                label="Date of Violation*"
                maxDate={values.resolvedDate || undefined}
                value={values.violationDate}
                onChange={this.onViolationDateHandler}
                error={touched.violationDate && !!errors.violationDate}
                fullWidth
                disableFuture
              />
            </FieldWithIcon>
          </div>

          <FieldWithIcon icon="business" onBlur={this.onBlurHandler} error={touched.locationId && !!errors.locationId}>
            <Select
              className="select-checkbox-input custom"
              label="Day Care Center*"
              values={values.locationId}
              options={locations}
              onChange={this.onSelectLocationHandler}
              error={touched.locationId && !!errors.locationId}
              valueKey="id"
              labelKey="name"
              name="locationId"
              helperText={touched.locationId ? errors.locationId : ''}
            />
          </FieldWithIcon>

          <FieldWithIcon
            icon="meeting_room"
            onBlur={this.onBlurHandler}
            error={touched.workAreaId && !!errors.workAreaId}
          >
            <Select
              className="select-checkbox-input custom"
              label="Classroom"
              values={values.workAreaId}
              options={workAreas}
              onChange={this.onSelectWorkAreaHandler}
              error={touched.workAreaId && !!errors.workAreaId}
              valueKey="id"
              labelKey="workAreaName"
              name="workAreaId"
              helperText={touched.workAreaId ? errors.workAreaId : ''}
              emptyText="No classrooms assigned to current location"
              loading={getWorkAreaProgress}
            />
          </FieldWithIcon>

          <FieldWithIcon icon="notes" error={touched.code && !!errors.code} onBlur={this.onBlurHandler}>
            <TextField
              type="text"
              name="code"
              value={values.code}
              label="Code"
              error={touched.code && !!errors.code}
              helperText={touched.code ? errors.code : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
            />
          </FieldWithIcon>

          <FieldWithIcon icon="notes" error={touched.description && !!errors.description} onBlur={this.onBlurHandler}>
            <TextField
              type="text"
              name="description"
              value={values.description}
              label="Description*"
              error={touched.description && !!errors.description}
              helperText={touched.description ? errors.description : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
              multiline={true}
              rows={3}
              rowsMax={5}
            />
          </FieldWithIcon>

          <FormControlLabel
            className="input-checkbox"
            control={
              <Checkbox
                className="simple-checkbox"
                name="resolved"
                checked={values.resolved}
                onChange={this.onCheckboxHandler}
                color="primary"
              />
            }
            label="Resolved"
          />

          <FieldWithIcon
            icon="event"
            onBlur={this.onBlurHandler}
            error={touched.resolvedDate && !!errors.resolvedDate}
            withInputProps
          >
            <DatePicker
              name="resolvedDate"
              label="Resolved Date"
              value={values.resolvedDate}
              onChange={this.onResolvedDateHandler}
              error={touched.resolvedDate && !!errors.resolvedDate}
              helperText={touched.resolvedDate ? errors.resolvedDate : ''}
              minDate={values.violationDate}
              fullWidth
              disableFuture
              disabled={!values.resolved}
            />
          </FieldWithIcon>

          <FieldWithIcon icon="attach_money" error={touched.penalty && !!errors.penalty} onBlur={this.onBlurHandler}>
            <TextField
              type="text"
              name="penalty"
              value={values.penalty}
              label="Penalty*"
              error={touched.penalty && !!errors.penalty}
              helperText={touched.penalty ? errors.penalty : ''}
              onChange={this.onChangeHandler}
              fullWidth
              autoComplete="off"
            />
          </FieldWithIcon>

          <FormControlLabel
            className="input-checkbox"
            control={
              <Checkbox
                className="simple-checkbox"
                name="critical"
                checked={values.critical}
                onChange={this.onCheckboxHandler}
                color="primary"
              />
            }
            label="Critical"
          />

          <div className="footer-buttons">
            <Button onClick={this.onCancelHandler} className="light-btn dark">
              CANCEL
            </Button>
            <div className="button-holder">
              <ButtonWithSpinner
                className="light-btn"
                type="button"
                onClick={this.onSubmitHandler}
                loading={loading}
                disabled={!isValid}
              >
                SAVE
              </ButtonWithSpinner>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default ComplaintModal;
