import * as userApi from './users';
import * as authApi from './auth';
import * as historyLogApi from './history-log';
import * as locationsApi from './locations';
import * as substitutionApi from './substitution';
import * as reportsApi from './reports';
import * as violationsApi from './violations';
import * as complaintsApi from './complaints';
import * as schedulerApi from './scheduler';
import HTTP from './http';

export default HTTP;
export {
  userApi,
  authApi,
  historyLogApi,
  locationsApi,
  substitutionApi,
  reportsApi,
  violationsApi,
  complaintsApi,
  schedulerApi,
};
