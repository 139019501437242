import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menu-item';
import { menuIds } from '../../../constants';

const menu = [
  {
    id: menuIds.USERS,
    label: 'User list',
    icon: 'people',
    to: '/admin',
  },
  {
    id: menuIds.HISTORY,
    label: 'User History Log',
    icon: 'insert_drive_file',
    to: '/admin/history-log',
  },
  {
    id: menuIds.SCHEDULER,
    label: 'API Scheduler',
    icon: 'today',
    to: '/admin/api-scheduler',
  },
  {
    id: menuIds.DASHBOARD,
    label: 'Dashboard',
    icon: 'dashboard',
    to: '/dashboard',
  },
];

class AdminMenu extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { onClose } = this.props;

    return menu.map(item => (
      <MenuItem key={item.id} onClick={onClose} icon={item.icon} label={item.label} linkTo={item.to} />
    ));
  }
}

export default AdminMenu;
