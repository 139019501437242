import { takeEvery, put, call, select } from 'redux-saga/effects';

import { historyLogApi } from '../../api';

import { GET_HISTORY_LOG_REQUEST, CREATE_HISTORY_LOG_REQUEST } from './history-log.types';

import * as historyLogActions from './history-log.actions';
import { snackActions } from '../../components/snack-bar';

function* getHistoryLogSaga({ payload: { currentPage, pageSize, orderBy, orderDirection } }) {
  try {
    const { history, pagination } = yield call(historyLogApi.getHistoryLog, {
      currentPage,
      pageSize,
      orderBy,
      orderDirection,
    });
    yield put(historyLogActions.getHistoryLogSuccess({ history, pagination, order: { orderBy, orderDirection } }));
  } catch (error) {
    yield put(historyLogActions.getHistoryLogFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createHistoryLogSaga({ payload: action }) {
  try {
    const { userId } = yield select(({ user }) => user);
    yield historyLogApi.createHistoryLog(userId, action);
    yield put(historyLogActions.createHistorySuccess());
  } catch (error) {
    yield put(historyLogActions.createHistoryFail());
  }
}

export default function* historyLogSaga() {
  yield takeEvery(GET_HISTORY_LOG_REQUEST, getHistoryLogSaga);
  yield takeEvery(CREATE_HISTORY_LOG_REQUEST, createHistoryLogSaga);
}
