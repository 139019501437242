import HTTP from './http';
import { dateHelpers } from '../helpers';

export const getScheduler = async () => {
  const { data: scheduler } = await HTTP.get(`/schedulers/days-off`);
  return scheduler;
};

export const setScheduler = async ({ addedDaysOff, deletedDaysOff }) => {
  await HTTP.post(`/schedulers/days-off`, {
    addedDaysOff: addedDaysOff.map(day => dateHelpers.schedulerDateFormat(day)),
    deletedDaysOff: deletedDaysOff.map(day => dateHelpers.schedulerDateFormat(day)),
  });
};
