import { takeEvery, put, call } from 'redux-saga/effects';

import { schedulerApi } from '../../api';

import * as schedulerActions from './scheduler-page.actions';
import * as schedulerTypes from './scheduler-page.types';
import { createHistoryRequest } from '../history-log-page';
import { snackActions } from '../../components/snack-bar';
import { prettifiers } from '../../helpers';
import { logTypes } from '../../constants';

function* getSchedulerSaga() {
  try {
    const scheduler = yield call(schedulerApi.getScheduler);
    yield put(schedulerActions.getSchedulerSuccess(scheduler));
  } catch (error) {
    yield put(schedulerActions.getSchedulerFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* setSchedulerSaga({ payload }) {
  try {
    yield call(schedulerApi.setScheduler, payload);
    const scheduler = yield call(schedulerApi.getScheduler);
    yield put(schedulerActions.setSchedulerSuccess(scheduler));
    yield put(
      snackActions.showSnackbar({
        message: 'Dates successfully changed',
        variant: 'success',
      })
    );
  } catch (error) {
    yield put(schedulerActions.setSchedulerFail());
    yield put(
      snackActions.showSnackbar({
        message: error.response.snackMessage,
        variant: 'error',
      })
    );
  }
}

function* createHistorySaga({ payload }) {
  try {
    yield put(createHistoryRequest(prettifiers.historyLogMessage(logTypes.API_SCHEDULER, payload)));
  } catch (error) {
    console.log(error);
  }
}

export default function* historyLogSaga() {
  yield takeEvery(schedulerTypes.GET_SCHEDULER_REQUEST, getSchedulerSaga);
  yield takeEvery(schedulerTypes.SET_SCHEDULER_REQUEST, setSchedulerSaga);
  yield takeEvery(schedulerTypes.SET_SCHEDULER_REQUEST, createHistorySaga);
}
