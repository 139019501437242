import moment from 'moment';

export const reportDateFormat = date => {
  return date.format('YYYY-MM-DD');
};

export const schedulerDateFormat = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const periodsFormat = date => {
  if (date) return moment(date).format('MM/DD/YYYY');
  return '';
};

export const dateStampFormat = date => {
  return moment
    .utc(date)
    .local()
    .format('MM/DD/YYYY HH:mm:ss');
};

export const isSame = (date, comparedDate) => {
  return moment(date).isSame(comparedDate, 'day');
};
