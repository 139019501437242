import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon } from '@material-ui/core';

import ComplaintsTable from './complaints-table';
import ComplaintDeleteModal from './complaint-delete-modal';
import { ComplaintUpsertModal } from '../../shared/modals';
import * as complaintsActions from './complaints.actions';
import { locationActions } from '../../shared/locations';

import ModalContainer, { modalActions } from '../../components/modal';
import { modalTypes } from '../../constants';

@connect(
  ({ locations: { userLocations }, ui: { modal }, complaints: { list: complaintsList, pagination, spinners } }) => ({
    userLocations,
    complaintsList,
    pagination,
    spinners,
    modal,
  }),
  {
    openModal: modalActions.openModal,
    hideModal: modalActions.hideModal,
    updateComplaintRequest: complaintsActions.updateComplaintRequest,
    deleteComplaintRequest: complaintsActions.deleteComplaintRequest,
    getComplaintsRequest: complaintsActions.getComplaintsRequest,
    getUserLocationsRequest: locationActions.getUserLocationsRequest,
  }
)
class ComplaintsPage extends Component {
  static propTypes = {};

  componentDidMount() {
    const {
      pagination: { currentPage, pageSize },
      getComplaintsRequest,
      getUserLocationsRequest,
    } = this.props;
    getComplaintsRequest({ currentPage, pageSize });
    getUserLocationsRequest();
  }

  updateComplaint = complaint => {
    this.props.updateComplaintRequest(complaint);
  };

  deleteComplaint = complaint => {
    this.props.deleteComplaintRequest(complaint);
  };

  changeCurrentPageHandler = (_, page) => {
    const {
      pagination: { pageSize },
    } = this.props;
    this.props.getComplaintsRequest({ currentPage: page + 1, pageSize });
  };

  changePageSizeHandler = e => {
    const pageSize = e.target.value;
    const {
      pagination: { currentPage },
    } = this.props;
    this.props.getComplaintsRequest({ currentPage, pageSize });
  };

  openCreateComplaintModal = () => {
    this.props.openModal({
      modalType: modalTypes.CREATE_COMPLAINT,
    });
  };

  openUpdateComplaintModal = complaint => {
    this.props.openModal({
      modalType: modalTypes.UPDATE_COMPLAINT,
      modalProps: complaint,
    });
  };

  openDeleteComplaintModal = complaint => {
    this.props.openModal({
      modalType: modalTypes.DELETE_COMPLAINT,
      modalProps: complaint,
    });
  };

  closeModalHandler = modalType => {
    this.props.hideModal({ modalType });
  };

  render() {
    const {
      spinners,
      pagination,
      complaintsList,
      userLocations,
      modal: { modalType, modalProps },
    } = this.props;

    return (
      <div className="container admin-page">
        <div className="button-holder">
          <div className="pusher" />
          <h1 className="title">BeanStalk Academy Dashboard</h1>
          <Button variant="contained" className="simple-btn" color="primary" onClick={this.openCreateComplaintModal}>
            <Icon>add</Icon>
            ADD COMPLAINT
          </Button>
        </div>

        <ComplaintsTable
          onEdit={this.openUpdateComplaintModal}
          onDelete={this.openDeleteComplaintModal}
          onChangePage={this.changeCurrentPageHandler}
          onChangeRowsPerPage={this.changePageSizeHandler}
          loading={spinners.getComplaints}
          pagination={pagination}
          data={complaintsList}
        />

        <ModalContainer
          onClose={this.closeModalHandler}
          disableAutoFocus
          open={modalType === modalTypes.UPDATE_COMPLAINT}
        >
          <ComplaintUpsertModal
            title="Edit Complaint"
            complaint={modalProps}
            onSubmit={this.updateComplaint}
            onClose={() => this.closeModalHandler(modalTypes.UPDATE_COMPLAINT)}
            loading={spinners.updateComplaint}
            locations={userLocations}
          />
        </ModalContainer>

        <ComplaintDeleteModal
          open={modalType === modalTypes.DELETE_COMPLAINT}
          onClose={() => this.closeModalHandler(modalTypes.DELETE_COMPLAINT)}
          onSubmit={this.deleteComplaint}
          complaint={modalProps}
          loading={spinners.deleteComplaint}
        />
      </div>
    );
  }
}

export default ComplaintsPage;
