// CREATE COMPLAINT
export const CREATE_COMPLAINT_REQUEST = 'CREATE_COMPLAINT_REQUEST';
export const CREATE_COMPLAINT_SUCCESS = 'CREATE_COMPLAINT_SUCCESS';
export const CREATE_COMPLAINT_FAIL = 'CREATE_COMPLAINT_FAIL';

// UPDATE COMPLAINT
export const UPDATE_COMPLAINT_REQUEST = 'UPDATE_COMPLAINT_REQUEST';
export const UPDATE_COMPLAINT_SUCCESS = 'UPDATE_COMPLAINT_SUCCESS';
export const UPDATE_COMPLAINT_FAIL = 'UPDATE_COMPLAINT_FAIL';

// DELETE COMPLAINT
export const DELETE_COMPLAINT_REQUEST = 'DELETE_COMPLAINT_REQUEST';
export const DELETE_COMPLAINT_SUCCESS = 'DELETE_COMPLAINT_SUCCESS';
export const DELETE_COMPLAINT_FAIL = 'DELETE_COMPLAINT_FAIL';

// GET COMPLAINTS
export const GET_COMPLAINTS_REQUEST = 'GET_COMPLAINTS_REQUEST';
export const GET_COMPLAINTS_SUCCESS = 'GET_COMPLAINTS_SUCCESS';
export const GET_COMPLAINTS_FAIL = 'GET_COMPLAINTS_FAIL';
