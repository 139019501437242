import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Collapse, Paper } from '@material-ui/core';
import invert from 'lodash/invert';
import classNames from 'classnames';

import { reports } from '../constants/reports';
import { generatePDF } from '../helpers/pdf';

const invertedReports = invert(reports);

class ReportWrapper extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    tabChangeHandler: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    report: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    tabLabels: PropTypes.array,
    filters: PropTypes.object,
  };

  state = {
    isOpen: this.props.isOpen,
    openTab: 0,
  };

  collapseHandler = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  tabsHandler = (_, openTab) => {
    const { title, tabChangeHandler } = this.props;

    this.setState({ openTab });

    tabChangeHandler(invertedReports[title], openTab);
  };

  handleDownloadPdfClick = e => {
    const { title, report, permissions, loading, filters } = this.props;
    const { openTab } = this.state;

    e.stopPropagation();

    generatePDF(title, report, openTab, permissions, loading, filters);
  };

  render() {
    const { openTab, isOpen } = this.state;
    const { title, children, withoutTabs, tabChangeHandler, tabLabels, report, loading, ...rest } = this.props;

    const child = Children.only(children);
    const reportElement = cloneElement(child, { byLocation: openTab, report, loading });

    return (
      <div {...rest}>
        <div className={`table-toggle ${isOpen ? 'opened' : ''}`} onClick={this.collapseHandler}>
          <div className="table-title">{title}</div>
          <div className="table-toolbar">
            <div className="icon-download" onClick={this.handleDownloadPdfClick} />
            <div className="icon-toggle" />
          </div>
        </div>
        <Collapse className={`table-holder ${isOpen ? 'opened' : ''}`} in={isOpen} timeout={0}>
          {!withoutTabs && (
            <Tabs className="simple-tabs" value={openTab} onChange={this.tabsHandler} variant="fullWidth">
              <Tab className="simple-tab" label={(tabLabels && tabLabels[0]) || 'CLASSROOMS'} />
              <Tab className="simple-tab" label={(tabLabels && tabLabels[1]) || 'LOCATIONS'} />
            </Tabs>
          )}
          <Paper className={classNames('report', { 'without-tabs': withoutTabs && title !== reports.LSW })}>
            {reportElement}
          </Paper>
        </Collapse>
      </div>
    );
  }
}

export default ReportWrapper;
