import React, { PureComponent } from 'react';
import { Chip } from '@material-ui/core/';
import PropTypes from 'prop-types';

class Chips extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    valueKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    options: [],
    values: [],
  };

  render() {
    const { options, values, onDelete, valueKey } = this.props;
    const chips = options.filter(option => {
      return values.some(value => value === option.id);
    });

    return chips.map(item => {
      return (
        <Chip
          className="simple-tag"
          label={item.name}
          key={item.id}
          onDelete={() => onDelete(item[valueKey])}
          color="primary"
        />
      );
    });
  }
}

export default Chips;
