import HTTP from './http';

export const getHistoryLog = async ({ currentPage, pageSize, orderBy, orderDirection }) => {
  const { data } = await HTTP.get(`/users/histories`, {
    params: { pageSize, pageNumber: currentPage, orderBy, orderDirection },
  });
  const { results: history, ...pagination } = data;
  return { history, pagination };
};

export const createHistoryLog = async (userId, action) => {
  await HTTP.put(`/users/histories`, {
    userId,
    action,
    actionDateTime: new Date().toISOString(),
  });
};
