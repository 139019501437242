import { logTypes } from '../constants';
import { periodsFormat } from './date';
import formatter from 'numeral';

export const historyLogMessage = (type, data) => {
  if (type === logTypes.LOGIN) {
    return 'Login';
  }
  if (type === logTypes.LOGOUT) {
    return 'Logout';
  }
  if (type === logTypes.LOCATION_FILTER) {
    const filteredLocations = data.locations.filter(location => {
      return data.locationIds.some(id => id === location.id);
    });

    const locations = filteredLocations.map(value => value.name).join(' \\ ');
    return `${locations} filter applied`;
  }
  if (type === logTypes.DATE_FILTER) {
    return `Date filter for period ${data.dateFrom.format('MM/DD/YYYY')} - ${data.dateTo.format('MM/DD/YYYY')} applied`;
  }
  if (type === logTypes.API_SCHEDULER) {
    const addedDays = data.addedDaysOff.map(day => periodsFormat(day)).join(', ');
    const deletedDays = data.deletedDaysOff.map(day => periodsFormat(day)).join(', ');
    return `API turning on/off.${addedDays && ' Added days: ' + addedDays + '.'}${deletedDays &&
      ' Deleted days: ' + deletedDays + '.'}`;
  }
};

export const currency = amount => {
  return formatter(amount).format('$0,000,000.00');
};
