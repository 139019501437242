import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import AdminMenu from './admin-menu';
import UserMenu from './user-menu';

import { checkRole } from '../../../helpers';
import { role, redirectMap } from '../../../constants';

import { logout } from '../app-bar.actions';
import { modalActions } from '../../../components/modal';

@connect(
  ({ user: { roles, permissions }, report }) => ({ roles, report, permissions }),
  { logout, openModal: modalActions.openModal }
)
class AppBarMenu extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    report: PropTypes.object.isRequired,
    permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  renderMenu = () => {
    const { roles, onClose, openModal, report, permissions } = this.props;
    const menuProps = {
      onClose,
      openModal,
      report,
      permissions,
    };

    if (checkRole(roles, role.ADMIN)) {
      const { path } = this.props;
      if (path.startsWith(redirectMap.ADMIN)) {
        return <AdminMenu onClose={onClose} />;
      }
      if (path.startsWith(redirectMap.USER)) {
        return <UserMenu isAdmin onClose={onClose} {...menuProps} />;
      }
      return <AdminMenu onClose={onClose} />;
    }
    return <UserMenu {...menuProps} />;
  };

  logoutHandler = () => {
    this.props.onClose();
    this.props.logout();
  };

  render() {
    const { anchorEl, onClose } = this.props;

    return (
      <Menu
        className="main-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        disableAutoFocusItem
        PaperProps={{
          style: {
            width: 220,
            padding: 0,
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
            borderRadius: 2,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorReference="anchorEl"
      >
        {this.renderMenu()}
        <MenuItem color="inherit" className="nav-link menu-item" onClick={this.logoutHandler}>
          <div className="nav-link-ref">
            <ListItemIcon>
              <Icon>perm_identity</Icon>
            </ListItemIcon>
            <ListItemText className="menu-item-label">Log Out</ListItemText>
          </div>
        </MenuItem>
      </Menu>
    );
  }
}

export default AppBarMenu;
