import { combineReducers } from 'redux';

import * as types from './locations.types';

const allLocations = (state = [], action) => {
  switch (action.type) {
    case types.GET_LOCATIONS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};

const userLocations = (state = [], action) => {
  switch (action.type) {
    case types.GET_USER_LOCATIONS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};

const workAreas = (state = [], action) => {
  switch (action.type) {
    case types.GET_WORKAREAS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};

const spinnersState = {
  getLocations: false,
  getUserLocations: false,
  getWorkAreas: false,
};
const spinners = (state = spinnersState, action) => {
  switch (action.type) {
    case types.GET_LOCATIONS_REQUEST:
      return { ...state, getLocations: true };
    case types.GET_LOCATIONS_FAIL:
    case types.GET_LOCATIONS_SUCCESS:
      return { ...state, getLocations: false };
    case types.GET_USER_LOCATIONS_REQUEST:
      return { ...state, getUserLocations: true };
    case types.GET_USER_LOCATIONS_FAIL:
    case types.GET_USER_LOCATIONS_SUCCESS:
      return { ...state, getUserLocations: false };
    case types.GET_WORKAREAS_REQUEST:
      return { ...state, getWorkAreas: true };
    case types.GET_WORKAREAS_FAIL:
    case types.GET_WORKAREAS_SUCCESS:
      return { ...state, getWorkAreas: false };
    default:
      return state;
  }
};

const locationsReducer = combineReducers({
  allLocations,
  userLocations,
  workAreas,
  spinners,
});

export default locationsReducer;
