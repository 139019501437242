import React, { PureComponent, Children, cloneElement } from 'react';
import { Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class FieldWithIcon extends PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    withInputProps: PropTypes.bool,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  state = {
    isFocused: false,
  };

  onFocusHandler = () => {
    this.setState({ isFocused: true });
  };

  onBlurHandler = e => {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    onBlur && onBlur(e);
  };

  render() {
    const { isFocused } = this.state;
    const { error, icon, children, withInputProps, readOnly } = this.props;
    const child = Children.only(children);
    const props = {
      className: `${child.props.className ? child.props.className : ''} ${classNames('simple-input', {
        error,
        isFocused,
      })}`,
      onFocus: this.onFocusHandler,
      onBlur: this.onBlurHandler,
      InputProps: readOnly ? { readOnly: !isFocused } : {},
    };
    const clonedElement = cloneElement(
      child,
      withInputProps ? { ...props, InputProps: { onBlur: this.onBlurHandler } } : props
    );

    return (
      <div className="input-holder">
        <Icon className={classNames('icon', { isFocused })}>{icon}</Icon>
        {clonedElement}
      </div>
    );
  }
}

export default FieldWithIcon;
