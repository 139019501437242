import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

@withStyles(theme => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
}))
class ProgressButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  render() {
    const { loading, disabled, classes, onClick, children, ...rest } = this.props;
    return (
      <div className={classNames(classes.wrapper)}>
        <Button {...rest} disabled={loading || disabled} onClick={onClick}>
          {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  }
}

export default ProgressButton;
