import * as modalTypes from './modals';
import * as logTypes from './history-log';
import * as menuIds from './menu';
import { role, redirectMap } from './roles';
import { permission } from './permissions';
import { AUTH_TOKEN } from './token';
import { reports, highlightColors } from './reports';

export default AUTH_TOKEN;
export { role, redirectMap, modalTypes, logTypes, menuIds, permission, reports, highlightColors };
