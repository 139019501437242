import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker as DatePickerModal, InlineDatePicker } from 'material-ui-pickers';
import DateUtils from '@date-io/moment';

class DatePicker extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  render() {
    const { label, format = 'MM/DD/YYYY', value, onChange, inline, ...rest } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateUtils}>
        {inline ? (
          <InlineDatePicker label={label} value={value} onChange={onChange} format={format} {...rest} />
        ) : (
          <DatePickerModal label={label} value={value} onChange={onChange} format={format} {...rest} />
        )}
      </MuiPickersUtilsProvider>
    );
  }
}

export default DatePicker;
