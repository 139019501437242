import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal as MuiModal } from '@material-ui/core';
import { connect } from 'react-redux';

import { SubstituteModal, ComplaintUpsertModal, ViolationUpsertModal } from '../../shared/modals';
import { modalTypes } from '../../constants';
import * as modalActions from './modal.actions';
import { addSubstitutionRequest } from '../../pages/dashboard-page/dashboard-page.actions';
import { createComplaintRequest } from '../../pages/complaints-page/complaints.actions';
import { createViolationRequest } from '../../pages/violations-page/violations.actions';
import { getWorkAreasRequest } from '../../shared/locations/locations.actions';

export default class ModalContainer extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { children, onClose, ...rest } = this.props;
    return (
      <MuiModal onClose={onClose} {...rest}>
        {children}
      </MuiModal>
    );
  }
}

@connect(
  ({
    locations: { userLocations, workAreas, spinners: locationSpinners },
    ui: { modal },
    report,
    complaints,
    violations,
  }) => ({
    userLocations,
    workAreas,
    report,
    modal,
    complaints,
    violations,
    locationSpinners,
  }),
  {
    addSubstitutionRequest,
    createComplaintRequest,
    createViolationRequest,
    getWorkAreasRequest,
    hideModal: modalActions.hideModal,
  }
)
class Modal extends PureComponent {
  addSubstitution = substitution => {
    this.props.addSubstitutionRequest(substitution);
  };

  createComplaint = complaint => {
    this.props.createComplaintRequest(complaint);
  };

  createViolation = violation => {
    this.props.createViolationRequest(violation);
  };

  getWorkAreas = locationId => {
    this.props.getWorkAreasRequest(locationId);
  };

  closeModalHandler = modalType => {
    this.props.hideModal({ modalType });
  };

  render() {
    const {
      modal: { modalType },
      report,
      complaints,
      violations,
      userLocations,
      workAreas,
      locationSpinners,
    } = this.props;
    return (
      <>
        <MuiModal disableAutoFocus open={modalType === modalTypes.CREATE_SUBSTITUTE} onClose={this.closeModalHandler}>
          <SubstituteModal
            title="Add Substitute"
            onSubmit={this.addSubstitution}
            onClose={() => this.closeModalHandler(modalTypes.CREATE_SUBSTITUTE)}
            loading={report.spinners.addSubstitution}
            locations={userLocations}
          />
        </MuiModal>
        <MuiModal disableAutoFocus open={modalType === modalTypes.CREATE_COMPLAINT} onClose={this.closeModalHandler}>
          <ComplaintUpsertModal
            title="Add Complaint"
            onSubmit={this.createComplaint}
            onClose={() => this.closeModalHandler(modalTypes.CREATE_COMPLAINT)}
            locations={userLocations}
            loading={complaints.spinners.createComplaint}
          />
        </MuiModal>
        <MuiModal disableAutoFocus open={modalType === modalTypes.CREATE_VIOLATION} onClose={this.closeModalHandler}>
          <ViolationUpsertModal
            title="Add Violation"
            onSubmit={this.createViolation}
            onClose={() => this.closeModalHandler(modalTypes.CREATE_VIOLATION)}
            locations={userLocations}
            workAreas={workAreas}
            loading={violations.spinners.createViolation}
            onLoading={this.getWorkAreas}
            getWorkAreaProgress={locationSpinners.getWorkAreas}
          />
        </MuiModal>
      </>
    );
  }
}

export { Modal };
