import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon } from '@material-ui/core';
import debounce from 'lodash/debounce';

import { modalTypes } from '../../constants';

import { locationActions } from '../../shared/locations';
import * as userActions from './users-page.actions';
import ModalContainer, { modalActions } from '../../components/modal';
import { UserUpsertModal, UserDeleteModal } from './modals';
import UsersTable from './users-table';

@connect(
  ({
    users: { list: usersList, isEmailFree, pagination, spinners, order },
    ui: { modal },
    locations: { allLocations },
  }) => ({
    modal,
    loading: spinners,
    users: usersList,
    isEmailFree,
    locations: allLocations,
    pagination,
    order,
  }),
  {
    getLocationsRequest: locationActions.getLocationsRequest,
    openModal: modalActions.openModal,
    hideModal: modalActions.hideModal,
    createUserRequest: userActions.createUserRequest,
    updateUserRequest: userActions.updateUserRequest,
    deleteUserRequest: userActions.deleteUserRequest,
    getUsersRequest: userActions.getUsersRequest,
    checkEmailRequest: userActions.checkEmailRequest,
    checkEmailSuccess: userActions.checkEmailSuccess,
  }
)
class Users extends PureComponent {
  static propTypes = {
    modal: PropTypes.shape({
      modalType: PropTypes.string,
      modalProps: PropTypes.object,
    }).isRequired,
    loading: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    isEmailFree: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    createUserRequest: PropTypes.func.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
    deleteUserRequest: PropTypes.func.isRequired,
    getUsersRequest: PropTypes.func.isRequired,
    checkEmailRequest: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      currentPage: PropTypes.number.isRequired,
      pageCount: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      rowCount: PropTypes.number.isRequired,
    }),
    order: PropTypes.shape({
      orderBy: PropTypes.string.isRequired,
      orderDirection: PropTypes.string.isRequired,
    }),
  };

  componentDidMount() {
    const {
      pagination: { currentPage, pageSize },
      getUsersRequest,
      getLocationsRequest,
    } = this.props;
    getUsersRequest({ currentPage, pageSize, orderBy: 'fullName', orderDirection: 'asc' });
    getLocationsRequest();
  }

  createUser = user => {
    this.props.createUserRequest(user);
  };

  updateUser = user => {
    this.props.updateUserRequest(user);
  };

  deleteUser = user => {
    this.props.deleteUserRequest(user);
  };

  openCreateUserModal = () => {
    this.props.openModal({
      modalType: modalTypes.CREATE_USER_MODAL,
    });
  };

  openEditUserModal = user => {
    this.props.openModal({
      modalType: modalTypes.UPDATE_USER_MODAL,
      modalProps: user,
    });
  };

  openDeleteModal = user => {
    this.props.openModal({
      modalType: modalTypes.DELETE_USER_MODAL,
      modalProps: user,
    });
  };

  closeModalHandler = modalType => {
    this.props.hideModal({ modalType });
  };

  changeCurrentPageHandler = (_, page) => {
    const {
      pagination: { pageSize },
      order: { orderBy, orderDirection },
    } = this.props;
    this.props.getUsersRequest({ currentPage: page + 1, pageSize, orderBy, orderDirection });
  };

  changePageSizeHandler = e => {
    const pageSize = e.target.value;
    const {
      pagination: { currentPage },
      order: { orderBy, orderDirection },
    } = this.props;
    this.props.getUsersRequest({ currentPage, pageSize, orderBy, orderDirection });
  };

  checkEmailHandler = debounce(data => {
    this.props.checkEmailRequest(data);
  }, 600);

  resetEmailError = () => {
    this.props.checkEmailSuccess(true);
  };

  onSortHandler = ({ orderBy, orderDirection }) => {
    const {
      pagination: { currentPage, pageSize },
      getUsersRequest,
    } = this.props;
    getUsersRequest({ currentPage, pageSize, orderBy, orderDirection });
  };

  render() {
    const {
      modal: { modalType, modalProps },
      loading,
      users,
      isEmailFree,
      locations,
      pagination,
      order,
    } = this.props;

    return (
      <div className="container admin-page">
        <div className="button-holder">
          <div className="pusher" />
          <h1 className="title">BeanStalk Academy Dashboard. Admin Panel</h1>
          <Button variant="contained" className="simple-btn" color="primary" onClick={this.openCreateUserModal}>
            <Icon>add</Icon>
            ADD NEW USER
          </Button>
        </div>
        <UsersTable
          onEdit={this.openEditUserModal}
          onDelete={this.openDeleteModal}
          onChangePage={this.changeCurrentPageHandler}
          onChangeRowsPerPage={this.changePageSizeHandler}
          loading={loading.getUsers}
          pagination={pagination}
          users={users}
          order={order}
          onSort={this.onSortHandler}
        />

        {modalType === modalTypes.CREATE_USER_MODAL && (
          <ModalContainer onClose={this.closeModalHandler} disableAutoFocus open>
            <UserUpsertModal
              title="Create New User"
              onSubmit={this.createUser}
              onClose={() => this.closeModalHandler(modalTypes.CREATE_USER_MODAL)}
              onEmailCheck={this.checkEmailHandler}
              isEmailFree={isEmailFree}
              emailCheckProgress={loading.checkEmail}
              loading={loading.createUser}
              locations={locations}
              resetEmailError={this.resetEmailError}
            />
          </ModalContainer>
        )}

        {modalType === modalTypes.UPDATE_USER_MODAL && (
          <ModalContainer onClose={this.closeModalHandler} disableAutoFocus open>
            <UserUpsertModal
              title="Edit User"
              user={modalProps}
              onSubmit={this.updateUser}
              onClose={() => this.closeModalHandler(modalTypes.UPDATE_USER_MODAL)}
              onEmailCheck={this.checkEmailHandler}
              isEmailFree={isEmailFree}
              emailCheckProgress={loading.checkEmail}
              loading={loading.updateUser}
              locations={locations}
              resetEmailError={this.resetEmailError}
            />
          </ModalContainer>
        )}

        {modalType === modalTypes.DELETE_USER_MODAL && (
          <UserDeleteModal
            onClose={() => this.closeModalHandler(modalTypes.DELETE_USER_MODAL)}
            onSubmit={this.deleteUser}
            user={modalProps}
            loading={loading.deleteUser}
            open
          />
        )}
      </div>
    );
  }
}

export default Users;
