import JWTDecode from 'jwt-decode';
import AUTH_TOKEN from '../constants';

export default tokenStr => {
  const token = tokenStr || localStorage.getItem(AUTH_TOKEN);
  if (!token) return;
  const decodedToken = JWTDecode(token);
  const { roles, permissions, userId } = decodedToken;
  return { roles, permissions, userId };
};
