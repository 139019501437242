import React, { Component } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import ButtonWithSpinner from '../../components/button-with-spinner';
import FieldWithIcon from '../../components/field-with-icon';
import { createValidator } from '../../helpers';

@withFormik({
  validate: createValidator(validator =>
    validator.object().shape({
      email: validator
        .string()
        .email('Must be a valid email')
        .required('Email is required'),
      password: validator
        .string()
        .min(6, 'Password must be at least 6 characters')
        .max(256, 'Password must be at most 256 characters')
        .required('Password is required'),
    })
  ),
  mapPropsToValues: () => ({ email: '', password: '' }),
})
class LoginForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { isValid, values, onSubmit } = this.props;
    if (!isValid) return;
    onSubmit(values);
  };

  onChangeHandler = e => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  onBlurHandler = e => {
    const { setFieldTouched } = this.props;
    setFieldTouched(e.target.name, true, false);
  };

  render() {
    const { errors, touched, values, loading, isValid } = this.props;
    return (
      <div className="login-form">
        <div className="inner">
          <form onSubmit={this.onSubmitHandler}>
            <span className="title">BeanStalk Academy Dashboard</span>
            <FieldWithIcon icon="email" onBlur={this.onBlurHandler} error={touched.email && !!errors.email}>
              <TextField
                type="email"
                name="email"
                value={values.email}
                label="Email"
                error={touched.email && !!errors.email}
                helperText={touched.email ? errors.email : ''}
                onChange={this.onChangeHandler}
                autoComplete="off"
                fullWidth
              />
            </FieldWithIcon>
            <FieldWithIcon icon="lock" error={touched.password && !!errors.password} onBlur={this.onBlurHandler}>
              <TextField
                type="password"
                name="password"
                value={values.password}
                label="Password"
                error={touched.password && !!errors.password}
                helperText={touched.password ? errors.password : ''}
                onChange={this.onChangeHandler}
                autoComplete="off"
                fullWidth
              />
            </FieldWithIcon>
            <div className="button-holder">
              <ButtonWithSpinner
                className="simple-btn"
                loading={loading}
                disabled={!isValid}
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </ButtonWithSpinner>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default LoginForm;
