import { combineReducers } from 'redux';

import * as types from './users-page.types';

const list = (state = [], action) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return [...action.payload.users];
    default:
      return state;
  }
};

const orderState = {
  orderBy: 'fullName',
  orderDirection: 'asc',
};
const order = (state = orderState, action) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return { ...action.payload.order };
    default:
      return state;
  }
};

const paginationState = {
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  rowCount: 0,
};
const pagination = (state = paginationState, action) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return { ...state, ...action.payload.pagination };
    default:
      return state;
  }
};

const spinnersState = {
  checkEmail: false,
  getUsers: false,
  createUser: false,
  updateUser: false,
  deleteUser: false,
};
const spinners = (state = spinnersState, action) => {
  switch (action.type) {
    case types.GET_USERS_REQUEST:
      return { ...state, getUsers: true };
    case types.GET_USERS_FAIL:
    case types.GET_USERS_SUCCESS:
      return { ...state, getUsers: false };
    case types.CREATE_USER_REQUEST:
      return { ...state, createUser: true };
    case types.UPDATE_USER_REQUEST:
      return { ...state, updateUser: true };
    case types.DELETE_USER_REQUEST:
      return { ...state, deleteUser: true };
    case types.CREATE_USER_SUCCESS:
    case types.CREATE_USER_FAIL:
      return { ...state, createUser: false };
    case types.UPDATE_USER_SUCCESS:
    case types.UPDATE_USER_FAIL:
      return { ...state, updateUser: false };
    case types.DELETE_USER_SUCCESS:
    case types.DELETE_USER_FAIL:
      return { ...state, deleteUser: false };
    case types.CHECK_EMAIL_REQUEST:
      return { ...state, checkEmail: true };
    case types.CHECK_EMAIL_SUCCESS:
    case types.CHECK_EMAIL_FAIL:
      return { ...state, checkEmail: false };
    default:
      return state;
  }
};

const isEmailFree = (state = true, action) => {
  switch (action.type) {
    case types.CHECK_EMAIL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const usersReducer = combineReducers({
  list,
  order,
  isEmailFree,
  pagination,
  spinners,
});

export default usersReducer;
