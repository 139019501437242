import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Header from '../components/app-bar';

import AUTH_TOKEN from '../constants';

const PrivateRoute = allowedRoles => Component =>
  connect(({ user: { roles } }) => ({
    roles,
  }))(
    class extends React.PureComponent {
      static propTypes = {
        roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      };

      isLoggedIn = () => {
        return localStorage.getItem(AUTH_TOKEN);
      };

      isAllowedByRole = () => {
        const { roles } = this.props;

        if (Array.isArray(roles)) {
          return allowedRoles.some(role => roles.includes(role));
        }

        return allowedRoles.some(role => role === roles);
      };

      render() {
        if (this.isLoggedIn()) {
          if (this.isAllowedByRole()) {
            return (
              <Fragment>
                <Header hasMenu />
                <Component {...this.props} />
              </Fragment>
            );
          }
          return <Redirect to="/not-found" />;
        }
        return <Redirect to="/login" />;
      }
    }
  );

export default PrivateRoute;
