import HTTP from './http';
import qs from 'qs';

import { dateHelpers } from '../helpers';

const reportRequest = url => async ({ locationIds, dateFrom, dateTo }) => {
  const { data } = await HTTP.get(url, {
    params: {
      locationIds,
      dateFrom: dateFrom && dateHelpers.reportDateFormat(dateFrom),
      dateTo: dateTo && dateHelpers.reportDateFormat(dateTo),
    },
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });
  return data;
};

export const getEnrolledAttendedWithIncome = reportRequest(`/reports/avg-enrolled-attended-with-income`);
export const getTeachersInClassroom = reportRequest(`/reports/teachers-in-classroom`);
export const getTeachersAndEmployees = reportRequest(`/reports/all-teachers-and-employees`);
export const getTeachersNeededByCalculating = reportRequest(`/reports/teachers-calculating`);
export const getMoniesBilledVsReceived = reportRequest(`/reports/monies-billed-vs-received`);
export const getListOfSubstitutes = reportRequest(`/reports/substitutes-by-week`);
export const getIncomeAndCosts = reportRequest(`/reports/income-and-costs`);
export const getAttendeesByPeriod = reportRequest(`/reports/compare-attended-by-time-period`);
export const getViolationsCount = reportRequest(`/reports/violations-received-with-amounts`);
export const getComplaintsCount = reportRequest(`/reports/parents-complaints`);
export const getAttendeesVsEnrollees = reportRequest(`/reports/compare-attendees-vs-enrollees`);
export const getCountOfTeachersPerClassroom = reportRequest(`/reports/count-of-teachers-per-classroom`);
