import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemText,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Select,
  FormHelperText,
  CircularProgress,
  Icon,
} from '@material-ui/core/';
import classNames from 'classnames';
import find from 'lodash/find';

class MultiSelect extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]).isRequired,
    valueKey: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    allSelected: PropTypes.bool,
    multiple: PropTypes.bool,
    error: PropTypes.bool,
  };

  onChangeHandler = e => {
    const { options, onChange, allSelected } = this.props;
    const { value } = e.target;
    if (value[value.length - 1] === 'selectAll') {
      if (!allSelected) {
        onChange(options.map(option => option.id));
      } else {
        onChange([]);
      }
      return;
    }

    onChange(value);
  };

  get selectMenuProps() {
    return {
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disableAutoFocusItem: true,
        className: 'select-checkbox-options',
      },
    };
  }

  renderValue = selection => {
    const { multiple, labelKey, options } = this.props;
    if (!multiple) {
      const renderedValue = find(options, { id: selection }) || {};
      return renderedValue[labelKey];
    }
    const filteredOptions = options.filter(option => {
      return selection.some(value => value === option.id);
    });

    return filteredOptions.map(value => value[labelKey]).join(', ');
  };

  render() {
    const {
      options,
      values,
      valueKey,
      labelKey,
      label,
      allSelected,
      multiple,
      onChange,
      error,
      helperText,
      emptyText,
      loading,
      ...rest
    } = this.props;

    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel className="select-placeholder" htmlFor={label} error={error}>
          {label}
        </InputLabel>
        <Select
          className="select-checkbox-input custom"
          multiple={multiple}
          value={values}
          onChange={this.onChangeHandler}
          input={<Input id={label} />}
          renderValue={values => this.renderValue(values)}
          IconComponent={props =>
            loading ? (
              <CircularProgress {...props} size={16} color="primary" />
            ) : (
              <Icon {...props}>arrow_drop_down</Icon>
            )
          }
          {...this.selectMenuProps}
          {...rest}
        >
          {multiple && options && options.length && (
            <MenuItem className="input-checkbox select-option" value="selectAll" key="selectAll">
              <Checkbox checked={allSelected} color="primary" className="simple-checkbox" />
              <ListItemText className="checkbox-label" primary="Select All" />
            </MenuItem>
          )}

          {options && options.length ? (
            options.map(option => (
              <SelectItem key={option[valueKey]} value={option[valueKey]} label={option[labelKey]} />
            ))
          ) : (
            <MenuItem className="input-checkbox select-option" value="" key="noLocations" disabled>
              <ListItemText className="checkbox-label" primary={emptyText || 'No locations assigned to current user'} />
            </MenuItem>
          )}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

class SelectItem extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps['data-value'] === this.props['data-value'] && nextProps['selected'] === this.props['selected']) {
      return false;
    }
    return true;
  }

  render() {
    const { key, value, label, selected, ...rest } = this.props;

    return (
      <MenuItem
        key={key}
        className={classNames('input-checkbox select-option', {
          selected,
        })}
        {...rest}
      >
        <Checkbox checked={selected} color="primary" className="simple-checkbox" />
        <ListItemText className="checkbox-label" primary={label} />
      </MenuItem>
    );
  }
}

export default MultiSelect;
