import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';

import VirtualizedList from '../../../components/virtualized-list';
import { dateHelpers } from '../../../helpers';

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    byLocation: PropTypes.number,
  };

  state = {
    title: '',
    titleIndex: -1,
    locationsCount: 0,
    classroomScrollBarSize: 0,
    locationScrollBarSize: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.cacheByClassrooms.clearAll();
      this.ByLocationsList.current && this.ByLocationsList.current.forceUpdateGrid();
      this.ByClassroomsList.current && this.ByClassroomsList.current.forceUpdateGrid();
      this.setState({
        title: get(this.props.report, 'locations[0].name', ''),
        locationsCount: get(this.props.report, 'locations', []).length,
      });
    }
  }

  ByClassroomsList = React.createRef();
  cacheByClassrooms = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  ByLocationsList = React.createRef();
  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByClassroom = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByClassrooms} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="group-row">{report.locations[index].name}</div>
          {report.locations[index].classrooms.map((classroom, i) => (
            <div className="row" key={`${report.locations[index]}-${classroom.name}-${i}`}>
              <div className="cell label-cell w-24">{classroom.name}</div>
              <div className="cell date-cell w-76">
                {classroom.datePeriods.map((period, i) => (
                  <div className="cell" key={i}>
                    <div className="mini-cell">{period.averageEnrolleesCount}</div>
                    <div className="mini-cell">{period.averageAttendeesCount}</div>
                    <div className="mini-cell">{`${period.percentOfAttendees}%`}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CellMeasurer>
    );
  };

  rowByLocation = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell label-cell w-24">{report.locations[index].name}</div>
            <div className="cell date-cell w-76">
              {report.locations[index].datePeriods.map((period, i) => (
                <div className="cell" key={i}>
                  <div className="mini-cell">{period.averageEnrolleesCount}</div>
                  <div className="mini-cell">{period.averageAttendeesCount}</div>
                  <div className="mini-cell">{`${period.percentOfAttendees}%`}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  rowsRenderHandler = ({ startIndex }) => {
    const { report, byLocation } = this.props;
    const { titleIndex } = this.state;

    if (startIndex !== titleIndex && !byLocation) {
      this.setState({ title: report.locations[startIndex].name, titleIndex: startIndex });
    }
  };

  detectScrollBar = ({ vertical, size }) => {
    const { byLocation, locationsCount } = this.props;

    if (byLocation) {
      this.setState({ locationScrollBarSize: vertical ? size : 0 });
      return;
    }

    this.setState({
      classroomScrollBarSize: vertical ? size : 0,
      locationScrollBarSize: locationsCount >= 10 ? size : 0,
    });
  };

  render() {
    const { byLocation, report, loading } = this.props;
    const { title, classroomScrollBarSize, locationScrollBarSize } = this.state;

    return get(report, 'locations', []).length ? (
      !byLocation ? (
        <>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div className="group-title">{title}</div>
          <div className="complex-table-header">
            <div className="header-cell label-cell w-24">Classroom</div>
            <div className="date-cell w-76">
              <div className="header-cell complex-cell" style={{ paddingRight: classroomScrollBarSize }}>
                {report.datePeriods.map((period, i) => (
                  <div className="sub-cell" key={i}>
                    {`${dateHelpers.periodsFormat(period.dateFrom)} -`}
                    <br />
                    {dateHelpers.periodsFormat(period.dateTo)}
                  </div>
                ))}
              </div>
              <div className="header-cell complex-cell" style={{ paddingRight: classroomScrollBarSize }}>
                {report.datePeriods.map((_, i) => (
                  <div className="sub-row" key={i}>
                    <div className="sub-cell">Enrollees</div>
                    <div className="sub-cell">Attendees</div>
                    <div className="sub-cell">Attendees/Enrollees(%)</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <VirtualizedList
            ref={this.ByClassroomsList}
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByClassroom}
            rowCount={report.locations.length}
            deferredMeasurementCache={this.cacheByClassrooms}
            rowHeight={this.cacheByClassrooms.rowHeight}
            onScrollbarPresenceChange={this.detectScrollBar}
          />
        </>
      ) : (
        <>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div className="complex-table-header">
            <div className="header-cell label-cell w-24">Location</div>
            <div className="date-cell w-76">
              <div className="header-cell complex-cell" style={{ paddingRight: locationScrollBarSize }}>
                {report.locations[0].datePeriods.map((period, i) => (
                  <div className="sub-cell" key={i}>
                    {`${dateHelpers.periodsFormat(period.dateFrom)} -`}
                    <br />
                    {dateHelpers.periodsFormat(period.dateTo)}
                  </div>
                ))}
              </div>
              <div className="header-cell complex-cell" style={{ paddingRight: locationScrollBarSize }}>
                {report.datePeriods.map((_, i) => (
                  <div className="sub-row" key={i}>
                    <div className="sub-cell">Enrollees</div>
                    <div className="sub-cell">Attendees</div>
                    <div className="sub-cell">Attendees/Enrollees(%)</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <VirtualizedList
            ref={this.ByLocationsList}
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByLocation}
            rowCount={report.locations.length}
            deferredMeasurementCache={this.cacheByLocations}
            rowHeight={this.cacheByLocations.rowHeight}
            onScrollbarPresenceChange={this.detectScrollBar}
          />
          <div className="complex-table-footer">
            <div className="footer-cell label-cell w-24">Total</div>
            <div className="date-cell w-76">
              {report.datePeriods.map((period, i) => (
                <div className="footer-cell" key={i} style={{ paddingRight: locationScrollBarSize + 5 }}>
                  <div className="mini-cell">{period.averageEnrolleesCount}</div>
                  <div className="mini-cell">{period.averageAttendeesCount}</div>
                  <div className="mini-cell">{`${period.percentOfAttendees}%`}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
