import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AUTH_TOKEN, { role, redirectMap } from '../constants';
import { checkRole } from '../helpers';

@connect(({ user: { roles } }) => ({
  roles,
}))
class RedirectTo extends PureComponent {
  static propTypes = {
    roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  isLoggedIn = () => {
    return localStorage.getItem(AUTH_TOKEN);
  };

  render() {
    const { roles } = this.props;

    if (this.isLoggedIn()) {
      if (checkRole(roles, role.ADMIN)) {
        return <Redirect to={redirectMap.ADMIN} />;
      }
      if (checkRole(roles, role.USER)) {
        return <Redirect to={redirectMap.USER} />;
      }
      return <Redirect to="/not-found" />;
    }

    return <Redirect to="/login" />;
  }
}

export default RedirectTo;
