import HTTP from './http';

export const createViolation = async violation => {
  await HTTP.put('/violations', violation);
};

export const updateViolation = async violation => {
  await HTTP.post('/violations', violation);
};

export const deleteViolation = async id => {
  await HTTP.delete('/violations', { data: { id } });
};
