import * as types from './complaints.types';

// CREATE COMPLAINT
export const createComplaintRequest = payload => ({
  type: types.CREATE_COMPLAINT_REQUEST,
  payload,
});
export const createComplaintSuccess = () => ({
  type: types.CREATE_COMPLAINT_SUCCESS,
});
export const createComplaintFail = () => ({
  type: types.CREATE_COMPLAINT_FAIL,
});

// UPDATE COMPLAINT
export const updateComplaintRequest = payload => ({
  type: types.UPDATE_COMPLAINT_REQUEST,
  payload,
});
export const updateComplaintSuccess = () => ({
  type: types.UPDATE_COMPLAINT_SUCCESS,
});
export const updateComplaintFail = () => ({
  type: types.UPDATE_COMPLAINT_FAIL,
});

// DELETE COMPLAINT
export const deleteComplaintRequest = payload => ({
  type: types.DELETE_COMPLAINT_REQUEST,
  payload,
});
export const deleteComplaintSuccess = () => ({
  type: types.DELETE_COMPLAINT_SUCCESS,
});
export const deleteComplaintFail = () => ({
  type: types.DELETE_COMPLAINT_FAIL,
});

// GET COMPLAINTS
export const getComplaintsRequest = payload => ({
  type: types.GET_COMPLAINTS_REQUEST,
  payload,
});
export const getComplaintsSuccess = payload => ({
  type: types.GET_COMPLAINTS_SUCCESS,
  payload,
});
export const getComplaintsFail = () => ({
  type: types.GET_COMPLAINTS_FAIL,
});
