import React, { Fragment } from 'react';
import Snackbar from './components/snack-bar';
import { Modal } from './components/modal';

import AppRouting from './routing';

const App = () => (
  <Fragment>
    <AppRouting />
    <Snackbar />
    <Modal />
  </Fragment>
);

export default App;
