import * as validator from 'yup';

validator.addMethod(validator.mixed, 'equalTo', function(ref, message) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: message,
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
});

export default schemaCreator => values => {
  try {
    const schema = schemaCreator(validator);
    schema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    const errors = error.inner.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.path]: curr.message,
      };
    }, {});
    return errors;
  }
};
