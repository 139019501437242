import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Snackbar, SnackbarContent, IconButton, Icon } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { green, amber } from '@material-ui/core/colors';

import { hideSnackbar } from './snack-bar.actions';

@withStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    margin: theme.spacing.unit,
    flexWrap: 'nowrap',
  },
}))
class SnackContent extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  iconSet = {
    success: 'check_circle',
    warning: 'warning',
    error: 'error',
  };

  render() {
    const { message, onClose, variant, classes, ...rest } = this.props;

    return (
      <SnackbarContent
        className={classNames(classes[variant], classes.content)}
        message={
          <span className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)}>{this.iconSet[variant]}</Icon>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>,
        ]}
        {...rest}
      />
    );
  }
}

@connect(
  ({
    ui: {
      snack: { open, message, variant },
    },
  }) => ({ open, message, variant }),
  { hideSnackbar }
)
class Snack extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    hideSnackbar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  render() {
    const { open, message, variant, hideSnackbar } = this.props;
    return (
      <span>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={open}
          message={message}
          autoHideDuration={8000}
          onClose={hideSnackbar}
        >
          <SnackContent message={message} variant={variant} onClose={hideSnackbar} />
        </Snackbar>
      </span>
    );
  }
}

export default Snack;
