import { combineReducers } from 'redux';

import * as types from './dashboard-page.types';

const tabsState = {
  EAWI: 0,
  TCTE: 0,
  TGW: 0,
  CATP: 0,
  VRA: 1,
  NPC: 1,
  CMBMR: 0,
  LATOE: 0,
  LSW: 1,
  NTCC: 0,
  CAE: 0,
  IC: 0,
  CTC: 0,
};

// Filters
const filters = (state = {}, action) => {
  switch (action.type) {
    case types.CHANGE_FILTERS:
      const { dateFrom, dateTo } = action.payload;

      return { ...state, dateFrom, dateTo };

    default:
      return state;
  }
};

// Tabs
const tabs = (state = tabsState, action) => {
  switch (action.type) {
    case types.CHANGE_REPORT_TAB:
      const { type, value } = action.payload;
      return { ...state, [type]: value };

    default:
      return state;
  }
};

// Substitution
const addSubstitution = (state = false, action) => {
  switch (action.type) {
    case types.ADD_SUBSTITUTION_REQUEST:
      return true;
    case types.ADD_SUBSTITUTION_FAIL:
    case types.ADD_SUBSTITUTION_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Average Enrolled, Attended with Income
const enrolledAttendedWithIncome = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ENROLLED_ATTENDED_WITH_INCOME_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getEnrolledAttendedWithIncome = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_ENROLLED_ATTENDED_WITH_INCOME_FAIL:
    case types.GET_ENROLLED_ATTENDED_WITH_INCOME_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Attendees by Time Period
const attendeesByPeriod = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ATTENDEES_BY_PERIOD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getAttendeesByPeriod = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_ATTENDEES_BY_PERIOD_FAIL:
    case types.GET_ATTENDEES_BY_PERIOD_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Teachers in the Classroom with the Total Expense
const teachersInClassroom = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TEACHERS_IN_CLASSROOM_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getTeachersInClassroom = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_TEACHERS_IN_CLASSROOM_FAIL:
    case types.GET_TEACHERS_IN_CLASSROOM_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Number of teachers needed by calculating, if classes can be combined without reaching the max per class
const teachersNeededByCalculating = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TEACHERS_NEEDED_BY_CALCULATING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getTeachersNeededByCalculating = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_TEACHERS_NEEDED_BY_CALCULATING_FAIL:
    case types.GET_TEACHERS_NEEDED_BY_CALCULATING_SUCCESS:
      return false;
    default:
      return state;
  }
};

// List of All Teachers and Office Employees
const teachersAndEmployees = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TEACHERS_AND_EMPLOYEES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getTeachersAndEmployees = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_TEACHERS_AND_EMPLOYEES_FAIL:
    case types.GET_TEACHERS_AND_EMPLOYEES_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Compare Monies Billed vs Monies Received
const moniesBilledVsReceived = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MONIES_BILLED_VS_RECEIVED_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getMoniesBilledVsReceived = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_MONIES_BILLED_VS_RECEIVED_FAIL:
    case types.GET_MONIES_BILLED_VS_RECEIVED_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Income and costs
const incomeAndCosts = (state = {}, action) => {
  switch (action.type) {
    case types.GET_INCOME_AND_COSTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getIncomeAndCosts = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_INCOME_AND_COSTS_FAIL:
    case types.GET_INCOME_AND_COSTS_SUCCESS:
      return false;
    default:
      return state;
  }
};

// List of Substitutes by Week
const listOfSubstitutes = (state = {}, action) => {
  switch (action.type) {
    case types.GET_LIST_OF_SUBSTITUTES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getListOfSubstitutes = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_LIST_OF_SUBSTITUTES_FAIL:
    case types.GET_LIST_OF_SUBSTITUTES_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Violations, Received with Amounts
const violationsCount = (state = {}, action) => {
  switch (action.type) {
    case types.GET_VIOLATIONS_COUNT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getViolationsCount = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_VIOLATIONS_COUNT_FAIL:
    case types.GET_VIOLATIONS_COUNT_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Number of Parents' Complaints
const complaintsCount = (state = {}, action) => {
  switch (action.type) {
    case types.GET_COMPLAINTS_COUNT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getComplaintsCount = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_COMPLAINTS_COUNT_FAIL:
    case types.GET_COMPLAINTS_COUNT_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Compare Attendees versus Enrollees
const attendeesVsEnrollees = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ATTENDEES_VS_ENROLLEES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getAttendeesVsEnrollees = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_ATTENDEES_VS_ENROLLEES_FAIL:
    case types.GET_ATTENDEES_VS_ENROLLEES_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Count of Teachers per Classroom
const countOfTeachersPerClassroom = (state = [], action) => {
  switch (action.type) {
    case types.GET_COUNT_OF_TEACHERS_PER_CLASSROOM_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const getCountOfTeachersPerClassroom = (state = false, action) => {
  switch (action.type) {
    case types.APPLY_FILTERS_REQUEST:
      return true;
    case types.GET_COUNT_OF_TEACHERS_PER_CLASSROOM_FAIL:
    case types.GET_COUNT_OF_TEACHERS_PER_CLASSROOM_SUCCESS:
      return false;
    default:
      return state;
  }
};

const spinners = combineReducers({
  addSubstitution,
  getEnrolledAttendedWithIncome,
  getAttendeesByPeriod,
  getTeachersInClassroom,
  getTeachersAndEmployees,
  getMoniesBilledVsReceived,
  getListOfSubstitutes,
  getIncomeAndCosts,
  getViolationsCount,
  getComplaintsCount,
  getAttendeesVsEnrollees,
  getTeachersNeededByCalculating,
  getCountOfTeachersPerClassroom,
});

const dashboardReducer = combineReducers({
  tabs,
  filters,
  spinners,
  enrolledAttendedWithIncome,
  attendeesByPeriod,
  teachersInClassroom,
  teachersAndEmployees,
  moniesBilledVsReceived,
  listOfSubstitutes,
  incomeAndCosts,
  violationsCount,
  complaintsCount,
  attendeesVsEnrollees,
  teachersNeededByCalculating,
  countOfTeachersPerClassroom,
});

export default dashboardReducer;
