import * as TYPES from './login-page.types';

export const loginRequest = payload => ({
  type: TYPES.LOGIN_REQUEST,
  payload,
});

export const loginSuccess = payload => ({
  type: TYPES.LOGIN_SUCCESS,
  payload,
});

export const loginFail = () => ({
  type: TYPES.LOGIN_FAIL,
});
