import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WindowScroller } from 'react-virtualized';

import VirtualizedList from '../../../components/virtualized-list';
import { prettifiers } from '../../../helpers';

const rowWidth = {
  workArea: 120,
  avgEnrolled: 80,
  avgAttended: 80,
  position: 120,
  name: 120,
  credentials: 120,
  rate: 110,
  schedule: 110,
};

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    loading: PropTypes.bool,
    byLocation: PropTypes.number,
  };

  state = {
    title: '',
    titleIndex: -1,
    locationsCount: 0,
    workAreaVerticalScrollBarSize: 0,
    locationScrollBarSize: 0,
    employeesContainer: [],
    maxEmployeesInWorkArea: 0,
    reportWidth: 0,
    hasHorizontalScrollbar: false,
  };

  componentDidUpdate(prevProps) {
    if (this.horizontalScrollArea) {
      const hasHorizontalScrollbar = this.horizontalScrollArea.scrollWidth > this.horizontalScrollArea.clientWidth;

      if (this.state.hasHorizontalScrollbar !== hasHorizontalScrollbar) {
        this.setState({ hasHorizontalScrollbar });
      }
    } else {
      this.setState({ hasHorizontalScrollbar: false });
    }

    if (prevProps.report !== this.props.report) {
      this.cacheByWorkAreas.clearAll();

      this.ByLocationsList.current && this.ByLocationsList.current.forceUpdateGrid();
      this.ByWorkAreasList.current && this.ByWorkAreasList.current.forceUpdateGrid();

      const maxEmployeesInWorkArea = this.findMaxEmployeesInWorkArea();
      const reportWidth = this.calculateReportWidth(maxEmployeesInWorkArea);
      const employeesContainer = this.generateEmployeesContainer(maxEmployeesInWorkArea);

      this.setState({
        title: get(this.props.report, 'locations[0].name', ''),
        locationsCount: get(this.props.report, 'locations', []).length,
        maxEmployeesInWorkArea,
        reportWidth,
        employeesContainer,
      });
    }
  }

  ByWorkAreasList = React.createRef();
  cacheByWorkAreas = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  ByLocationsList = React.createRef();
  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  findMaxEmployeesInWorkArea = () => {
    const { report } = this.props;
    let maxEmployeesInWorkArea = 0;

    report.locations.forEach(location => {
      location.workAreas.forEach(workArea => {
        if (workArea.employees.length > maxEmployeesInWorkArea) {
          maxEmployeesInWorkArea = workArea.employees.length;
        }
      });
    });

    return maxEmployeesInWorkArea;
  };

  calculateReportWidth = maxEmployeesInWorkArea => {
    return (
      rowWidth.workArea +
      rowWidth.avgEnrolled +
      rowWidth.avgAttended +
      (rowWidth.position + rowWidth.name + rowWidth.credentials + rowWidth.rate + rowWidth.schedule) *
        maxEmployeesInWorkArea
    );
  };

  generateEmployeesContainer = maxEmployeesInWorkArea => {
    const employeesContainer = [];
    employeesContainer.length = maxEmployeesInWorkArea;
    employeesContainer.fill(null);

    return employeesContainer;
  };

  rowByWorkArea = ({ index, style, parent, key }) => {
    const { report } = this.props;
    const { employeesContainer, workAreaVerticalScrollBarSize, hasHorizontalScrollbar } = this.state;

    return (
      <CellMeasurer key={key} cache={this.cacheByWorkAreas} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="group-row">{report.locations[index].name}</div>
          {report.locations[index].workAreas.map((workArea, i) => {
            return (
              <div className="row" key={`${report.locations[index]}-${workArea.name}-${i}`}>
                <div style={{ minWidth: rowWidth.workArea }} className="cell">
                  {workArea.name}
                </div>
                <div style={{ minWidth: rowWidth.avgEnrolled }} className="cell">
                  {workArea.averageEnrolleesCount}
                </div>
                <div style={{ minWidth: rowWidth.avgAttended }} className="cell">
                  {workArea.averageAttendeesCount}
                </div>
                {employeesContainer.reduce((all, item, index) => {
                  const employee = workArea.employees[index];

                  return [
                    all,
                    <div style={{ minWidth: rowWidth.position }} className="cell left-border" key={`${i}-position`}>
                      {employee ? employee.position : ''}
                    </div>,
                    <div style={{ minWidth: rowWidth.name }} className="cell" key={`${i}-personFullName`}>
                      {employee ? employee.personFullName : ''}
                    </div>,
                    <div style={{ minWidth: rowWidth.credentials }} className="cell" key={`${i}-credentials`}>
                      {employee ? employee.credentials : ''}
                    </div>,
                    <div style={{ minWidth: rowWidth.rate }} className="cell" key={`${i}-rate`}>
                      {employee && employee.rate
                        ? `${prettifiers.currency(employee.rate)}${employee.isHourlyRate ? '/hour' : ''}`
                        : ''}
                    </div>,
                    <div
                      style={{
                        minWidth: rowWidth.schedule,
                        paddingRight:
                          workAreaVerticalScrollBarSize &&
                          hasHorizontalScrollbar &&
                          index === employeesContainer.length - 1
                            ? workAreaVerticalScrollBarSize + 5
                            : 5,
                      }}
                      className="cell"
                      key={`${i}-schedule`}
                    >
                      {employee && employee.schedule ? `${employee.schedule.in} - ${employee.schedule.out}` : ''}
                    </div>,
                  ];
                }, [])}
              </div>
            );
          })}
        </div>
      </CellMeasurer>
    );
  };

  rowByLocation = ({ index, style, parent, key }) => {
    const { report } = this.props;

    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell">{report.locations[index].name}</div>
            <div className="cell">{report.locations[index].classroomsCount}</div>
            <div className="cell">{report.locations[index].averageEnrolleesCount}</div>
            <div className="cell">{report.locations[index].averageAttendeesCount}</div>
            <div className="cell">{report.locations[index].teachersCount}</div>
            <div className="cell">{report.locations[index].officeWorkersCount}</div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  rowsRenderHandler = ({ startIndex }) => {
    const { report, byLocation } = this.props;
    const { titleIndex } = this.state;

    if (startIndex !== titleIndex && !byLocation) {
      this.setState({ title: report.locations[startIndex].name, titleIndex: startIndex });
    }
  };

  detectScrollBar = ({ vertical, size }) => {
    const { byLocation, locationsCount } = this.props;

    if (byLocation) {
      this.setState({ locationScrollBarSize: vertical ? size : 0 });
      return;
    }

    this.setState({
      workAreaVerticalScrollBarSize: vertical ? size : 0,
      locationScrollBarSize: locationsCount >= 10 ? size : 0,
    });
  };

  render() {
    const { byLocation, report, loading } = this.props;
    const { title, workAreaVerticalScrollBarSize, locationScrollBarSize, employeesContainer, reportWidth } = this.state;

    return get(report, 'locations', []).length ? (
      !byLocation ? (
        <Fragment>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div style={{ minWidth: reportWidth }} className="group-title">
            {title}
          </div>
          <WindowScroller scrollElement={this.horizontalScrollArea}>
            {({ isScrolling, scrollTop, onChildScroll }) => (
              <div
                ref={ref => {
                  this.horizontalScrollArea = ref;
                }}
                className="scroll"
              >
                <div className="table-header">
                  <div style={{ minWidth: rowWidth.workArea }} className="header-cell">
                    Workarea
                  </div>
                  <div style={{ minWidth: rowWidth.avgEnrolled }} className="header-cell">
                    Avg enrolled
                  </div>
                  <div style={{ minWidth: rowWidth.avgAttended }} className="header-cell">
                    Avg attended
                  </div>
                  {employeesContainer.reduce(
                    (all, item, index) => [
                      ...all,
                      <div style={{ minWidth: rowWidth.position }} className="header-cell" key={`${index}-position`}>
                        Position
                      </div>,
                      <div style={{ minWidth: rowWidth.name }} className="header-cell" key={`${index}-personFullName`}>
                        Name
                      </div>,
                      <div
                        style={{ minWidth: rowWidth.credentials }}
                        className="header-cell"
                        key={`${index}-credentials`}
                      >
                        Credentials
                      </div>,
                      <div style={{ minWidth: rowWidth.rate }} className="header-cell" key={`${index}-rate`}>
                        Rate
                      </div>,
                      <div
                        style={{
                          minWidth: rowWidth.schedule,
                          paddingRight:
                            workAreaVerticalScrollBarSize && index === employeesContainer.length - 1
                              ? workAreaVerticalScrollBarSize + 5
                              : 5,
                        }}
                        className="header-cell"
                        key={`${index}-schedule`}
                      >
                        Schedule
                      </div>,
                    ],
                    []
                  )}
                </div>
                <VirtualizedList
                  className="no-scroll"
                  ref={this.ByWorkAreasList}
                  onRowsRendered={this.rowsRenderHandler}
                  rowRenderer={this.rowByWorkArea}
                  rowCount={report.locations.length}
                  deferredMeasurementCache={this.cacheByWorkAreas}
                  rowHeight={this.cacheByWorkAreas.rowHeight}
                  onScrollbarPresenceChange={this.detectScrollBar}
                  scrolledWidth={reportWidth}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  onScroll={onChildScroll}
                />
              </div>
            )}
          </WindowScroller>
        </Fragment>
      ) : (
        <Fragment>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div className="table-header">
            <div className="header-cell">Location</div>
            <div className="header-cell">Classrooms count</div>
            <div className="header-cell">Average Children Enrolled</div>
            <div className="header-cell">Average Children Attended</div>
            <div className="header-cell">Teachers Count</div>
            <div className="header-cell" style={{ paddingRight: locationScrollBarSize + 5 }}>
              Office Workers
            </div>
          </div>
          <VirtualizedList
            ref={this.ByLocationsList}
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByLocation}
            rowCount={report.locations.length}
            deferredMeasurementCache={this.cacheByLocations}
            rowHeight={this.cacheByLocations.rowHeight}
            onScrollbarPresenceChange={this.detectScrollBar}
          />
          <div
            className={classNames('table-footer', { 'top-border': !locationScrollBarSize })}
            style={{ paddingRight: locationScrollBarSize }}
          >
            <div className="footer-cell">Total</div>
            <div className="footer-cell">{report.totalClassroomsCount}</div>
            <div className="footer-cell">{report.totalAverageChildrenEnrolled}</div>
            <div className="footer-cell">{report.totalAverageChildrenAttended}</div>
            <div className="footer-cell">{report.totalTeachersCount}</div>
            <div className="footer-cell">{report.totalOfficeWorkersCount}</div>
          </div>
        </Fragment>
      )
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
