import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Icon,
  IconButton,
  TableFooter,
  Tooltip,
  CircularProgress,
  TableSortLabel,
} from '@material-ui/core/';

class UsersTable extends PureComponent {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    users: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
  };

  sortHandler = order => {
    this.props.onSort(order);
  };

  render() {
    const { onDelete, onEdit, users, loading, pagination, onChangeRowsPerPage, onChangePage, order } = this.props;

    return (
      <Paper className="table-wrapper">
        <div className="table-name">User List</div>
        {loading && (
          <div className="table-spinner">
            <CircularProgress />
          </div>
        )}

        <Table>
          <colgroup>
            <col width="15%" />
            <col width="22%" />
            <col width="40%" />
            <col width="12%" />
            <col width="11%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title="Sort By Full Name" enterDelay={300}>
                  <TableSortLabel
                    active={order.orderBy === 'fullName'}
                    direction={order.orderDirection}
                    onClick={() =>
                      this.sortHandler({
                        orderBy: 'fullName',
                        orderDirection: order.orderDirection === 'asc' ? 'desc' : 'asc',
                      })
                    }
                  >
                    User Name
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Sort By Email" enterDelay={300}>
                  <TableSortLabel
                    active={order.orderBy === 'email'}
                    direction={order.orderDirection}
                    onClick={() =>
                      this.sortHandler({
                        orderBy: 'email',
                        orderDirection: order.orderDirection === 'asc' ? 'desc' : 'asc',
                      })
                    }
                  >
                    Email
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>Locations Assigned To</TableCell>
              <TableCell>Permission</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map(user => (
              <TableRow key={user.id} className="row">
                <TableCell scope="user">{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <span className="span">{user.locations.map(location => location.name).join(' \\ ')}</span>
                </TableCell>
                <TableCell>
                  <Tooltip
                    disableFocusListener
                    title={
                      user.viewNonFinancialReports
                        ? 'Permission to view non financial data (activated)'
                        : 'Permission to view non financial data (deactivated)'
                    }
                  >
                    <Icon
                      className={classNames('icon', 'non-financial-icon', {
                        disabled: !user.viewNonFinancialReports,
                        'non-financial-active': user.viewNonFinancialReports,
                      })}
                    >
                      insert_chart_outlined
                    </Icon>
                  </Tooltip>
                  <Tooltip
                    disableFocusListener
                    title={
                      user.viewFinancialReports
                        ? 'Permission to view financial data (activated)'
                        : 'Permission to view financial data (deactivated)'
                    }
                  >
                    <Icon
                      className={classNames('icon', {
                        disabled: !user.viewFinancialReports,
                        'financial-active': user.viewFinancialReports,
                      })}
                    >
                      list_alt
                    </Icon>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <IconButton className="action-btn edit-btn" onClick={() => onEdit(user)}>
                    <Icon className="action-edit">create</Icon>
                  </IconButton>
                  {user.isDeletable && (
                    <IconButton className="action-btn" onClick={() => onDelete(user)}>
                      <Icon className="action-delete">delete</Icon>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 25, 50]}
                count={pagination.rowCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.currentPage - 1}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                SelectProps={{
                  style: {
                    width: 85,
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default UsersTable;
