import { combineReducers } from 'redux';

import * as types from './scheduler-page.types';

const schedule = (state = [], action) => {
  switch (action.type) {
    case types.GET_SCHEDULER_SUCCESS:
    case types.SET_SCHEDULER_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};

const spinners = (state = { getSchedule: false, setSchedule: false }, action) => {
  switch (action.type) {
    case types.GET_SCHEDULER_REQUEST:
      return { ...state, getSchedule: true };
    case types.GET_SCHEDULER_SUCCESS:
    case types.GET_SCHEDULER_FAIL:
      return { ...state, getSchedule: false };
    case types.SET_SCHEDULER_REQUEST:
      return { ...state, setSchedule: true };
    case types.SET_SCHEDULER_SUCCESS:
    case types.SET_SCHEDULER_FAIL:
      return { ...state, setSchedule: false };
    default:
      return state;
  }
};

const schedulerReducer = combineReducers({
  schedule,
  spinners,
});

export default schedulerReducer;
