import { combineReducers } from 'redux';

import * as types from './login-page.types';

const roles = (state = null, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      if (!action.payload.roles) {
        return state;
      }
      return action.payload.roles;
    default:
      return state;
  }
};

const permissions = (state = null, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      if (!action.payload.permissions) {
        return state;
      }
      return action.payload.permissions;
    default:
      return state;
  }
};

const userId = (state = null, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return action.payload.userId;
    default:
      return state;
  }
};

const spinners = (state = { login: false }, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { login: true };
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAIL:
      return { login: false };
    default:
      return state;
  }
};

const authReducer = combineReducers({
  userId,
  roles,
  permissions,
  spinners,
});

export default authReducer;
