import * as types from './violations.types';

// CREATE VIOLATION
export const createViolationRequest = payload => ({
  type: types.CREATE_VIOLATION_REQUEST,
  payload,
});
export const createViolationSuccess = () => ({
  type: types.CREATE_VIOLATION_SUCCESS,
});
export const createViolationFail = () => ({
  type: types.CREATE_VIOLATION_FAIL,
});

// UPDATE VIOLATION
export const updateViolationRequest = payload => ({
  type: types.UPDATE_VIOLATION_REQUEST,
  payload,
});
export const updateViolationSuccess = () => ({
  type: types.UPDATE_VIOLATION_SUCCESS,
});
export const updateViolationFail = () => ({
  type: types.UPDATE_VIOLATION_FAIL,
});

// DELETE VIOLATION
export const deleteViolationRequest = payload => ({
  type: types.DELETE_VIOLATION_REQUEST,
  payload,
});
export const deleteViolationSuccess = () => ({
  type: types.DELETE_VIOLATION_SUCCESS,
});
export const deleteViolationFail = () => ({
  type: types.DELETE_VIOLATION_FAIL,
});

// GET VIOLATIONS
export const getViolationsRequest = payload => ({
  type: types.GET_VIOLATIONS_REQUEST,
  payload,
});
export const getViolationsSuccess = payload => ({
  type: types.GET_VIOLATIONS_SUCCESS,
  payload,
});
export const getViolationsFail = () => ({
  type: types.GET_VIOLATIONS_FAIL,
});
