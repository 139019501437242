import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#536dfe' },
    secondary: { main: '#4285f4' },
  },
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: '#f16c00',
        height: 4,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        wordBreak: 'break-all',
      },
    },
  },
});

export default theme;
