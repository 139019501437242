import * as types from './dashboard-page.types';

// INITIAL FILTERS
export const applyInitialFiltersRequest = () => ({
  type: types.APPLY_INITIAL_FILTERS_REQUEST,
});
export const applyInitialFiltersSuccess = payload => ({
  type: types.APPLY_INITIAL_FILTERS_SUCCESS,
  payload,
});
export const applyInitialFiltersFail = () => ({
  type: types.APPLY_INITIAL_FILTERS_FAIL,
});

// FILTERS
export const applyFiltersRequest = payload => ({
  type: types.APPLY_FILTERS_REQUEST,
  payload,
});
export const applyFiltersSuccess = payload => ({
  type: types.APPLY_FILTERS_SUCCESS,
  payload,
});
export const applyFiltersFail = () => ({
  type: types.APPLY_FILTERS_FAIL,
});
export const changeFilters = payload => ({
  type: types.CHANGE_FILTERS,
  payload,
});

// TABS
export const changeReportTab = payload => ({
  type: types.CHANGE_REPORT_TAB,
  payload,
});

// SUBSTITUTION
export const addSubstitutionRequest = payload => ({
  type: types.ADD_SUBSTITUTION_REQUEST,
  payload,
});
export const addSubstitutionSuccess = () => ({
  type: types.ADD_SUBSTITUTION_SUCCESS,
});
export const addSubstitutionFail = () => ({
  type: types.ADD_SUBSTITUTION_FAIL,
});

// Average Enrolled, Attended with Income
export const getEnrolledAttendedWithIncomeSuccess = payload => ({
  type: types.GET_ENROLLED_ATTENDED_WITH_INCOME_SUCCESS,
  payload,
});
export const getEnrolledAttendedWithIncomeFail = () => ({
  type: types.GET_ENROLLED_ATTENDED_WITH_INCOME_FAIL,
});

// Attendees by Time Period
export const getAttendeesByPeriodSuccess = payload => ({
  type: types.GET_ATTENDEES_BY_PERIOD_SUCCESS,
  payload,
});
export const getAttendeesByPeriodFail = () => ({
  type: types.GET_ATTENDEES_BY_PERIOD_FAIL,
});

// Teachers in the Classroom with the Total Expense
export const getTeachersInClassroomSuccess = payload => ({
  type: types.GET_TEACHERS_IN_CLASSROOM_SUCCESS,
  payload,
});
export const getTeachersInClassroomFail = () => ({
  type: types.GET_TEACHERS_IN_CLASSROOM_FAIL,
});

// List of All Teachers and Office Employees
export const getTeachersAndEmployeesSuccess = payload => ({
  type: types.GET_TEACHERS_AND_EMPLOYEES_SUCCESS,
  payload,
});
export const getTeachersAndEmployeesFail = () => ({
  type: types.GET_TEACHERS_AND_EMPLOYEES_FAIL,
});

// Number of teachers needed by calculating, if classes can be combined without reaching the max per class
export const getTeachersNeededByCalculatingSuccess = payload => ({
  type: types.GET_TEACHERS_NEEDED_BY_CALCULATING_SUCCESS,
  payload,
});
export const getTeachersNeededByCalculatingFail = () => ({
  type: types.GET_TEACHERS_NEEDED_BY_CALCULATING_FAIL,
});

// Compare Monies Billed vs Monies Received
export const getMoniesBilledVsReceivedSuccess = payload => ({
  type: types.GET_MONIES_BILLED_VS_RECEIVED_SUCCESS,
  payload,
});
export const getMoniesBilledVsReceivedFail = () => ({
  type: types.GET_MONIES_BILLED_VS_RECEIVED_FAIL,
});

// List of Substitutes by Week
export const getListOfSubstitutesSuccess = payload => ({
  type: types.GET_LIST_OF_SUBSTITUTES_SUCCESS,
  payload,
});
export const getListOfSubstitutesFail = () => ({
  type: types.GET_LIST_OF_SUBSTITUTES_FAIL,
});

// Income and costs
export const getIncomeAndCostsSuccess = payload => ({
  type: types.GET_INCOME_AND_COSTS_SUCCESS,
  payload,
});
export const getIncomeAndCostsFail = () => ({
  type: types.GET_INCOME_AND_COSTS_FAIL,
});

// Violations, Received with Amounts
export const getViolationsCountSuccess = payload => ({
  type: types.GET_VIOLATIONS_COUNT_SUCCESS,
  payload,
});
export const getViolationsCountFail = () => ({
  type: types.GET_VIOLATIONS_COUNT_FAIL,
});

// Number of Parents' Complaints
export const getComplaintsCountSuccess = payload => ({
  type: types.GET_COMPLAINTS_COUNT_SUCCESS,
  payload,
});
export const getComplaintsCountFail = () => ({
  type: types.GET_COMPLAINTS_COUNT_FAIL,
});

// Compare Attendees versus Enrollees
export const getAttendeesVsEnrolleesSuccess = payload => ({
  type: types.GET_ATTENDEES_VS_ENROLLEES_SUCCESS,
  payload,
});
export const getAttendeesVsEnrolleesFail = () => ({
  type: types.GET_ATTENDEES_VS_ENROLLEES_FAIL,
});

// Count of Teachers per Classroom
export const getCountOfTeachersPerClassroomSuccess = payload => ({
  type: types.GET_COUNT_OF_TEACHERS_PER_CLASSROOM_SUCCESS,
  payload,
});
export const getCountOfTeachersPerClassroomFail = () => ({
  type: types.GET_COUNT_OF_TEACHERS_PER_CLASSROOM_FAIL,
});
