export const USERS = 'USERS';
export const HISTORY = 'HISTORY';
export const DASHBOARD = 'DASHBOARD';
export const SUBSTITUTES = 'SUBSTITUTES';
export const COMPLAINT = 'COMPLAINT';
export const COMPLAINTS_LIST = 'COMPLAINTS_LIST';
export const VIOLATION = 'VIOLATION';
export const VIOLATIONS_LIST = 'VIOLATIONS_LIST';
export const PDF = 'PDF';
export const CSV = 'CSV';
export const SCHEDULER = 'SCHEDULER';
