import { combineReducers } from 'redux';

import * as types from './complaints.types';

const list = (state = [], action) => {
  switch (action.type) {
    case types.GET_COMPLAINTS_SUCCESS:
      return [...action.payload.complaints];
    default:
      return state;
  }
};

const paginationState = {
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  rowCount: 0,
};
const pagination = (state = paginationState, action) => {
  switch (action.type) {
    case types.GET_COMPLAINTS_SUCCESS:
      return { ...state, ...action.payload.pagination };
    default:
      return state;
  }
};

const spinnersState = {
  getComplaints: false,
  createComplaint: false,
  updateComplaint: false,
  deleteComplaint: false,
};
const spinners = (state = spinnersState, action) => {
  switch (action.type) {
    case types.GET_COMPLAINTS_REQUEST:
      return { ...state, getComplaints: true };
    case types.GET_COMPLAINTS_FAIL:
    case types.GET_COMPLAINTS_SUCCESS:
      return { ...state, getComplaints: false };
    case types.CREATE_COMPLAINT_REQUEST:
      return { ...state, createComplaint: true };
    case types.UPDATE_COMPLAINT_REQUEST:
      return { ...state, updateComplaint: true };
    case types.DELETE_COMPLAINT_REQUEST:
      return { ...state, deleteComplaint: true };
    case types.CREATE_COMPLAINT_SUCCESS:
    case types.CREATE_COMPLAINT_FAIL:
      return { ...state, createComplaint: false };
    case types.UPDATE_COMPLAINT_SUCCESS:
    case types.UPDATE_COMPLAINT_FAIL:
      return { ...state, updateComplaint: false };
    case types.DELETE_COMPLAINT_SUCCESS:
    case types.DELETE_COMPLAINT_FAIL:
      return { ...state, deleteComplaint: false };
    default:
      return state;
  }
};

const complaintsReducer = combineReducers({
  list,
  pagination,
  spinners,
});

export default complaintsReducer;
