import { takeEvery, put, take, race } from 'redux-saga/effects';
import { history, prettifiers } from '../../helpers';

import {
  createHistoryRequest,
  CREATE_HISTORY_LOG_SUCCESS,
  CREATE_HISTORY_LOG_FAIL,
} from '../../pages/history-log-page';
import { resetStore } from './app-bar.actions';
import { LOGOUT } from './app-bar.types';

import AUTH_TOKEN, { logTypes } from '../../constants';

function* logoutSaga() {
  yield put(createHistoryRequest(prettifiers.historyLogMessage(logTypes.LOGOUT)));
  yield race([take(CREATE_HISTORY_LOG_SUCCESS), take(CREATE_HISTORY_LOG_FAIL)]);

  localStorage.removeItem(AUTH_TOKEN);
  history.replace('/login');
  yield put(resetStore());
}

export default function* authSaga() {
  yield takeEvery(LOGOUT, logoutSaga);
}
