import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import { get, filter } from 'lodash/';
import { Button, FormControlLabel, Checkbox, CircularProgress, InputAdornment, TextField } from '@material-ui/core/';

import { createValidator } from '../../../helpers';
import FieldWithIcon from '../../../components/field-with-icon';
import ButtonWithSpinner from '../../../components/button-with-spinner';
import Select from '../../../components/select';
import Chips from '../../../components/chips';

const setLocations = user => {
  if (user) {
    return get(user, 'locations').map(location => location.id);
  }
  return [];
};
@withFormik({
  validate: createValidator(validator =>
    validator.object().shape({
      firstName: validator
        .string()
        .max(256, 'First Name must be at most 256 characters')
        .matches(/^[a-zA-Z\s]*$/, 'First Name must contain only letters')
        .required('First Name is required'),
      lastName: validator
        .string()
        .max(256, 'Last Name must be at most 256 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Last Name must contain only letters')
        .required('Last Name is required'),
      email: validator
        .string()
        .email('Must be a valid email')
        .required('Email is required'),
      password: validator.string().when('type', {
        is: type => type === 'create',
        then: validator
          .string()
          .min(8, 'Password must be at least 8 characters')
          .max(256, 'Password must be at most 256 characters')
          .required('Password is required'),
        otherwise: validator.string().test('password', 'Password must be 8-256 characters', function(value) {
          if (!!value) {
            const schema = validator
              .string()
              .min(8, 'Password must be at least 8 characters')
              .max(256, 'Password must be at most 256 characters')
              .required('Password is required');
            return schema.isValidSync(value);
          }
          return true;
        }),
      }),
      confirmPassword: validator.string().equalTo(validator.ref('password'), 'Must match with Password field'),
      type: validator.string(),
    })
  ),
  mapPropsToValues: ({ user }) => ({
    firstName: get(user, 'firstName') || '',
    lastName: get(user, 'lastName') || '',
    email: get(user, 'email') || '',
    locations: setLocations(user),
    viewNonFinancialReports: user ? user.viewNonFinancialReports : false,
    viewFinancialReports: user ? user.viewFinancialReports : false,
    type: user ? 'update' : 'create',
    password: '',
    confirmPassword: '',
  }),
})
class UserModal extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      viewNonFinancialReports: PropTypes.bool.isRequired,
      viewFinancialReports: PropTypes.bool.isRequired,
      locations: PropTypes.array.isRequired,
    }),
    title: PropTypes.string.isRequired,
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onEmailCheck: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    emailCheckProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    isEmailFree: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
  };

  componentWillUnmount() {
    const { isEmailFree, resetEmailError } = this.props;
    if (!isEmailFree) resetEmailError();
  }

  onChangeHandler = e => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  emailCheckHandler = e => {
    const { onEmailCheck, user } = this.props;
    const { name, value } = e.target;
    if (name === 'email') {
      if (!value) return;
      onEmailCheck({ email: e.target.value, id: get(user, 'id') });
    }
  };

  onBlurHandler = e => {
    const { setFieldTouched } = this.props;
    setFieldTouched(e.target.name, true, false);
  };

  onCheckboxHandler = e => {
    const { values, setFieldValue } = this.props;
    const { name } = e.target;
    setFieldValue(name, !values[name]);
  };

  onSelectHandler = locations => {
    const { setFieldValue } = this.props;
    setFieldValue('locations', locations);
  };

  onChipHandler = removedLocation => {
    const { values, setFieldValue } = this.props;
    const filteredLocations = filter(values.locations, location => location !== removedLocation);
    setFieldValue('locations', filteredLocations);
  };

  onSubmitHandler = () => {
    const { onSubmit, values, isValid, user } = this.props;
    const { type, ...userValues } = values;
    if (!isValid) return;
    const locationsIds = userValues.locations;
    if (type === 'update') {
      onSubmit({ ...user, ...userValues, locationsIds });
      return;
    }
    onSubmit({ ...userValues, locationsIds });
  };

  onCancelHandler = () => {
    this.props.onClose();
  };

  get passwordInputProps() {
    if (!this.props.user) return {};
    return {
      placeholder: '***********',
      InputLabelProps: {
        shrink: true,
      },
    };
  }

  get emailInputProps() {
    const { emailCheckProgress } = this.props;
    return {
      InputProps: {
        endAdornment: emailCheckProgress && (
          <InputAdornment position="end">
            <CircularProgress size={15} />
          </InputAdornment>
        ),
      },
    };
  }

  get emailErrorText() {
    if (!this.props.isEmailFree) {
      return 'Email must be unique';
    }
    if (this.props.touched.email) {
      return this.props.errors.email;
    }
    return '';
  }

  render() {
    const { errors, touched, values, loading, isValid, user, isEmailFree, locations, title } = this.props;

    return (
      <div className="modal user-modal">
        <div className="modal-header">{title}</div>
        <Form>
          <div className="columns-holder">
            <div className="col">
              <FieldWithIcon icon="person" error={touched.firstName && !!errors.firstName} onBlur={this.onBlurHandler}>
                <TextField
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  label="First Name"
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName ? errors.firstName : ''}
                  onChange={this.onChangeHandler}
                  autoComplete="off"
                  fullWidth
                />
              </FieldWithIcon>

              <FieldWithIcon icon="person" error={touched.lastName && !!errors.lastName} onBlur={this.onBlurHandler}>
                <TextField
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  label="Last Name"
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName ? errors.lastName : ''}
                  onChange={this.onChangeHandler}
                  autoComplete="off"
                  fullWidth
                />
              </FieldWithIcon>

              <FieldWithIcon
                icon="email"
                error={!isEmailFree || (touched.email && !!errors.email)}
                onBlur={this.onBlurHandler}
                readOnly
              >
                <TextField
                  type="email"
                  name="email"
                  value={values.email}
                  label="Email"
                  error={!isEmailFree || (touched.email && !!errors.email)}
                  helperText={this.emailErrorText}
                  autoComplete="off"
                  fullWidth
                  onChange={e => {
                    this.onChangeHandler(e);
                    this.emailCheckHandler(e);
                  }}
                  {...this.emailInputProps}
                />
              </FieldWithIcon>

              {!!user && (
                <div className="notice">
                  To update user's password fill Password and Confirm Password fields with new values
                </div>
              )}

              <FieldWithIcon
                icon="lock"
                error={touched.password && !!errors.password}
                onBlur={this.onBlurHandler}
                readOnly
              >
                <TextField
                  type="password"
                  name="password"
                  value={values.password}
                  label="Password"
                  error={touched.password && !!errors.password}
                  helperText={touched.password ? errors.password : ''}
                  onChange={this.onChangeHandler}
                  autoComplete="new-password"
                  fullWidth
                  {...this.passwordInputProps}
                />
              </FieldWithIcon>

              <FieldWithIcon
                icon="lock"
                error={touched.confirmPassword && !!errors.confirmPassword}
                onBlur={this.onBlurHandler}
                readOnly
              >
                <TextField
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  label="Confirm Password"
                  error={touched.confirmPassword && !!errors.confirmPassword}
                  helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                  onChange={this.onChangeHandler}
                  autoComplete="new-password"
                  fullWidth
                  {...this.passwordInputProps}
                />
              </FieldWithIcon>

              <FormControlLabel
                className="input-checkbox"
                control={
                  <Checkbox
                    className="simple-checkbox"
                    name="viewNonFinancialReports"
                    checked={values.viewNonFinancialReports}
                    onChange={this.onCheckboxHandler}
                    color="primary"
                  />
                }
                label="View Non-Financial Reports"
              />

              <FormControlLabel
                className="input-checkbox"
                control={
                  <Checkbox
                    className="simple-checkbox"
                    name="viewFinancialReports"
                    checked={values.viewFinancialReports}
                    onChange={this.onCheckboxHandler}
                    color="primary"
                  />
                }
                label="View Financial Reports"
              />
            </div>
            <div className="col">
              <div className="location-label">{values.locations.length} Location(s)</div>
              <Select
                multiple
                label="Select Locations"
                values={values.locations}
                options={locations}
                onChange={this.onSelectHandler}
                valueKey="id"
                labelKey="name"
                allSelected={values.locations.length === locations.length}
              />
              <div className="tags-holder height-limited">
                <Chips values={values.locations} options={locations} onDelete={this.onChipHandler} valueKey="id" />
              </div>
            </div>
          </div>
          <div className="footer-buttons">
            <Button onClick={this.onCancelHandler} className="light-btn dark">
              CANCEL
            </Button>
            <div className="button-holder">
              <ButtonWithSpinner
                className="light-btn"
                type="button"
                onClick={this.onSubmitHandler}
                loading={loading}
                disabled={!isValid || !isEmailFree}
              >
                SAVE
              </ButtonWithSpinner>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default UserModal;
