import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

import PrivateRoute from './private-route';
import DefaultRedirect from './redirect-route';
import { history } from '../helpers';
import { role } from '../constants';

const LoginPage = lazy(() => import('../pages/login-page'));
const UsersPage = lazy(() => import('../pages/users-page'));
const HistoryLogPage = lazy(() => import('../pages/history-log-page'));
const DashboardPage = lazy(() => import('../pages/dashboard-page'));
const ComplaintsPage = lazy(() => import('../pages/complaints-page'));
const ViolationsPage = lazy(() => import('../pages/violations-page'));
const SchedulerPage = lazy(() => import('../pages/scheduler-page'));
const NotFoundPage = lazy(() => import('../pages/not-found-page'));

const AccessAdmin = PrivateRoute([role.ADMIN]);
const AccessUser = PrivateRoute([role.ADMIN, role.USER]);

const AppRouting = () => (
  <Router history={history}>
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route exact path="/" component={DefaultRedirect} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/dashboard" component={AccessUser(DashboardPage)} />
        <Route exact path="/dashboard/complaints" component={AccessUser(ComplaintsPage)} />
        <Route exact path="/dashboard/violations" component={AccessUser(ViolationsPage)} />
        <Route exact path="/admin" component={AccessAdmin(UsersPage)} />
        <Route exact path="/admin/api-scheduler" component={AccessAdmin(SchedulerPage)} />
        <Route exact path="/admin/history-log" component={AccessAdmin(HistoryLogPage)} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  </Router>
);

export default AppRouting;
