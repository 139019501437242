import HTTP from './http';

export const createComplaint = async complaint => {
  await HTTP.put('/complaints', complaint);
};

export const updateComplaint = async complaint => {
  await HTTP.post('/complaints', complaint);
};

export const deleteComplaint = async id => {
  await HTTP.delete('/complaints', { data: { id } });
};
