import { HIDE_SNACKBAR, SHOW_SNACKBAR } from './snack-bar.types';

const initialState = {
  variant: '',
  message: '',
  open: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...state, variant: action.payload.variant, message: action.payload.message, open: true };
    case HIDE_SNACKBAR:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default authReducer;
