import { combineReducers } from 'redux';

import { dashboardReducer } from '../pages/dashboard-page';
import { authReducer } from '../pages/login-page';
import { usersReducer } from '../pages/users-page';
import { historyLogReducer } from '../pages/history-log-page';
import { violationsReducer } from '../pages/violations-page';
import { complaintsReducer } from '../pages/complaints-page';
import { schedulerReducer } from '../pages/scheduler-page';
import { snackbarReducer } from '../components/snack-bar';
import { modalReducer } from '../components/modal';
import { locationsReducer } from '../shared/locations';

const uiReducer = combineReducers({
  snack: snackbarReducer,
  modal: modalReducer,
});

export default (state, action) => {
  const appReducer = combineReducers({
    user: authReducer,
    users: usersReducer,
    report: dashboardReducer,
    history: historyLogReducer,
    locations: locationsReducer,
    violations: violationsReducer,
    complaints: complaintsReducer,
    scheduler: schedulerReducer,
    ui: uiReducer,
  });

  if (action.type === 'RESET_STORE') {
    state = {};
  }

  return appReducer(state, action);
};
