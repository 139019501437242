import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';

import VirtualizedList from '../../../components/virtualized-list';

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.array,
    loading: PropTypes.bool,
    byLocation: PropTypes.number,
  };

  state = {
    title: '',
    titleIndex: -1,
    classroomScrollBarSize: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.cacheByClassrooms.clearAll();

      if (this.ByClassroomsList.current) {
        this.ByClassroomsList.current.forceUpdateGrid();
      }

      this.setState({ title: this.props.report[0].name || '' });
    }
  }

  ByClassroomsList = React.createRef();

  cacheByClassrooms = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByClassroom = ({ index, style, parent, key }) => {
    const { report } = this.props;

    return (
      <CellMeasurer key={key} cache={this.cacheByClassrooms} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="group-row">{report[index].name}</div>
          {report[index].classrooms.map((classroom, i) => (
            <div className="row" key={`${report[index]}-${classroom.name}-${i}`}>
              <div className="cell column">{classroom.name}</div>
              <div className="cell column">{classroom.teachersCount}</div>
              <div className="cell column">
                {classroom.leadTeachers.map((lead, index) => (
                  <div key={`${lead.personFullName}-${index}`} className="sub-cell">
                    {lead.personFullName}
                  </div>
                ))}
              </div>
              <div className="cell column">
                {classroom.assistantTeachers.map((assistant, index) => (
                  <div key={`${assistant.personFullName}-${index}`} className="sub-cell">
                    {assistant.personFullName}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CellMeasurer>
    );
  };

  rowsRenderHandler = ({ startIndex }) => {
    const { report } = this.props;
    const { titleIndex } = this.state;

    if (startIndex !== titleIndex) {
      this.setState({ title: report[startIndex].name, titleIndex: startIndex });
    }
  };

  detectScrollBar = ({ vertical, size }) => this.setState({ classroomScrollBarSize: vertical ? size : 0 });

  render() {
    const { report, loading } = this.props;
    const { title, classroomScrollBarSize } = this.state;

    if (!report.length) return <div className="no-data-title">No data for applied filters!</div>;

    return (
      <Fragment>
        {loading && (
          <div className="table-spinner">
            <CircularProgress />
          </div>
        )}
        <div className="group-title">{title}</div>
        <div className="table-header">
          <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
            Classroom
          </div>
          <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
            Teachers Count
          </div>
          <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
            Lead Teacher
          </div>
          <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
            Assistant Teacher
          </div>
        </div>
        <VirtualizedList
          ref={this.ByClassroomsList}
          onRowsRendered={this.rowsRenderHandler}
          rowRenderer={this.rowByClassroom}
          rowCount={report.length}
          deferredMeasurementCache={this.cacheByClassrooms}
          rowHeight={this.cacheByClassrooms.rowHeight}
          onScrollbarPresenceChange={this.detectScrollBar}
        />
      </Fragment>
    );
  }
}

export default Report;
