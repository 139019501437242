import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon } from '@material-ui/core';

import ViolationsTable from './violations-table';
import ViolationDeleteModal from './violation-delete-modal';
import { ViolationUpsertModal } from '../../shared/modals';
import * as violationsActions from './violations.actions';
import { locationActions } from '../../shared/locations';

import ModalContainer, { modalActions } from '../../components/modal';
import { modalTypes } from '../../constants';

@connect(
  ({
    locations: { userLocations, workAreas, spinners: locationSpinners },
    ui: { modal },
    violations: { list: violationsList, pagination, spinners },
  }) => ({
    userLocations,
    workAreas,
    violationsList,
    pagination,
    spinners,
    locationSpinners,
    modal,
  }),
  {
    openModal: modalActions.openModal,
    hideModal: modalActions.hideModal,
    updateViolationRequest: violationsActions.updateViolationRequest,
    deleteViolationRequest: violationsActions.deleteViolationRequest,
    getViolationsRequest: violationsActions.getViolationsRequest,
    getUserLocationsRequest: locationActions.getUserLocationsRequest,
    getWorkAreasRequest: locationActions.getWorkAreasRequest,
  }
)
class ViolationsPage extends Component {
  static propTypes = {};

  componentDidMount() {
    const {
      pagination: { currentPage, pageSize },
      getViolationsRequest,
      getUserLocationsRequest,
    } = this.props;
    getViolationsRequest({ currentPage, pageSize });
    getUserLocationsRequest();
  }

  getWorkAreas = locationId => {
    this.props.getWorkAreasRequest(locationId);
  };

  updateViolation = violation => {
    this.props.updateViolationRequest(violation);
  };

  deleteViolation = violation => {
    this.props.deleteViolationRequest(violation);
  };

  changeCurrentPageHandler = (_, page) => {
    const {
      pagination: { pageSize },
    } = this.props;
    this.props.getViolationsRequest({ currentPage: page + 1, pageSize });
  };

  changePageSizeHandler = e => {
    const pageSize = e.target.value;
    const {
      pagination: { currentPage },
    } = this.props;
    this.props.getViolationsRequest({ currentPage, pageSize });
  };

  openCreateViolationModal = () => {
    this.props.openModal({
      modalType: modalTypes.CREATE_VIOLATION,
    });
  };

  openUpdateViolationModal = violation => {
    this.props.openModal({
      modalType: modalTypes.UPDATE_VIOLATION,
      modalProps: violation,
    });
  };

  openDeleteViolationModal = violation => {
    this.props.openModal({
      modalType: modalTypes.DELETE_VIOLATION,
      modalProps: violation,
    });
  };

  closeModalHandler = modalType => {
    this.props.hideModal({ modalType });
  };

  render() {
    const {
      pagination,
      violationsList,
      workAreas,
      spinners,
      locationSpinners,
      userLocations,
      modal: { modalType, modalProps },
    } = this.props;

    return (
      <div className="container admin-page">
        <div className="button-holder">
          <div className="pusher" />
          <h1 className="title">BeanStalk Academy Dashboard</h1>
          <Button variant="contained" className="simple-btn" color="primary" onClick={this.openCreateViolationModal}>
            <Icon>add</Icon>
            ADD VIOLATION
          </Button>
        </div>

        <ViolationsTable
          onEdit={this.openUpdateViolationModal}
          onDelete={this.openDeleteViolationModal}
          onChangePage={this.changeCurrentPageHandler}
          onChangeRowsPerPage={this.changePageSizeHandler}
          loading={spinners.getViolations}
          pagination={pagination}
          data={violationsList}
        />

        <ModalContainer
          onClose={this.closeModalHandler}
          disableAutoFocus
          open={modalType === modalTypes.UPDATE_VIOLATION}
        >
          <ViolationUpsertModal
            title="Edit Violation"
            violation={modalProps}
            onSubmit={this.updateViolation}
            onClose={() => this.closeModalHandler(modalTypes.UPDATE_VIOLATION)}
            loading={spinners.updateViolation}
            locations={userLocations}
            workAreas={workAreas}
            onLoading={this.getWorkAreas}
            getWorkAreaProgress={locationSpinners.getWorkAreas}
          />
        </ModalContainer>

        <ViolationDeleteModal
          open={modalType === modalTypes.DELETE_VIOLATION}
          onClose={() => this.closeModalHandler(modalTypes.DELETE_VIOLATION)}
          onSubmit={this.deleteViolation}
          violation={modalProps}
          loading={spinners.deleteViolation}
        />
      </div>
    );
  }
}

export default ViolationsPage;
