// INITIAL FILTERS
export const APPLY_INITIAL_FILTERS_REQUEST = 'APPLY_INITIAL_FILTERS_REQUEST';
export const APPLY_INITIAL_FILTERS_SUCCESS = 'APPLY_INITIAL_FILTERS_SUCCESS';
export const APPLY_INITIAL_FILTERS_FAIL = 'APPLY_INITIAL_FILTERS_FAIL';

// FILTERS
export const APPLY_FILTERS_REQUEST = 'APPLY_FILTERS_REQUEST';
export const APPLY_FILTERS_SUCCESS = 'APPLY_FILTERS_SUCCESS';
export const APPLY_FILTERS_FAIL = 'APPLY_FILTERS_FAIL';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';

// TABS
export const CHANGE_REPORT_TAB = 'CHANGE_REPORT_TAB';

// SUBSTITUTION
export const ADD_SUBSTITUTION_REQUEST = 'ADD_SUBSTITUTION_REQUEST';
export const ADD_SUBSTITUTION_SUCCESS = 'ADD_SUBSTITUTION_SUCCESS';
export const ADD_SUBSTITUTION_FAIL = 'ADD_SUBSTITUTION_FAIL';

// Average Enrolled, Attended with Income
export const GET_ENROLLED_ATTENDED_WITH_INCOME_SUCCESS = 'GET_ENROLLED_ATTENDED_WITH_INCOME_SUCCESS';
export const GET_ENROLLED_ATTENDED_WITH_INCOME_FAIL = 'GET_ENROLLED_ATTENDED_WITH_INCOME_FAIL';

// Attendees by Time Period
export const GET_ATTENDEES_BY_PERIOD_SUCCESS = 'GET_ATTENDEES_BY_PERIOD_SUCCESS';
export const GET_ATTENDEES_BY_PERIOD_FAIL = 'GET_ATTENDEES_BY_PERIOD_FAIL';

// Teachers in the Classroom with the Total Expense
export const GET_TEACHERS_IN_CLASSROOM_SUCCESS = 'GET_TEACHERS_IN_CLASSROOM_SUCCESS';
export const GET_TEACHERS_IN_CLASSROOM_FAIL = 'GET_TEACHERS_IN_CLASSROOM_FAIL';

// List of All Teachers and Office Employees
export const GET_TEACHERS_AND_EMPLOYEES_SUCCESS = 'GET_TEACHERS_AND_EMPLOYEES_SUCCESS';
export const GET_TEACHERS_AND_EMPLOYEES_FAIL = 'GET_TEACHERS_AND_EMPLOYEES_FAIL';

// Number of teachers needed by calculating, if classes can be combined without reaching the max per class
export const GET_TEACHERS_NEEDED_BY_CALCULATING_SUCCESS = 'GET_TEACHERS_NEEDED_BY_CALCULATING_SUCCESS';
export const GET_TEACHERS_NEEDED_BY_CALCULATING_FAIL = 'GET_TEACHERS_NEEDED_BY_CALCULATING_FAIL';

// Compare Monies Billed vs Monies Received
export const GET_MONIES_BILLED_VS_RECEIVED_SUCCESS = 'GET_MONIES_BILLED_VS_RECEIVED_SUCCESS';
export const GET_MONIES_BILLED_VS_RECEIVED_FAIL = 'GET_MONIES_BILLED_VS_RECEIVED_FAIL';

// List of Substitutes by Week
export const GET_LIST_OF_SUBSTITUTES_SUCCESS = 'GET_LIST_OF_SUBSTITUTES_SUCCESS';
export const GET_LIST_OF_SUBSTITUTES_FAIL = 'GET_LIST_OF_SUBSTITUTES_FAIL';

// Income and costs
export const GET_INCOME_AND_COSTS_SUCCESS = 'GET_INCOME_AND_COSTS_SUCCESS';
export const GET_INCOME_AND_COSTS_FAIL = 'GET_INCOME_AND_COSTS_FAIL';

// Violations, Received with Amounts
export const GET_VIOLATIONS_COUNT_SUCCESS = 'GET_VIOLATIONS_COUNT_SUCCESS';
export const GET_VIOLATIONS_COUNT_FAIL = 'GET_VIOLATIONS_COUNT_FAIL';

// Number of Parents' Complaints
export const GET_COMPLAINTS_COUNT_SUCCESS = 'GET_COMPLAINTS_COUNT_SUCCESS';
export const GET_COMPLAINTS_COUNT_FAIL = 'GET_COMPLAINTS_COUNT_FAIL';

// Compare Attendees versus Enrollees
export const GET_ATTENDEES_VS_ENROLLEES_SUCCESS = 'GET_ATTENDEES_VS_ENROLLEES_SUCCESS';
export const GET_ATTENDEES_VS_ENROLLEES_FAIL = 'GET_ATTENDEES_VS_ENROLLEES_FAIL';

// Count of Teachers per Classroom
export const GET_COUNT_OF_TEACHERS_PER_CLASSROOM_SUCCESS = 'GET_COUNT_OF_TEACHERS_PER_CLASSROOM_SUCCESS';
export const GET_COUNT_OF_TEACHERS_PER_CLASSROOM_FAIL = 'GET_COUNT_OF_TEACHERS_PER_CLASSROOM_FAIL';
