import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import VirtualizedList from '../../../components/virtualized-list';

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    byLocation: PropTypes.number,
  };

  state = {
    scrollBarSize: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.ByLocationsList.current && this.ByLocationsList.current.forceUpdateGrid();
    }
  }

  ByLocationsList = React.createRef();
  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByLocation = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell">{report.locations[index].name}</div>
            <div className="cell">{report.locations[index].complaintsCount}</div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  detectScrollBar = ({ vertical, size }) => {
    this.setState({ scrollBarSize: vertical ? size : 0 });
  };

  render() {
    const { report, addComplaint, linkTo, loading } = this.props;
    const { scrollBarSize } = this.state;

    return get(report, 'locations', []).length ? (
      <>
        {loading && (
          <div className="table-spinner">
            <CircularProgress />
          </div>
        )}
        <div className="table-header">
          <div className="header-cell" style={{ paddingRight: scrollBarSize }}>
            Location
          </div>
          <div className="header-cell" style={{ paddingRight: scrollBarSize }}>
            Complains
          </div>
        </div>
        <VirtualizedList
          ref={this.ByLocationsList}
          rowRenderer={this.rowByLocation}
          rowCount={report.locations.length}
          deferredMeasurementCache={this.cacheByLocations}
          rowHeight={this.cacheByLocations.rowHeight}
          onScrollbarPresenceChange={this.detectScrollBar}
        />
        <div className="table-footer" style={{ paddingRight: scrollBarSize }}>
          <div className="large-footer-cell">
            <div className="footer-links">
              <span className="link-holder">
                <button className="link" type="button" onClick={addComplaint}>
                  Add Complaint
                </button>
              </span>
              <span className="link-holder">
                <NavLink className="link" exact to={linkTo}>
                  See Details
                </NavLink>
              </span>
            </div>
            <div className="footer-cell">Total</div>
          </div>
          <div className="footer-cell">{report.totalComplaintsCount}</div>
        </div>
      </>
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
