import * as types from './locations.types';

// ALL LOCATIONS
export const getLocationsRequest = () => ({
  type: types.GET_LOCATIONS_REQUEST,
});
export const getLocationsSuccess = payload => ({
  type: types.GET_LOCATIONS_SUCCESS,
  payload,
});
export const getLocationsFail = () => ({
  type: types.GET_LOCATIONS_FAIL,
});

// ASSIGNED LOCATIONS
export const getUserLocationsRequest = () => ({
  type: types.GET_USER_LOCATIONS_REQUEST,
});
export const getUserLocationsSuccess = payload => ({
  type: types.GET_USER_LOCATIONS_SUCCESS,
  payload,
});
export const getUserLocationsFail = () => ({
  type: types.GET_USER_LOCATIONS_FAIL,
});

// WORKAREAS
export const getWorkAreasRequest = payload => ({
  type: types.GET_WORKAREAS_REQUEST,
  payload,
});
export const getWorkAreasSuccess = payload => ({
  type: types.GET_WORKAREAS_SUCCESS,
  payload,
});
export const getWorkAreasFail = () => ({
  type: types.GET_WORKAREAS_FAIL,
});
