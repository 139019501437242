import * as types from './scheduler-page.types';

// GET SCHEDULER
export const getSchedulerRequest = payload => ({
  type: types.GET_SCHEDULER_REQUEST,
  payload,
});

export const getSchedulerSuccess = payload => ({
  type: types.GET_SCHEDULER_SUCCESS,
  payload,
});

export const getSchedulerFail = () => ({
  type: types.GET_SCHEDULER_FAIL,
});

// SET SCHEDULER
export const setSchedulerRequest = payload => ({
  type: types.SET_SCHEDULER_REQUEST,
  payload,
});

export const setSchedulerSuccess = payload => ({
  type: types.SET_SCHEDULER_SUCCESS,
  payload,
});

export const setSchedulerFail = () => ({
  type: types.SET_SCHEDULER_FAIL,
});
