import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  CircularProgress,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core/';
import { dateHelpers } from '../../helpers';

class HistoryLogTable extends PureComponent {
  static propTypes = {
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
  };

  state = {
    expanded: null,
  };

  onRowClickHandler = id => {
    let expanded = this.state.expanded === id ? null : id;
    this.setState({ expanded });
  };

  sortHandler = order => {
    this.props.onSort(order);
  };

  render() {
    const { data, loading, pagination, onChangeRowsPerPage, onChangePage, order } = this.props;

    return (
      <div className="container history-log">
        <Paper className="table-wrapper">
          <div className="table-name">User History Log</div>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}

          <Table>
            <colgroup>
              <col width="23%" />
              <col width="20%" />
              <col width="57%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Tooltip title="Sort By Date" enterDelay={300}>
                    <TableSortLabel
                      active={order.orderBy === 'actionDateTime'}
                      direction={order.orderDirection}
                      onClick={() =>
                        this.sortHandler({
                          orderBy: 'actionDateTime',
                          orderDirection: order.orderDirection === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      Datestamp
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Sort By Full Name" enterDelay={300}>
                    <TableSortLabel
                      active={order.orderBy === 'userFullName'}
                      direction={order.orderDirection}
                      onClick={() =>
                        this.sortHandler({
                          orderBy: 'userFullName',
                          orderDirection: order.orderDirection === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      User Name
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(user => (
                <TableRow className="row" key={user.id} onClick={() => this.onRowClickHandler(user.id)}>
                  <TableCell>{dateHelpers.dateStampFormat(user.actionDateTime)}</TableCell>
                  <TableCell>{user.userFullName}</TableCell>
                  <TableCell>
                    <span className="span">
                      <Tooltip title={user.action.length > 55 ? user.action : ''}>
                        <span>{user.action}</span>
                      </Tooltip>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50]}
                  count={pagination.rowCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  SelectProps={{
                    style: {
                      width: 85,
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default HistoryLogTable;
