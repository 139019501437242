import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import CircularProgress from '@material-ui/core/CircularProgress';

import VirtualizedList from '../../../components/virtualized-list';

class Report extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    byLocation: PropTypes.number,
  };

  state = {
    title: '',
    titleIndex: -1,
    locationsCount: 0,
    classroomScrollBarSize: 0,
    locationScrollBarSize: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.cacheByClassrooms.clearAll();
      this.ByLocationsList.current && this.ByLocationsList.current.forceUpdateGrid();
      this.ByClassroomsList.current && this.ByClassroomsList.current.forceUpdateGrid();
      this.setState({
        title: get(this.props.report, 'locations[0].name', ''),
        locationsCount: get(this.props.report, 'locations', []).length,
      });
    }
  }

  ByClassroomsList = React.createRef();
  cacheByClassrooms = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  ByLocationsList = React.createRef();
  cacheByLocations = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  rowByClassroom = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByClassrooms} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="group-row">{report.locations[index].name}</div>
          {report.locations[index].classrooms.map((classroom, i) => (
            <div
              className="row"
              key={`${report.locations[index]}-${classroom.name}-${i}`}
              style={{ background: classroom.groupColor }}
            >
              <div className="cell">{classroom.name}</div>
              <div className="cell">{classroom.departmentName}</div>
              <div className="cell">{classroom.maxChildrenAllowed}</div>
              <div className="cell">{classroom.averageAttendeesCount}</div>
              <div className="cell">{classroom.averageEnrolledCount}</div>
            </div>
          ))}
        </div>
      </CellMeasurer>
    );
  };

  rowByLocation = ({ index, style, parent, key }) => {
    const { report } = this.props;
    return (
      <CellMeasurer key={key} cache={this.cacheByLocations} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <div className="row">
            <div className="cell">{report.locations[index].name}</div>
            <div className="cell">{report.locations[index].maxChildrenAllowed}</div>
            <div className="cell">{report.locations[index].averageAttendeesCount}</div>
            <div className="cell">{report.locations[index].averageEnrolledCount}</div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  rowsRenderHandler = ({ startIndex }) => {
    const { report, byLocation } = this.props;
    const { titleIndex } = this.state;

    if (startIndex !== titleIndex && !byLocation) {
      this.setState({ title: report.locations[startIndex].name, titleIndex: startIndex });
    }
  };

  detectScrollBar = ({ vertical, size }) => {
    const { byLocation, locationsCount } = this.props;

    if (byLocation) {
      this.setState({ locationScrollBarSize: vertical ? size : 0 });
      return;
    }

    this.setState({
      classroomScrollBarSize: vertical ? size : 0,
      locationScrollBarSize: locationsCount >= 10 ? size : 0,
    });
  };

  render() {
    const { byLocation, report, loading } = this.props;
    const { title, classroomScrollBarSize, locationScrollBarSize } = this.state;

    return get(report, 'locations', []).length ? (
      !byLocation ? (
        <>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div className="group-title">{title}</div>
          <div className="table-header">
            <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
              Classroom
            </div>
            <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
              Group Type
            </div>
            <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
              Max Children Allowed
            </div>
            <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
              Attendees
            </div>
            <div className="header-cell" style={{ paddingRight: classroomScrollBarSize }}>
              Enrolled
            </div>
          </div>
          <VirtualizedList
            ref={this.ByClassroomsList}
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByClassroom}
            rowCount={report.locations.length}
            deferredMeasurementCache={this.cacheByClassrooms}
            rowHeight={this.cacheByClassrooms.rowHeight}
            onScrollbarPresenceChange={this.detectScrollBar}
          />
        </>
      ) : (
        <>
          {loading && (
            <div className="table-spinner">
              <CircularProgress />
            </div>
          )}
          <div className="table-header">
            <div className="header-cell" style={{ paddingRight: locationScrollBarSize }}>
              Location
            </div>
            <div className="header-cell" style={{ paddingRight: locationScrollBarSize }}>
              Max Children Allowed
            </div>
            <div className="header-cell" style={{ paddingRight: locationScrollBarSize }}>
              Attendees
            </div>
            <div className="header-cell" style={{ paddingRight: locationScrollBarSize }}>
              Enrolled
            </div>
          </div>
          <VirtualizedList
            ref={this.ByLocationsList}
            onRowsRendered={this.rowsRenderHandler}
            rowRenderer={this.rowByLocation}
            rowCount={report.locations.length}
            deferredMeasurementCache={this.cacheByLocations}
            rowHeight={this.cacheByLocations.rowHeight}
            onScrollbarPresenceChange={this.detectScrollBar}
          />
          <div className="table-footer" style={{ paddingRight: locationScrollBarSize }}>
            <div className="footer-cell">Total</div>
            <div className="footer-cell">{report.totalMaxChildrenAllowed}</div>
            <div className="footer-cell">{report.totalAverageAttendeesCount}</div>
            <div className="footer-cell">{report.totalAverageEnrolledCount}</div>
          </div>
        </>
      )
    ) : (
      <div className="no-data-title">No data for applied filters!</div>
    );
  }
}

export default Report;
