import * as types from './history-log.types';

// GET HISTORY LOG
export const getHistoryLogRequest = payload => ({
  type: types.GET_HISTORY_LOG_REQUEST,
  payload,
});

export const getHistoryLogSuccess = payload => ({
  type: types.GET_HISTORY_LOG_SUCCESS,
  payload,
});

export const getHistoryLogFail = () => ({
  type: types.GET_HISTORY_LOG_FAIL,
});

// CREATE HISTORY LOG
export const createHistoryRequest = payload => ({
  type: types.CREATE_HISTORY_LOG_REQUEST,
  payload,
});

export const createHistorySuccess = payload => ({
  type: types.CREATE_HISTORY_LOG_SUCCESS,
  payload,
});

export const createHistoryFail = () => ({
  type: types.CREATE_HISTORY_LOG_FAIL,
});
