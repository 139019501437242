import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LogTable from './log-table';
import { getHistoryLogRequest } from './history-log.actions';

@connect(
  ({ history: { list, pagination, spinners, order } }) => ({
    pagination,
    order,
    historyLog: list,
    loading: spinners,
  }),
  { getHistoryLogRequest }
)
class HistoryLogPage extends Component {
  static propTypes = {
    pagination: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    historyLog: PropTypes.array.isRequired,
    getHistoryLogRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      pagination: { currentPage, pageSize },
      getHistoryLogRequest,
    } = this.props;
    getHistoryLogRequest({ currentPage, pageSize, orderBy: 'actionDateTime', orderDirection: 'desc' });
  }

  changeCurrentPageHandler = (_, page) => {
    const {
      pagination: { pageSize },
      order: { orderBy, orderDirection },
    } = this.props;
    this.props.getHistoryLogRequest({ currentPage: page + 1, pageSize, orderBy, orderDirection });
  };

  changePageSizeHandler = e => {
    const pageSize = e.target.value;
    const {
      pagination: { currentPage },
      order: { orderBy, orderDirection },
    } = this.props;
    this.props.getHistoryLogRequest({ currentPage, pageSize, orderBy, orderDirection });
  };

  sortHandler = ({ orderBy, orderDirection }) => {
    const {
      pagination: { currentPage, pageSize },
      getHistoryLogRequest,
    } = this.props;
    getHistoryLogRequest({ currentPage, pageSize, orderBy, orderDirection });
  };

  render() {
    const { loading, historyLog, pagination, order } = this.props;

    return (
      <Fragment>
        <h1 className="dashboard-title">BeanStalk Academy Dashboard. Admin Panel</h1>
        <LogTable
          onChangePage={this.changeCurrentPageHandler}
          onChangeRowsPerPage={this.changePageSizeHandler}
          onSort={this.sortHandler}
          loading={loading.getHistoryLog}
          pagination={pagination}
          data={historyLog}
          order={order}
        />
      </Fragment>
    );
  }
}

export default HistoryLogPage;
